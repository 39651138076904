
/*
* Post Detail Page
*/
import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { toast } from 'react-toastify';
import adminprofile from '../../assets/images/testimonials/img-03.jpg';
import ProfileImage from '../../widgets/ProfileImage';
import CategoryWidget from '../../widgets/CategoryWidget';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};
const productslider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1
};

class PostDetail extends React.Component {
    constructor(props) {
        super(props);
        /* this.AddToWishList = this.AddToWishList.bind(this); */
        this.state = {
            isModalVisible: false,
            agreementContent1: false,
            photoIndex: 0,
        };


    }

    /*    changePreviewImage(image) {
           this.setState({
               newImage: image,
               tabid: 1
           });
       }
       // Add To Cart
       AddToCart(ProductID, ProductName, ProductImage, Qty, Rate, StockStatus) {
           var Cart = JSON.parse(localStorage.getItem("LocalCartItems"));
           if (Cart == null)
               Cart = [];
           let selectedProduct = Cart.find(product => product.ProductName === ProductName);
           if (selectedProduct == null) {
   
               Cart.push({ ProductID: ProductID, ProductName: ProductName, ProductImage: ProductImage, Qty: Qty, Rate: Rate, StockStatus: StockStatus });
               localStorage.removeItem("LocalCartItems");
               localStorage.setItem("LocalCartItems", JSON.stringify(Cart));
   
               toast.success("Item Added to Cart");
           }
           else {
               toast.warning("Item is already in Cart");
           }
       }

   
       AddToWishList(ProductID, ProductName, ProductImage, Qty, Rate, StockStatus) {
           var Cart = JSON.parse(localStorage.getItem("LocalWishListItems"));
           if (Cart == null)
               Cart = [];
   
           let selectedProduct = Cart.find(product => product.ProductID === ProductID);
           if (selectedProduct == null) {
   
               Cart.push({ ProductID: ProductID, ProductName: ProductName, ProductImage: ProductImage, Qty: Qty, Rate: Rate, StockStatus: StockStatus });
               localStorage.removeItem("LocalWishListItems");
               localStorage.setItem("LocalWishListItems", JSON.stringify(Cart));
   
               toast.success("Item Added to WishList");
           }
           else {
               toast.warning("Item is already in WishList");
           }
   
   
       } */

    PlusQty = () => {
        this.OnChangeQty(null, this.state.qty + 1);
    };

    MinusQty = () => {
        if (this.state.qty > 1)
            this.OnChangeQty(null, this.state.qty - 1);
    };

    OnChangeQty = (el, qty) => {
        qty = qty || parseInt(el.target.value) || 1;

        this.setState({ qty });
    };


    /*    CheckCardItem(ID) {
           let checkcart = false;
           var Cart = JSON.parse(localStorage.getItem("LocalCartItems"));
           if (Cart && Cart.length > 0) {
               for (const cartItem of Cart) {
                   if (cartItem.ProductID === ID) {
                       checkcart = true
                   }
               }
           }
           return checkcart;
       }
       CheckWishList(ID) {
           let wishlist = false;
           var Wish = JSON.parse(localStorage.getItem("LocalWishListItems"));
   
           if (Wish && Wish.length > 0) {
               for (const wishItem of Wish) {
                   if (wishItem.ProductID === ID) {
                       wishlist = true
                   }
               }
           }
           return wishlist;
       }
    */

    render() {
        const { photoIndex } = this.state;
        const { nft, purchaseButton } = this.props;
        return (
            <>
                <section>
                    <div className="product-content-top single-product">
                        <Row>
                            <div className="product-top-left col-xl-5 col-md-6">
                                <div className="product-top-left-inner shadow-lg">
                                    <div className="ciyashop-product-images">
                                        <div className="ciyashop-product-images-wrapper ciyashop-gallery-style-default ciyashop-gallery-thumb_position-bottom ciyashop-gallery-thumb_vh-horizontal">
                                            <div className="ciyashop-product-gallery ciyashop-product-gallery--with-images slick-carousel" >
                                                <Slider {...settings} className="ciyashop-product-gallery__wrapper popup-gallery" >
                                                    <div className="ciyashop-product-gallery__image" style={{ border: '3px solid #000000' }}>
                                                        {nft != null ? nft.document != null ? nft.document.map((documentitem) => (documentitem.originalMimeType.includes("video") ?
                                                            <video controls="controls" loop={true} preload="none" autoPlay>
                                                                <source type={documentitem.type} src={documentitem.originalUri !== null ? documentitem.originalUri : ""} />
                                                            </video>
                                                            :
                                                            documentitem.originalMimeType.includes("audio") ?
                                                                <>
                                                                    {
                                                                        documentitem.previewMimeType.includes("image") ?
                                                                            <img src={documentitem.previewUri !== null ? documentitem.previewUri : ""} className="img-fluid" >
                                                                            </img>
                                                                            : null
                                                                    }
                                                                    <audio poster={documentitem.previewUri !== null ? documentitem.previewUri : ""} controls="controls" preload="yes" src={documentitem.originalUri !== null ? documentitem.originalUri : ""}>
                                                                        <source />
                                                                    </audio>

                                                                </>
                                                                :
                                                                documentitem.originalMimeType.includes("image") ?
                                                                    <img src={documentitem.originalUri !== null ? documentitem.originalUri : ""} className="img-fluid" >
                                                                    </img>
                                                                    : null)
                                                        )
                                                            : null
                                                            : null
                                                        }
                                                    </div>
                                                </Slider>
                                                <div className="ciyashop-product-gallery_buttons_wrapper">
                                                    <div className="ciyashop-product-gallery_button ciyashop-product-gallery_button-zoom popup-gallery" onClick={() => this.setState({ isOpen: true })} >
                                                        <Link to="#" className="ciyashop-product-gallery_button-link-zoom">
                                                            <i className="fa fa-arrows-alt" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*  <div className="ciyashop-product-thumbnails">
                                            <Slider {...productslider} className="ciyashop-product-thumbnails__wrapper">
                                                {product.pictures.map((pictureimage, index) =>
                                                    <div className="ciyashop-product-thumbnail__image">
                                                        <Link onMouseOver={() => this.changePreviewImage(pictureimage)} >
                                                            <img src={require(`../../assets/images/${pictureimage}`)} className="img-fluid" />
                                                        </Link>
                                                    </div>
                                                )}
                                            </Slider>
                                        </div> */}
                                            <div className="clearfix" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product-top-right col-xl-7 col-md-6">
                                <div className="product-top-right-inner">
                                    <div className="summary entry-summary">
                                        <h1 className="product_title entry-title font44">{nft.name}</h1>
                                        <Row className="mt-3">
                                            <Col>
                                                <div className="float-left mr-3">
                                                    <h6>Creator</h6>
                                                    <ProfileImage src={adminprofile} width={45} height={45} /*TODO: isChecked={product.owner.isChecked == 'true'} */>
                                                        <div className="admin-profile-info font-weight-bold float-left ml-2 mt-2 pt-1">
                                                            {nft.creatorAccount != null ? nft.creatorAccount.user != null ? nft.creatorAccount.user.nickname != null ? nft.creatorAccount.user.nickname : "" : "" : ""}
                                                            <br /><span className="text hebys1">(%{nft.royaltyRate} royalty)</span>
                                                        </div>
                                                    </ProfileImage>
                                                </div>
                                                <div className="float-left">
                                                    <h6>Collection</h6>
                                                    <ProfileImage src={nft.collection != null ? nft.collection.imageUrl != null ? nft.collection.imageUrl : "" : ""} width={45} height={45} /* isChecked={product.owner.isChecked == 'true'} */>
                                                        <div className="admin-profile-info font-weight-bold float-left ml-2 mt-2 pt-1">
                                                            {nft.collection != null ? nft.collection.name != null ? nft.collection.name : "" : ""}
                                                        </div>
                                                    </ProfileImage>
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <Row className="mt-2 py-3">
                                            <Col>
                                                <div className="product-detail-royaltyRate font18 d-inline">
                                                    <span className="text">{nft.royaltyRate} of sales will go to creator</span>
                                                </div>
                                            </Col>
                                        </Row> */}
                                        <hr />
                                        <Row>
                                            <div className="col-md-2"><h6>Categories: </h6></div>
                                                {nft != null && nft.nftcategory != null ? nft.nftcategory.map(_nftCategory =>
                                                    _nftCategory != null && _nftCategory.category != null ?
                                                        <CategoryWidget categoryName={_nftCategory.category.name} iconClassName={_nftCategory.category.icon} ></CategoryWidget>
                                                        : null
                                                    )
                                                    : null
                                                }
                                        </Row>
                                        <hr />
                                        <Row className="d-flex align-content-center">
                                            <div className="col-md-2"><h6>Description: </h6></div><div className="col-md-10 pl-0">{nft.description}</div>
                                        </Row>
                                        <hr />
                                        <form className="cart pb-0">
                                            {
                                                nft.saleAndAuction.length > 0 ?
                                                    <>
                                                    <div className="quantity font26 py-3 px-4 mb-2 font-weight-bold text-white bg-primary d-flex align-items-center">
                                                            {nft.saleAndAuction[0] != null ? nft.saleAndAuction[0].coin != null ? nft.saleAndAuction[0].coin.symbol : "" : null} {    Number(nft.saleAndAuction[0].unitPrice).toFixed(4)} {" "}
                                                        <Link onClick={() => purchaseButton(nft.saleAndAuction[0].unitPrice, nft.saleAndAuction[0].coin.symbol, nft.saleAndAuction[0].id)}
                                                            className="button single_add_to_cart_button px-4 bg-white hebys1 mx-3" rel="nofollow">BUY</Link>
                                                            <img height="36" src={require(`../../assets/images/chains/${nft.saleAndAuction[0].chainId}.png`)}/>
                                                    </div>
                                                    </>
                                                    : null
                                            }
                                        </form>
                                        {/* <p className="price">{`Ξ${(product.salePrice * qty).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} ${product.coin}`}</p>  */}
                                        {/*   <div className="product-details__short-description">
                                        <div className="pdp-about-details-txt pdp-about-details-equit">
                                            {product.description}
                                        </div>
                                    </div> */}
                                        
                                        {/*     <div className="product-summary-actions">

                                            {!this.CheckWishList(product.id) ?
                                                <div className="add-to-wishlist">
                                                    <Link onClick={() => this.AddToWishList(product.id, product.name, product.pictures[0], qty, product.salePrice, "In Stock")}>
                                                        Add to Wishlist
                                </Link>
                                                </div>
                                                :
                                                <div className="add-to-wishlist-fill">
                                                    <Link to="/wishlist" >Browse Wishlist</Link>
                                                </div>
                                            }
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                    <div>

                        {/*  {isOpen && (
                            <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                enableZoom={false}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + images.length - 1) % images.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % images.length,
                                    })
                                }
                            />
                        )} */}
                    </div>

                </section>
            </>
        )
    }
}
export default PostDetail;

