import React, { useRef, useState } from "react"
import { Link } from "react-router-dom";
import { Nav, Navbar, NavItem, UncontrolledTooltip } from "reactstrap";
import { getLocalizedText } from "../../common/helpers/localizationManager";
import { ellipsisAddress } from "../../common/helpers/utilities"

interface IWalletAddressProps {
  address: string;
  isOwner?: boolean;
}

const WalletAddress: React.FC<IWalletAddressProps> = ({ address, isOwner }) => {
  const buttonRef = useRef(null);
  const [isCopied, setCopied] = useState(false);
  const copyToClipboard = () => {
    // TODO Fallback to legacy api if not supported.
    if (navigator.clipboard)
      navigator.clipboard.writeText(address);

    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  return (
    <>
      <a ref={buttonRef} className="mx-1 p-2 ciyashop-btn d-inline-flex" onClick={!isCopied ? copyToClipboard : undefined}
        style={{
          display: "flex",
          alignItems: "baseline",
          color: "rgb(50,50,50)"
        }}>
        <div style={{ userSelect: 'none' }} /* className="text-muted" */>{ellipsisAddress(address, isOwner ? 6 : 6)}</div>
        <i className="fa fa-clipboard ml-2 d-inline-flex"></i>
      </a>
      <UncontrolledTooltip placement="top" target={buttonRef} delay={{ show: 0, hide: 125 }}>
        <b className="px-2 py-4">
          {!isCopied
            ? getLocalizedText("COPY_LABEL")
            : getLocalizedText("COPIED_LABEL")
          }
        </b>
      </UncontrolledTooltip>
    </>
  )
}

export default WalletAddress;