
/**
 * ProductDetailTradingHistory Widget
 */
import React, { Component } from 'react';
import { TabPanel, Tabs } from 'react-tabs';
import { getLocalizedTextWithValue } from '../common/helpers/localizationManager';

class ProductDetailTradingHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const { tradingHistory, nft } = this.props;
        return (
            <>
                <Tabs>
                    <TabPanel>
                        <div className="tab-pane fade show active " id="trading-list" role="tabpanel" aria-labelledby="transaction-list-tab">
                            <div className="table-responsive">

                                <table className="table trading-table mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Event</th>
                                            <th scope="col">Item</th>
                                            <th scope="col"></th>
                                            <th scope="col">Unit Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">From</th>
                                            <th scope="col">To</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tradingHistory != null ?
                                            tradingHistory.map((trading) => {
                                                return (
                                                    <tr>
                                                        <td>{getLocalizedTextWithValue("ACTIVITY_EVENT_TYPE_OPTION", trading.eventType)}</td>
                                                        <td><img className="img-fluid  user-image th-img" src={nft != null ? nft.defaultPreviewUrl != null ? nft.defaultPreviewUrl : "" : ""} /></td>
                                                        <td>{nft != null ? nft.name : null}</td>
                                                        <td>{trading.bidPrice}</td>
                                                        <td>{trading.quantity}</td>
                                                        <td>{trading.fromAddress}</td>
                                                        <td>{trading.toAddress}</td>
                                                        <td>{trading.createdDate}</td>
                                                    </tr>
                                                )
                                            }) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </>
        )
    }
}

export default ProductDetailTradingHistory;
