import React from 'react';
import { Card, CardHeader, CardBody, Button, UncontrolledCollapse } from 'reactstrap';

export default class GroupItemCls extends React.Component {

    constructor(props) {
        super(props);

        this.togglerId = '_' + Math.random().toString(36).substr(2, 9);
        this.headerId = '_' + Math.random().toString(36).substr(2, 9);
    }

    renderGroupBody = () => {
        return (
            <CardBody style={{ paddingBottom: 4, ...this.props.bodyStyle }}>
                {this.props.children}
            </CardBody>
        )
    }

    renderGroupTitle = () => {
        return (
            <span style={{ margin: 0, padding: 0, fontWeight: 500, ...this.props.titleStyle }}>{this.props.title}</span>
        )
    }

    render() {
        return (
            <Card style={{ marginBottom: 16, ...this.props.rootStyle }}>
                <CardHeader id={this.headerId} style={this.props.headerStyle}>
                    {
                        this.props.isCollapsible != false ?
                            <Button id={`${this.togglerId}`} block color="link" aria-controls="collapseOne"
                                style={{ margin: 0, padding: 0, textAlign: 'start', ...this.props.headerButtonStyle }}>
                                {this.renderGroupTitle()}
                                <div className="card-header-actions">
                                    <i className="icon-arrow-up"></i>
                                </div>
                            </Button>
                            :
                            this.renderGroupTitle()
                    }
                </CardHeader>
                {
                    this.props.isCollapsible != false ?
                        <UncontrolledCollapse className="pb-3" defaultOpen={this.props.defaultOpen || true} aria-labelledby={this.headerId} toggler={`#${this.togglerId}`}>
                            {this.renderGroupBody()}
                        </UncontrolledCollapse>
                        :
                        this.renderGroupBody()
                }
            </Card>
        )
    }
}