/**
 *  Shop Product Detail Page
 */
import React, { Component } from 'react';
import PostDetail from '../../templates/post-detail';
import ProductSlider from '../../widgets/ProductSlider';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { Row, Col, Container, Nav, NavItem, NavLink, TabContent, TabPane, Input, Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import adminprofile from '../../assets/images/testimonials/img-02.jpg';
import ProductDetailTraits from '../../widgets/ProductDetailTraits';
import ProfileImage from '../../widgets/ProfileImage';
import { apiGetNftDetail } from "../../common/helpers/api";
import ProductDetailOwners from '../../widgets/ProductDetailOwners';
import ProductDetailTradingHistory from '../../widgets/ProductDetailTradingHistory';
import ProductDetailSaleAndAuction from '../../widgets/ProductDetailSaleAndAuction';
import { getLocalizedText } from '../../common/helpers/localizationManager';
import { Web3Context } from '../../component/Wallet/Web3Provider';
import { apiPostPurchaseNFT } from '../../common/helpers/api';

const relatedslider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3
            }

        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};

class ProductDetail extends Component {
    static contextType = Web3Context;
    constructor(props) {
        super(props);
        this.state = {
            tokenId: this.props.match.params.tokenId,
            creatorContractAddress: this.props.match.params.creatorContractAddress,
            activeTab: '1',
            nft: [],
            isOpen: false,
            quantity: 1,
            unitPrice: 0,
            totalPrice: 0,
            buyCoinSymbol: "",
            sellId: 0,
            hasPendingRequest: false,
            showModal: false,
            errorMessage: "",
            actionList: []
        };
        this.toggle = this.toggle.bind(this);
        this.context = React.createContext(Web3Context);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getNft(this.state.tokenId, this.state.creatorContractAddress);
    }

    getNft = async (tokenId, creatorContractAddress) => {
        try {
            this.setState({ isloading: true });
            var request = {
                tokenId: tokenId,
                creatorContractAddress: creatorContractAddress
            };
            var NftResponse = (await apiGetNftDetail(
                request
            )).data;
            console.log(request)
            this.setState({ nft: NftResponse, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
    };

    handleQuantityChange(event) {
        var newValue;
        newValue = parseInt(event.target.value) || 1;

        var totalPrice = this.state.totalPrice;
        totalPrice = this.state.unitPrice * newValue;

        this.setState({ quantity: newValue, totalPrice });
    }

    getAction = () => {
        var actionList = this.state.actionList;
        let descActionList = _(actionList).orderBy(undefined, ['asc']).value();
        let firstHistoryItem = _.first(descActionList);
        return firstHistoryItem;
    }

    setAction = (action) => {
        var actionList = this.state.actionList;
        actionList.push(action);
        this.setState({ actionList, hasPendingRequest: true, showModal: true });
    }

    toggleModal = () => this.setState({ showModal: !this.state.showModal });

    showBuyModal = (unitPrice, buyCoinSymbol, sellId) => {
        this.setState({ unitPrice: unitPrice, totalPrice: unitPrice, buyCoinSymbol: buyCoinSymbol, sellId: sellId, isModalVisible: true });
    }

    purchaseNFT = async () => {
        let { contractHandler, address, web3 } = this.context;
        let { sellId, quantity, unitPrice, totalPrice, buyCoinSymbol } = this.state;

        var sellContract = await contractHandler.getSellContract();
        var gweiPrice = web3.utils.toWei(totalPrice.toString(), "ether");

        this.setAction("Purchase NFT")
        var withdrawTransaction = await sellContract.withdrawAsync(sellId, quantity, address, parseInt(gweiPrice))
        var txId = await withdrawTransaction.transactionHash;
        var status = await withdrawTransaction.status;
        this.setAction(`TxId of the transmitted transaction: ${txId}`);

        if (status) {
            this.setAction(`TxId of the transmitted transaction: ${txId}`);
            var purchaseNFTRequest = {
                sellId: sellId,
                quantity: quantity,
                unitPrice: unitPrice,
                coinId: 1, //TODO:Coin Id 
                txId: txId,
            };
            await apiPostPurchaseNFT(purchaseNFTRequest);
        }

        this.setState({ quantity: 0, totalPrice: 0, buyCoinSymbol: "", isModalVisible: false });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }


    render() {
        const { nft, isModalVisible, buyCoinSymbol, unitPrice, totalPrice, errorMessage, showModal, hasPendingRequest, isConnecting } = this.state;
        let { address } = this.context;
        var privateSellList = _.filter(nft.saleAndAuction, x => x.isPrivate == true && x.privateBuyerAccountAddress == address);

        return (
            <>
                <Modal className="modal-delete modal-lg modal-dialog-centered"
                    isOpen={showModal} toggle={this.toggleModal} backdrop="static">
                    <ModalBody>
                        {!!errorMessage
                            ? (
                                <>
                                    <small>{errorMessage}</small>
                                </>
                            )
                            : hasPendingRequest
                                ? (
                                    <>
                                        <div>{"Pending Call Request"}</div>
                                        <div>
                                            <div>
                                                {this.getAction()}
                                            </div>
                                        </div>
                                    </>
                                )
                                : isConnecting
                                    ? (
                                        <div>{"Connecting wallet."}</div>
                                    )
                                    :
                                    (
                                        <div>{"Call Request Rejected"}</div>
                                    )}
                    </ModalBody>
                </Modal>
                <Modal
                    className="modal-view modal-dialog-centered"
                    size={"lg"}
                    isOpen={isModalVisible}
                    toggle={() => this.setState({ isModalVisible: false })}  >
                    <ModalHeader closeButton style={{ backgroundColor: 'rgba(0, 180, 240, 0.9)', color: '#FFFFFF', justifyContent: 'center' }}>
                        <span> {getLocalizedText('CHECKOUT')}</span>
                    </ModalHeader>
                    <ModalBody style={{ padding: 30 }}>
                        <div id="order_review" className="checkout-review-order" style={{ border: 'none' }} >
                            <div className="table-responsive">
                                <table className="shop_table checkout-review-order-table">
                                    <thead>
                                        <tr>
                                            <th className="product-name">Item</th>
                                            <th className="product-name">Quantity</th>
                                            <th className="product-total">Unit Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="cart_item">
                                            <td >
                                                {nft.name}&nbsp;
                                            </td>
                                            <td >
                                                <Input type="text" name="key" value={this.state.quantity} style={{ width: 50 }} className="form-control " placeholder="Key" onChange={this.handleQuantityChange} />
                                            </td>
                                            <td className="product-total">
                                                <span className="woocs_special_price_code"><span className="Price-amount amount"><span className="Price-currencySymbol"> </span>  {unitPrice} {" "}  {buyCoinSymbol} </span></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr className="cart-subtotal">
                                            <th>Total</th>
                                            <td><span className="woocs_special_price_code"><span className="Price-amount amount"><span className="Price-currencySymbol"> </span> {totalPrice} {" "}  {buyCoinSymbol}</span></span>
                                            </td>
                                        </tr>
                                        <tr className="cart-subtotal">
                                            <td>
                                                <Input type="checkbox" name="isPrivate" style={{ paddingLeft: 10 }}
                                                    value={this.state.agreementContent1} onChange={() => this.setState({ agreementContent1: !this.state.agreementContent1 })} />{' '}
                                                {getLocalizedText("AGREEMENT CONTENT TEXT")}
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ marginTop: 15, marginRight: 0 }} onClick={() => this.setState({ isModalVisible: false })}>{getLocalizedText('CANCEL')}</Button>
                        <Button color="success" disabled={!this.state.agreementContent1} style={{ marginTop: 15, marginRight: 0 }} onClick={() => this.purchaseNFT()}>{getLocalizedText('CHECKOUT')}</Button>
                    </ModalFooter>
                </Modal>
                {
                    this.state.isloading == true ?
                        <div id="preloader">
                            <Loader
                                type="Puff"
                                color="#fe327e"
                                height="100"
                                width="100"
                            />
                        </div>
                        :
                        <div>
                            {nft !== null && nft.id > 0 ?
                                <div className="site-content">
                                    <div className="content-wrapper py-5 px-4 bg-gray">
                                        <Container>
                                            {<PostDetail nft={nft} tabid={this.state.activeTab} purchaseButton={this.showBuyModal} />}
                                            <div className="product-content-bottom mt-4">
                                                <Nav tabs >
                                                    <NavItem active>
                                                        <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>Details</NavLink>
                                                    </NavItem>
                                                    <NavItem disabled>
                                                        <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>Trading History</NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>Owners</NavLink>
                                                    </NavItem>
                                                    <NavItem disabled>
                                                        <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>Sale List</NavLink>
                                                    </NavItem>
                                                </Nav>
                                                <TabContent className="pt-4 px-3 bg-white" activeTab={this.state.activeTab}>
                                                    <TabPane tabId="1">
                                                        <div className="product-content-top single-product">
                                                            <Row>
                                                                <div className="product-top-right col-xl-12 col-md-12">
                                                                    <div className="product-top-right-inner">
                                                                        <div className="summary entry-summary">
                                                                            <div className="product-rating" >
                                                                                <Col md={12}>
                                                                                    <h6>Creator</h6>
                                                                                    <ProfileImage src={adminprofile} width={45} height={45} /* isChecked={Productedit.owner.isChecked == 'true'} */>
                                                                                            <div className="admin-profile-info font-weight-bold float-left ml-2 mt-2 pt-1">{nft.creatorAccount != null ? nft.creatorAccount.user.nickname != null ? nft.creatorAccount.user.nickname : "" : ""}</div>
                                                                                    </ProfileImage>
                                                                                </Col>
                                                                            </div>
                                                                            <hr /> 
                                                                            <h2 className="product_title entry-title" ><i className="fa fa-align-left hebys1" /> Properties</h2>
                                                                            <hr />
                                                                            <ProductDetailTraits traits={nft.trait} />
                                                                            <div className="product-summary-actions">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </TabPane>
                                                    <TabPane tabId="2">
                                                        <ProductDetailTradingHistory tradingHistory={nft.tradingHistory} nft={nft} />
                                                    </TabPane>
                                                    <TabPane tabId="3">
                                                        <div className="product-reviews">
                                                            <ProductDetailOwners owners={nft.nftowner} />
                                                        </div>
                                                    </TabPane>
                                                    <TabPane tabId="4">
                                                        <ProductDetailSaleAndAuction
                                                            saleAndAuctionList={nft.saleAndAuction}
                                                            sessionUserPrivateSellList={privateSellList}
                                                            purchaseButton={this.showBuyModal} />
                                                    </TabPane>
                                                </TabContent>
                                                {/* <div className="related products">
                                        <h2>Related products</h2>
                                        <div className="row">
                                            <ProductSlider productSub={Productedit.subcategory} settings={relatedslider} />
                                        </div>
                                    </div> */}
                                            </div>
                                        </Container>
                                    </div>
                                </div>
                                :
                                <Container>
                                    {nft.Message}
                                </Container>
                            }
                        </div>
                }
            </>
        )
    }
}

const AppMapStateToProps = state => {
    return {
        products: state.data.products
    };
};


export default connect(AppMapStateToProps)(withRouter(ProductDetail));
