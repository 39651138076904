import axios, { AxiosInstance } from 'axios'
import { BasicWalletInfo, IAssetData, ICollection, ICollectionCreateDTO, IGasPrices, INft, INftLazyMintDTO, INftMintDTO, IParsedTx, MessageSignRequest, ResponseWrapper, SessionContext } from './types'
import { IPFSProviderURL } from '../constants'
import { cacheGetAccessToken, cacheGetDefaultAccessToken } from './cacheManager';
import { map } from 'lodash';
export const ipfsProviderAPIKey = process.env.REACT_APP_IPFS_PROVIDER_API_KEY;
export const ipfsProviderSecretKey = process.env.REACT_APP_IPFS_PROVIDER_SECRET_KEY;

const ipfsProviderAPI = axios.create({
  baseURL: IPFSProviderURL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Accept': 'application/json',
    'pinata_api_key': ipfsProviderAPIKey,
    'pinata_secret_api_key': ipfsProviderSecretKey
  },
  //timeout: 40000,
  withCredentials: true
});

export async function login() {
  return ipfsProviderAPI.get<ResponseWrapper<string>>(
    `/data/testAuthentication`,
  );
}

export async function pinMultipleFile(files: any[], metaData?: any, options?: any) {
  let formData = new FormData();
  files.forEach(file => {
    formData.append(`file`, file);
  });

  formData.append('pinataMetadata', JSON.stringify({ "name": "test" }));
  formData.append('pinataOptions ', JSON.stringify({ "wrapWithDirectory": true }));

  return ipfsProviderAPI.post(
    "/pinning/pinFileToIPFS",
    formData,
    /*  {
       formData,
       "pinataMetaData": metaData,
       "pinataOptions": options,
     } */{
      headers: {
        "Content-Type": "multipart/form-data",
        pinata_api_key: ipfsProviderAPIKey,
        pinata_secret_api_key: ipfsProviderSecretKey
      }
    }
  );
}
export async function pinFile(file: any, name: string, keyValues: {}, metaData?: any, options?: any) {
  let formData = new FormData();
  formData.append('file', file);
  //TODO: metada and option
  formData.append('pinataMetadata', JSON.stringify({ "name": name, "keyvalues": keyValues }));
  formData.append('pinataOptions ', JSON.stringify({ "wrapWithDirectory": false }));

  return axios
    .post(IPFSProviderURL + "/pinning/pinFileToIPFS", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        pinata_api_key: ipfsProviderAPIKey,
        pinata_secret_api_key: ipfsProviderSecretKey
      }
    }
    )
}

export async function pinJSON(request: any) {
  return ipfsProviderAPI.post(
    "/pinning/pinJSONToIPFS",
    request
  );
}

export async function unpin(cid: string) {
  return ipfsProviderAPI.delete(
    `/pinning/unpin/${cid}`
  );
}

export { }