import moment from '../moment';
import { DEFAULT_CACHE_EXPIRATION_MIN, DEFAULT_CACHE_LOCK_TIMEOUT_MS, DEFAULT_CACHE_STORAGE } from '../constants';
import { BasicWalletInfo, LoggedInUser, MessageSignRequest, SessionContext } from './types';
import { ellipsisAddress } from './utilities';

var cachedObjects: any = {};

function cacheGet(key: string, storageName = DEFAULT_CACHE_STORAGE) {
    try {
        var temp = null;

        if (cachedObjects.hasOwnProperty(key)) {
            temp = cachedObjects[key];
            if (temp && temp.expires && temp.expires > moment())
                return temp.data;
            else temp = null;
        }

        if (temp == null) {
            temp = getStorage(storageName).getItem(key);
            if (temp != null) {
                try {
                    temp = JSON.parse(temp);
                } catch (e) {
                    console.error(e);
                }
                cacheSet(key, temp, DEFAULT_CACHE_EXPIRATION_MIN, storageName);

                return temp;
            }
        }
    } catch (error) {
        console.error(error);
    }
    return null;
}

function cacheSet(key: string, object: any, expirationMin = DEFAULT_CACHE_EXPIRATION_MIN, storageName = DEFAULT_CACHE_STORAGE) {
    try {
        if (key && object) {

            var temp = {
                data: object,
                expires: moment().add(expirationMin, 'm')
            }
            cachedObjects[key] = temp;

            if (typeof object === 'object')
                object = JSON.stringify(object);

            getStorage(storageName).setItem(key, object);
            return true;
        }
    } catch (error) {
        console.error(error);
    }
    return false;
}

function cacheDelete(key: string, storageName = DEFAULT_CACHE_STORAGE) {
    try {
        if (cachedObjects.hasOwnProperty(key)) {
            cachedObjects[key] = undefined;

            getStorage(storageName).removeItem(key);
            return true;
        }
    } catch (error) {
        console.error(error);
    }
    return false;
}

async function cacheLock(lockKey: string, storageName = DEFAULT_CACHE_STORAGE) {
    try {
        lockKey += "_lock";

        if (cacheGet(lockKey, storageName) != null)
            await sleep(DEFAULT_CACHE_LOCK_TIMEOUT_MS)

        if (cacheGet(lockKey, storageName) != null) {
            cacheDelete(lockKey, storageName);
            return false;
        }
        else {
            cacheSet(lockKey, "locked", DEFAULT_CACHE_EXPIRATION_MIN, storageName);
            return true
        }
    } catch (error) {
        console.error(error);
    }
    return false;
}

async function cacheUnlock(lockKey: string, storageName = DEFAULT_CACHE_STORAGE) {
    try {
        lockKey += "_lock";

        cacheDelete(lockKey, storageName);
        return true;
    } catch (error) {
        console.error(error);
    }
    return false;
}

function cacheClear() {
    try {
        for (const key in cachedObjects) {
            if (cachedObjects.hasOwnProperty(key)) {
                localStorage.removeItem(key);
                sessionStorage.removeItem(key);
            }
        }
        cachedObjects = {};

        return true;
    } catch (error) {
        console.error(error);
    }
    return false;
}

function clearCookies() {
    var res = document.cookie;
    var multiple = res.split(";");
    for (var i = 0; i < multiple.length; i++) {
        var key = multiple[i].split("=");
        document.cookie = key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
    }
}

/**
 * Provided storage keys will be restored after clearing site data.
 *
 */
function clearSiteData() {
    var toBeRestored = ["preferredLang"]
    var storeCheckpoint = toBeRestored.map(key => ({ key, value: localStorage.getItem(key) }));

    localStorage.clear();
    sessionStorage.clear();
    clearCookies();
    cacheClear();

    storeCheckpoint.forEach(item => {
        if (item.key && item.value != null)
            localStorage.setItem(item.key, item.value)
    });
}

function getStorage(storageName: string) {
    switch (storageName) {
        case "local":
            return localStorage;
        case "session":
        default:
            return sessionStorage;
    }
}

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

//#region Helpers

export function cacheSetAccountData(session: SessionContext, walletInfo: BasicWalletInfo, isDefault: boolean = true) {
    var user = session.currentUser;
    var shortAddress = ellipsisAddress(walletInfo.address);

    cacheSet(`profile:${user.userId}`, user, 600);
    cacheSet(`accessToken:${shortAddress}`, session.token, 600);

    if (isDefault)
        cacheSetSelectedAddress(walletInfo.address);
}

export function cacheSetSelectedAddress(address: string) {
    var shortAddress = ellipsisAddress(address);

    cacheSet("selectedAddress", shortAddress, 600);
}

export function cacheClearAccountData(userId?: number, address?: string) {
    var shortAddress = !!address ? ellipsisAddress(address) : "";

    for (let i = 0; i < localStorage.length; i++) {
        var storageKey = localStorage.key(i);
        if (storageKey?.startsWith(`profile:${userId || ""}`))
            cacheDelete(storageKey);

        if (storageKey?.startsWith(`accessToken:${shortAddress}`))
            cacheDelete(storageKey);
    }

    cacheDelete("selectedAddress");
}

export function cacheGetProfile(userId: number): LoggedInUser {
    return cacheGet(`profile:${userId}`);
}

export function cacheGetAccessToken(address: string): LoggedInUser {
    var shortAddress = ellipsisAddress(address);

    return cacheGet(`accessToken:${shortAddress}`);
}

export function cacheGetDefaultAccessToken(): LoggedInUser {
    var shortAddress = cacheGet("selectedAddress");

    return cacheGet(`accessToken:${shortAddress}`);
}

export function isAccountAuthenticated(address: string): boolean {
    var shortAddress = ellipsisAddress(address);

    return cacheGet(`accessToken:${shortAddress}`) != null;
}

//#endregion

export { cacheGet, cacheSet, cacheDelete, cacheClear, clearSiteData, clearCookies, cacheLock, cacheUnlock };