
/**
 * ProductDetailSaleAndAuction Widget
 */
import React, { Component } from 'react';
import adminprofile from '../assets/images/testimonials/img-02.jpg';
import ProfileImage from '../widgets/ProfileImage';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class ProductDetailSaleAndAuction extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    renderSellList = (list, purchaseButton) => {
        return (
            list.map((saleAndAuction) => {
                return (
                    <>
                        <Row>
                            <Col md={12} className="product-content-top single-product" style={{ display: 'flex' }}>
                                <Col md={7}>
                                    <ProfileImage src={adminprofile} width={45} height={45} /* isChecked={Productedit.owner.isChecked == 'true'} */>
                                        <div className="admin-profile-info" style={{ paddingTop: 10, float: 'left', paddingLeft: 10 }}>
                                            <h6 style={{ fontSize: 15 }}> <b>{saleAndAuction.createdAccount != null ? saleAndAuction.createdAccount.user.nickname != null ? saleAndAuction.createdAccount.user.nickname : "" : ""}</b></h6>
                                            <h6 style={{ fontSize: 12 }}>Sale NFT Quantity:<b>{saleAndAuction.quantity}</b></h6>
                                        </div>
                                    </ProfileImage>
                                </Col>
                                <Col md={5}>
                                    <form className="cart">
                                        <div className="quantity" style={{ padding: 13, fontSize: 20, fontWeight: 'bold' }}>
                                            {saleAndAuction.coin != null ? saleAndAuction.coin.symbol : ""} {saleAndAuction.unitPrice} {" "}
                                        </div>
                                        <Link onClick={() => purchaseButton(saleAndAuction.unitPrice, saleAndAuction.coin.symbol, saleAndAuction.id)}
                                            className="button single_add_to_cart_button" rel="nofollow">BUY</Link>
                                        <div className="clearfix" />
                                    </form>
                                </Col>

                            </Col>
                        </Row>
                        <hr />
                    </>
                )
            })
        )
    }

    render() {

        const { saleAndAuctionList, purchaseButton, sessionUserPrivateSellList } = this.props;
        return (
            <>
                {
                    sessionUserPrivateSellList != null && sessionUserPrivateSellList.length > 0 ?
                        <>
                            <Col md={12}>
                                <h5 className="product_title entry-title" >
                                    PrivateSell for You</h5>
                                <hr />
                            </Col>
                            {
                                this.renderSellList(sessionUserPrivateSellList, purchaseButton)
                            }
                        </>
                        :
                        null
                }
                {
                    saleAndAuctionList != null && saleAndAuctionList.length > 0 ?
                        <>
                            <Col md={12}>
                                <h5 className="product_title entry-title" >
                                    Sell List</h5>
                                <hr />
                            </Col>
                            {
                                this.renderSellList(saleAndAuctionList, purchaseButton)
                            }
                        </>
                        : null
                }
            </>
        )
    }
}

export default ProductDetailSaleAndAuction;
