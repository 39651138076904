
/**
 * CategoryWidget Widget
 */
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class CategoryWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }


    render() {
        const { categoryName, iconClassName } = this.props;

        return (
            <Col md={2} className="font-weight-bold nowrap text-dark pl-0">
                    <i className={iconClassName+" mr-2"}></i>{categoryName}
            </Col>
        )
    }
}

export default CategoryWidget;
