import React from "react";
import { getLocalizedText } from './helpers/localizationManager';
import Loadable from 'react-loadable';
import LoadingPlaceholder from '../component/loadingPlaceholder';

const NetworkErrorModal = Loadable({
    loader: () => import('../component/modals/networkErrorModal'),
    loading: LoadingPlaceholder
});

const ModalPresets = {
    EmptyError: {
        title: "",
        body: "",
        posButtonText: "OK",
        negButtonText: "",
        modalStyle: 'bg-blue',
        posButtonColor: "secondary",
    },
    NetworkError: {
        title: "",
        body: <NetworkErrorModal />,
        posButtonText: "OK",
        negButtonText: "",
        modalStyle: 'bg-blue',
        posButtonColor: "warning",
        modalProps: {
            backdrop: 'static'
        }
    },
};

export default ModalPresets;