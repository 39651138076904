/**
 *  Admin Site Product Add
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, FormGroup, Input, Label, Row, Col, Button, InputGroup, InputGroupAddon, InputGroupText, Toast } from 'reactstrap';
import NumericInput from 'react-number-format';
import Files from "react-butterfiles";
import { getLocalizedText } from '../../../common/helpers/localizationManager';
import { TraitTypes, TokenProtocol, } from '../../../common/constants';
import GroupItem from '../../../widgets/GroupItem';
import { pinFile, pinJSON } from "../../../common/helpers/ipfsProvider";
import { apiGetCollection, apiGetCategory, apiGetTokenId, apiNftLazyMint, apiPostTraits, apiPostFile } from "../../../common/helpers/api";
import LoadingOverlay from '../../LoadingOverlay';
import { Web3Context } from '../../Wallet/Web3Provider';
import WalletConnectLink from '../../Wallet/widgets/WalletConnectLink';
import WalletConnect from '../../Pages/WalletConnect';
import Loader from 'react-loader-spinner';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const productslider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1
};

const numericInputProps = {
    decimalScale: 0,
    decimalSeparator: ',',
    defaultThousandSeparator: ',',

};

const InputStyle = {

};

const acceptFileUpload = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
    "audio/mpeg",
    "audio/mpg",
    "audio/mpeg3",
    "audio/mp3",
    "video/mpeg3",
    "video/mp4",
];

const notRequirePreivewImage = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
]
const productdata = {
    Product_single: "product-single.jpg",
    product_gallery: [
        "product-single.jpg",
    ],
};

class ItemCreate extends Component {
    static contextType = Web3Context;
    constructor(props) {
        super(props);
        this.state = {
            pictures: [],
            photoIndex: 0,
            isOpen: false,
            isloading: false,
            ErrorMsg: "",
            item: {
                name: "My First NFT",
                quantity: 1,
                isPhysical: 0,
                collectionId: 59, //TODO: Default collection api returned
                categories: [],
                isPublic: true,
            },
            files: [],
            previewImageFiles: [],
            isPreviewImageRequirement: false,
            previewImageSrc: "",
            errors: [],
            propertyCount: 0,
            traitList: [],
            traitModel: {
                traitType: "",
                key: "",
                textValue: "",
                numericValue1: 0,
                numericValue2: 0,
                numericValue3: 0,
            },
            fileModel: {
                name: "",
                fileName: "",
                originalname: "",
                originalMimeType: "",
                originalCid: "",
                previewCid: "",
                previewMimeType: "",
                Nftid: 0,
            },
            propertyIsOpen: true,
            collectionList: [],
            categoryList: []
        };

        this.context = React.createContext(Web3Context);
        this.Uploadimage = this.Uploadimage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        this.getCollectionList()
        this.getCategoryList()
        console.log("🚀 ~ file: ItemCreate.js ~ line 147 ~ ItemCreate ~ constructor ~ context", this.context)

    }

    getCollectionList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria: {
                    status: 1
                },
            };

            var collectionResponse = (await apiGetCollection(
                request
            )).data;

            var collectionList = collectionResponse.data.list;
            this.setState({ collectionList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
        console.log("🚀 ~ file: ItemCreate.js ~ line 153 ~ ItemCreate ~ componentDidMount ~ collectionList", collectionList)
    };

    getCategoryList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria: {
                    status: 1
                },
            };

            var categoryResponse = (await apiGetCategory(
                request
            )).data;

            var categoryList = categoryResponse.data.list;
            console.log("🚀 ~ file: ItemCreate.js ~ line 189 ~ ItemCreate ~ getCategoryList= ~ categoryList", categoryList)
            this.setState({ categoryList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
    };

    Uploadimage(picture) {
        if (picture == '') {
            this.setState({
                ...this.state,
                ErrorMsg: "File Not Supported"
            })
        }
        else {
            this.setState({
                pictures: this.state.pictures.concat(picture),
                ErrorMsg: ''
            });
        }
    }

    UploadFile(files) {
        var isPreviewImageRequirement = false;
        var previewImageSrc = "";
        //TODO: FileName Check
        if (files != null) {
            if (notRequirePreivewImage.includes(files[0].type))
                previewImageSrc = files[0].src.base64;
            else
                isPreviewImageRequirement = true;
        }

        this.setState({ files, isPreviewImageRequirement, previewImageSrc });
        console.log("🚀 ~ file: ItemCreate.js ~ line 186 ~ ItemCreate ~ UploadFile ~ files", files)
    }

    UploadPreviewImage(previewImageFiles) {
        var previewImageSrc = "";
        if (previewImageFiles != null)
            previewImageSrc = previewImageFiles[0].src.base64;

        this.setState({ previewImageFiles, previewImageSrc });
        console.log("🚀 ~ file: ItemCreate.js ~ line 201 ~ ItemCreate ~ UploadPreviewImage ~ previewImageFiles", previewImageFiles)
    }

    removeFile = () => {
        var files = [];
        this.setState({ files });
    }

    removePreviewImage = () => {
        var previewImageFiles = [];
        var previewImageSrc = "";
        this.setState({ previewImageFiles, previewImageSrc });
    }

    removeTraitProperty = (index) => {
        var traitList = this.state.traitList;
        traitList.splice(index, 1);

        this.setState({ traitList });
    }

    addTraitProperty = (traitType) => {
        var traitList = this.state.traitList;
        var newTrait = this.state.traitModel;
        newTrait.traitType = traitType;
        traitList.push({ ...newTrait });

        this.setState({ traitList });
    }

    handleInputChange(event, type) {
        const item = { ...this.state.item };
        const target = event.target;
        var newValue;

        if (type != null && type == 'checkbox')
            newValue = event.target.checked;
        else
            newValue = event.target.value;

        item[target.name] = newValue;

        this.setState({ item });
    }

    handleCategoryChange(event) {
        var item = this.state.item;

        if (event.target.checked == "1") {
            if (!item.categories.includes(event.target.value))
                item.categories.push(event.target.value);
        }
        else
            if (item.categories.includes(event.target.value)) {
                item.categories = item.categories.filter(function (value, index, arr) {
                    return value != event.target.value;
                });
            }

        this.setState({ item });
    }

    handlePropertyChange(event, index) {
        const target = event.target;
        var newValue = event.target.value;
        const traitList = this.state.traitList;
        traitList[index][target.name] = newValue;

        this.setState({ traitList });
    }

    renderTraits(traitType) {
        var rendList = [];

        this.state.traitList.map((property, index) => {
            rendList.push(
                property.traitType == traitType ?
                    <>
                        <Col key={`trait_list_${traitType}_${index}`} md={12}>
                            <FormGroup row>
                                <InputGroup>
                                    <Input type="text" name="key" value={property.key} className="form-control " placeholder="Key" onChange={(e) => this.handlePropertyChange(e, index)} />
                                    {
                                        traitType == TraitTypes.Property ?
                                            <Col sm={6}>
                                                <Input type="text" name="textValue" value={property.textValue} className="form-control" placeholder="Value" onChange={(e) => this.handlePropertyChange(e, index)} />
                                            </Col>
                                            : traitType == TraitTypes.Stats ?
                                                <>
                                                    <Col sm={2}>
                                                        <NumericInput placeholder="Value"
                                                            className='form-control'
                                                            name="numericValue2"
                                                            value={property.numericValue2}
                                                            decimalScale={0}
                                                            thousandSeparator={numericInputProps.defaultThousandSeparator}
                                                            decimalSeparator={numericInputProps.defaultDecimalSeparator}
                                                            onChange={(e) => this.handlePropertyChange(e, index)} />
                                                    </Col>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Of</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Col sm={2}>
                                                        <NumericInput placeholder="Value"
                                                            className='form-control'
                                                            name="numericValue3"
                                                            value={property.numericValue3}
                                                            decimalScale={0}
                                                            thousandSeparator={numericInputProps.defaultThousandSeparator}
                                                            decimalSeparator={numericInputProps.defaultDecimalSeparator}
                                                            onChange={(e) => this.handlePropertyChange(e, index)} />
                                                    </Col>
                                                </>
                                                : traitType == TraitTypes.Levels ?
                                                    <Col sm={4}>
                                                        <InputGroup>
                                                            <NumericInput placeholder="Start"
                                                                className='form-control'
                                                                name="numericValue1"
                                                                value={property.numericValue1}
                                                                decimalScale={0}
                                                                thousandSeparator={numericInputProps.defaultThousandSeparator}
                                                                decimalSeparator={numericInputProps.defaultDecimalSeparator}
                                                                onChange={(e) => this.handlePropertyChange(e, index)} />
                                                            <NumericInput placeholder="Value"
                                                                className='form-control'
                                                                name="numericValue2"
                                                                value={property.numericValue2}
                                                                decimalScale={0}
                                                                thousandSeparator={numericInputProps.defaultThousandSeparator}
                                                                decimalSeparator={numericInputProps.defaultDecimalSeparator}
                                                                onChange={(e) => this.handlePropertyChange(e, index)} />
                                                            <NumericInput placeholder="Value"
                                                                className='form-control'
                                                                name="numericValue3"
                                                                value={property.numericValue3}
                                                                decimalScale={0}
                                                                thousandSeparator={numericInputProps.defaultThousandSeparator}
                                                                decimalSeparator={numericInputProps.defaultDecimalSeparator}
                                                                onChange={(e) => this.handlePropertyChange(e, index)} />
                                                        </InputGroup>
                                                    </Col>
                                                    : null
                                    }
                                    <Col sm={2}>
                                        <Button color="danger"
                                            size={"sm"}
                                            onClick={() => this.removeTraitProperty(index)}
                                            style={{ padding: '5px 10px 5px 10px' }}>
                                            <i className="fa fa-trash fa-lg" />
                                            {' '}{getLocalizedText("DELETE")}
                                        </Button>
                                    </Col>
                                </InputGroup>
                            </FormGroup>
                        </Col >
                    </>
                    :
                    null
            )
        });
        return <Col md={12}>{rendList}</Col>
    }

    setCollectionId = (collectionId) => {
        const item = this.state.item;
        item.collectionId = collectionId;
        this.setState({ item });
    };

    renderCollection() {
        var rendList = [];
        var selectedCollectionId = this.state.item.collectionId;
        this.state.collectionList.map((property, index) => {
            rendList.push(
                <Col key={`collection_list${index}`}
                    md={4}
                    style={{ padding: 10, cursor: 'pointer', border: selectedCollectionId == property.id ? '2px solid #04d39f' : 'none' }}
                    onClick={() => this.setCollectionId(property.id)}>
                    <img className="img-fluid" src={property.imageUrl} style={{ maxHeight: 100 }} alt="profile" />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexWrap: 'nowrap',
                        overflow: 'hidden'
                    }}>
                        {property.name}
                        <div>
                            <Input type="radio" className="d-none" name={`collection_${property.id}`} checked={selectedCollectionId == property.id} />
                        </div>
                    </div>
                </Col >
            )
        });
        return <FormGroup check style={{ height: 125, display: 'flex', padding: 10 }}>{rendList}</FormGroup>
    }

    UploadFileIpfs = async (file, fileName, keyValues) => {
        var cId;
        try {
            this.setState({ isloading: true });

            var ipfsResponse = (await pinFile(
                file,
                fileName,
                keyValues
            )).data;

            cId = ipfsResponse.IpfsHash;
            console.log("🚀 ~ file: ItemCreate.js ~ line 442 ~ ItemCreate ~ UploadIpfs= ~ ipfsResponse", ipfsResponse)

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
        return cId
    };

    onSave = async () => {
        this.setState({ isloading: true });
        let { item, traitList, fileModel, files, previewImageFiles } = this.state;
        var file = { ...fileModel };
        item.tokenProtocol = TokenProtocol.ERC1155

        //TODO: input validate Check
        if (files.length == 0)
            return;

        var nftLazyMint = {
            ...item,
        };

        try {
            var nft = (await apiNftLazyMint(nftLazyMint)).data.data;
            console.log("🚀 ~ file: ItemCreate.js ~ line 494 ~ ItemCreate ~ onSave= ~ nft", nft)
        } catch (error) {
            console.error(error);
            return;
        }

        var keyValues = {
            TokenId: await nft.tokenId
        }

        traitList.forEach(element => {
            keyValues[element.key] = (element.textValue).toString() === '' ? element.numericValue2 : element.textValue;
        });

        var fileCid = await this.UploadFileIpfs(files[0].src.file, files[0].src.file.name, keyValues)
        file.originalMimeType = files[0].type;
        file.name = files[0].name;
        file.fileName = files[0].name;
        file.originalname = files[0].name;
        file.originalCid = fileCid;
        file.Nftid = nft.id;

        if (previewImageFiles.length > 0) {
            var previewFileCid = await this.UploadFileIpfs(previewImageFiles[0].src.file, previewImageFiles[0].src.file.name, keyValues)
            file.previewMimeType = previewImageFiles[0].type;
            file.previewCid = previewFileCid;
        } else {
            file.previewMimeType = files[0].type;
            file.previewCid = fileCid;
        }

        var traitRequest = {
            nftid: nft.id,
            traits: traitList
        }

        var traitResponse = (await apiPostTraits(traitRequest)).data.data;
        var filPostResponse = (await apiPostFile(file)).data.data;
        await traitResponse;
        await filPostResponse;
        console.log("🚀 ~ file: ItemCreate.js ~ line 507 ~ ItemCreate ~ onSave= ~ traitResponse", traitResponse)
        console.log("🚀 ~ file: ItemCreate.js ~ line 509 ~ ItemCreate ~ onSave= ~ filPostResponse", filPostResponse)
        console.log("🚀 ~ file: ItemCreate.js ~ line 492 ~ ItemCreate ~ onSave= ~ nftLazyMint", nftLazyMint)

        this.setState({ isloading: false });
    };

    render() {
        const { provider, assets, address, isConnected, isAuthenticated, chainId, isFetching, events } = this.context;

        let { previewImageSrc, files, item, traitList, isLoading, categoryList } = this.state;

        return (
            <div>
                {
                    isConnected && isAuthenticated
                        ? <>
                            {isLoading ? <Loader
                                type="Puff"
                                color="#04d39f"
                                height="100"
                                width="100"
                            /> : null}

                            {/*   <LoadingOverlay isLoading={isLoading} /> */}
                            <div className="site-content">
                                <div className="content-wrapper section-ptb">
                                    <Container>
                                        <div className="product-content-top single-product single-product-edit">
                                            <Row>
                                                <div className="product-top-left col-xl-5 col-md-6">
                                                    <div className="product-top-left-inner">
                                                        <div className="ciyashop-product-images">
                                                            <div className="ciyashop-product-images-wrapper ciyashop-gallery-style-default ciyashop-gallery-thumb_position-bottom ciyashop-gallery-thumb_vh-horizontal">
                                                                <div className="ciyashop-product-gallery ciyashop-product-gallery--with-images slick-carousel">
                                                                    <div className="ciyashop-product-gallery__image">
                                                                        <img src={previewImageSrc == "" ? require(`../../../assets/images/${productdata.Product_single}`) : previewImageSrc} className="img-fluid" />
                                                                    </div>
                                                                </div>
                                                                {/*  <div className="ciyashop-product-thumbnails">
                                                        <Slider {...productslider} className="ciyashop-product-thumbnails__wrapper">
                                                            {productdata.product_gallery.map((pictureimage, index) =>
                                                                <div key={index}>
                                                                    <div className="ciyashop-product-thumbnail__image">
                                                                        <a href="javascript:void(0)">
                                                                            <img src={require(`../../../assets/images/${pictureimage}`)} className="img-fluid" />
                                                                        </a>
                                                                        <div className="d-flex justify-content-center image-content align-items-center">
                                                                            <ImageUploader
                                                                                buttonText=""
                                                                                withIcon={true}
                                                                                withPreview={true}
                                                                                fileTypeError={this.state.ErrorMsg}
                                                                                onChange={this.Uploadimage}
                                                                                imgExtension={['.jpg', '.jpeg', '.png', '.mp3']}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Slider>
                                                    </div> */}
                                                                <div className="clearfix" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="product-top-right col-xl-7 col-md-6">
                                                    <div className="product-top-right-inner">
                                                        <div className="summary entry-summary">
                                                            <Label className="title pl-0">Upload File</Label>
                                                            <FormGroup >
                                                                <br />
                                                                <Files
                                                                    multiple={false}
                                                                    maxSize="30mb"
                                                                    multipleMaxSize="30mb"
                                                                    accept={acceptFileUpload}
                                                                    convertToBase64={true}
                                                                    onSuccess={files => this.UploadFile(files)}
                                                                    onError={errors => (this.setState({ errors }), console.log("*errors* " + JSON.stringify(errors)))}>

                                                                    {({ browseFiles, getDropZoneProps }) => {
                                                                        return (
                                                                            <Col>
                                                                                <div className="d-flex justify-content-center align-items-center image-content align-items-center w-100 p-3"
                                                                                    {...getDropZoneProps({
                                                                                        style: {
                                                                                            minHeight: 140,
                                                                                            border: "2px lightgray dashed",
                                                                                            borderRadius: 20
                                                                                        }
                                                                                    })} >
                                                                                    <Col md={12} className="justify-content-center align-items-center"
                                                                                        style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                                                                                        <span> PNG, GIF, WEBP, MP4 or MP3. Max 30mb.</span>
                                                                                        <Button className="btn btn-primary mb-2 mr-2 mt-2" onClick={browseFiles}>{getLocalizedText("BROWSE_LABEL")}</Button>
                                                                                        <ul style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            flexWrap: "nowrap",
                                                                                            alignContent: "center",
                                                                                            alignItems: "center",
                                                                                            justifyContent: "flex-start",
                                                                                            padding: 0,
                                                                                        }}>
                                                                                            {this.state.files.map(file => (
                                                                                                <>
                                                                                                    {
                                                                                                        file.type.includes("video") ?
                                                                                                            <video controls="controls" loop={true} preload="none" autoPlay>
                                                                                                                <source type={file.type} src={files[0] != null ? files[0].src.base64 : ""} />
                                                                                                            </video>
                                                                                                            :
                                                                                                            file.type.includes("audio") ?
                                                                                                                <audio poster={""} controls="controls" preload="none">
                                                                                                                    <source type={"audio/ogg"} src={files[0] != null ? files[0].src.base64 : ""} />
                                                                                                                </audio>
                                                                                                                : null
                                                                                                    }
                                                                                                    <li key={file.name}>{file.name}</li>
                                                                                                    <Button color="danger"
                                                                                                        size={"sm"}
                                                                                                        onClick={this.removeFile}
                                                                                                        style={{ marginLeft: 10, padding: '5px 10px 5px 10px' }}
                                                                                                    >
                                                                                                        <i className="fa fa-trash mr-2"></i>
                                                                                                        {getLocalizedText("DELETE")}
                                                                                                    </Button>
                                                                                                </>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </Col>
                                                                                </div>
                                                                                <div>
                                                                                </div>
                                                                            </Col>
                                                                        );
                                                                    }}
                                                                </Files>
                                                            </FormGroup>
                                                            {
                                                                this.state.isPreviewImageRequirement == true ?
                                                                    <>
                                                                        <Label className="title pl-0">Upload Preview Image</Label>
                                                                        <FormGroup >
                                                                            <br />
                                                                            <Files
                                                                                multiple={false}
                                                                                maxSize="30mb"
                                                                                multipleMaxSize="30mb"
                                                                                accept={notRequirePreivewImage}
                                                                                convertToBase64={true}
                                                                                onSuccess={files => this.UploadPreviewImage(files)}
                                                                                onError={errors => (this.setState({ errors }), console.log("*errors* " + JSON.stringify(errors)))}
                                                                            >
                                                                                {({ browseFiles, getDropZoneProps }) => {
                                                                                    return (
                                                                                        <Col md={8}>
                                                                                            <div className="d-flex justify-content-center image-content align-items-center"
                                                                                                {...getDropZoneProps({
                                                                                                    style: {
                                                                                                        width: '100%',
                                                                                                        minHeight: 140,
                                                                                                        border: "2px lightgray dashed",
                                                                                                        borderRadius: 20
                                                                                                    }
                                                                                                })} >
                                                                                                <Row>
                                                                                                    <Col md={12} className="justify-content-center align-items-center"
                                                                                                        style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                                        <span> PNG, GIF, WEBP  Max 30mb.</span>
                                                                                                        <Button className="btn btn-primary mb-2 mr-2 mt-2" onClick={browseFiles}>{getLocalizedText("BROWSE_LABEL")}</Button>
                                                                                                        <Row>
                                                                                                            <ul style={{
                                                                                                                "display": "flex",
                                                                                                                "flexDirection": "row",
                                                                                                                "flexWrap": "nowrap",
                                                                                                                "alignContent": "center",
                                                                                                                "alignItems": "center",
                                                                                                                "justifyContent": "flex-start"
                                                                                                            }}>
                                                                                                                {this.state.previewImageFiles.map(file => (
                                                                                                                    <>
                                                                                                                        <li key={file.name}>{file.name}</li>
                                                                                                                        <Button color="danger" href="#"
                                                                                                                            size={"sm"}
                                                                                                                            onClick={this.removePreviewImage}
                                                                                                                            style={{ marginLeft: 10, padding: '5px 10px 5px 10px' }}
                                                                                                                        >
                                                                                                                            <i className="fa fa-trash mr-2"></i>
                                                                                                                            {getLocalizedText("DELETE")}
                                                                                                                        </Button>
                                                                                                                    </>
                                                                                                                ))}
                                                                                                            </ul>
                                                                                                        </Row>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                            <div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    );
                                                                                }}
                                                                            </Files>
                                                                        </FormGroup>
                                                                    </>
                                                                    : null
                                                            }
                                                            <FormGroup>
                                                                {
                                                                    this.renderCollection()
                                                                }
                                                            </FormGroup>
                                                            <FormGroup >
                                                                <Label className="title pl-0">Name</Label>
                                                                <Input type="text" name="name" value={this.state.item.name} className="form-control product_title" placeholder="Item Name" onChange={this.handleInputChange} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label className="title pl-0">Description </Label>
                                                                <Input type="textarea" name="description" className="form-control" rows="3" value={this.state.item.description} placeholder="Item Description" onChange={this.handleInputChange} />
                                                            </FormGroup>
                                                            <Label className="title mb-2">Category</Label>
                                                            <FormGroup>
                                                                {categoryList.map((category) =>
                                                                    <Label>
                                                                        <Input type="checkbox" value={category.id} onChange={(e) => this.handleCategoryChange(e)} />{' '}
                                                                        {getLocalizedText(category.name)}
                                                                    </Label>
                                                                )}
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label>
                                                                    <Input type="checkbox" name="isPhysical" value={this.state.item.isPhysical} onChange={(e) => this.handleInputChange(e, "checkbox")} />{' '}
                                                                    {getLocalizedText("Is Physical?")}
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label className="title pl-0">Quantity</Label>
                                                                <NumericInput name="quantity" placeholder=" " className='form-control'
                                                                    decimalScale={numericInputProps.decimalScale}
                                                                    value={this.state.item.quantity}
                                                                    thousandSeparator={numericInputProps.defaultThousandSeparator}
                                                                    decimalSeparator={numericInputProps.defaultDecimalSeparator}
                                                                    onChange={this.handleInputChange} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label className="title pl-0">Royalty Rate</Label>
                                                                <NumericInput name="royaltyRate" placeholder=" " className='form-control'
                                                                    decimalScale={numericInputProps.decimalScale}
                                                                    value={this.state.item.royaltyRate}
                                                                    thousandSeparator={numericInputProps.defaultThousandSeparator}
                                                                    decimalSeparator={numericInputProps.defaultDecimalSeparator}
                                                                    onChange={this.handleInputChange} />
                                                            </FormGroup>
                                                            <GroupItem titleStyle={{ color: '#000000', fontWeight: 'bold' }} title={getLocalizedText("Properties")}>
                                                                {this.renderTraits(TraitTypes.Property)}
                                                                <Button color="success"
                                                                    size={"sm"}
                                                                    onClick={() => this.addTraitProperty(TraitTypes.Property)}
                                                                    style={{ marginLeft: 10 }}>
                                                                    <i className="fa fa-plus-circle fa-lg" />
                                                                    {' '}{getLocalizedText("ADD")}
                                                                </Button>
                                                            </GroupItem>
                                                            <GroupItem titleStyle={{ color: '#000000', fontWeight: 'bold' }} title={getLocalizedText("Stats")}>
                                                                {this.renderTraits(TraitTypes.Stats)}
                                                                <Button color="success"
                                                                    size={"sm"}
                                                                    onClick={() => this.addTraitProperty(TraitTypes.Stats)}
                                                                    style={{ marginLeft: 10 }}>
                                                                    <i className="fa fa-plus-circle fa-lg" />
                                                                    {' '}{getLocalizedText("ADD")}
                                                                </Button>
                                                            </GroupItem>
                                                            <GroupItem titleStyle={{ color: '#000000', fontWeight: 'bold', paddingBottom: '20px'}} title={getLocalizedText("Levels")}>
                                                                {this.renderTraits(TraitTypes.Levels)}
                                                                <Button color="success"
                                                                    size={"sm"}
                                                                    onClick={() => this.addTraitProperty(TraitTypes.Levels)}
                                                                    style={{ marginLeft: 10 }}>
                                                                    <i className="fa fa-plus-circle fa-lg" />
                                                                    {' '}{getLocalizedText("ADD")}
                                                                </Button>
                                                            </GroupItem>
                                                            <Link to="/admin-panel/Product" className="btn btn-secondary mb-2"> Cancel </Link>
                                                            <a href="#" className="btn btn-primary mb-2 mr-2" onClick={() => this.onSave()}> Save </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                    </Container>
                                </div>
                            </div>
                        </>
                        : (
                            <WalletConnect></WalletConnect>
                        )
                }
            </div>
        )
    }
}
export default ItemCreate;
