/**
 * EndOfSeason Widget
 */
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class TrendingCollection extends Component {

    render() {
        return (
            <Row className="section-ptb section-1 align-items-center">
                <Col sm={12} lg={3} className="text-right mb-4 mb-lg-0">
                    <div className="section-title text-md-right">
                        <h2 className="title text-right">Trending collections</h2>
                        <p>Explore categories: Art, music, domains, virtual worlds, trading cards, Collection, Sports, Utility</p>
                        <div className="border-space" />
                    </div>
                    <div className="ciyashop_button_wrapper ciyashop_button_width_default">
                        <div className="inline_hover ciyashop_button_default ciyashop_button_border_square ciyashop_button_size_medium">
                            <Link to="/market"> Explore the marketplace </Link>
                        </div>
                    </div>
                </Col>
                <Col md={4} lg={3} className="mb-5 pb-1">
                    <div className="single_image-wrapper text-center shadow rounded">
                        <img src={require(`../assets/images/categories/default/art.png`)} className="img-fluid attachment-full" alt="season-img1" />
                    </div>
                    <Row className="single_image-content">
                        <Col sm={3} className="col-2 px-0">
                            <div className="border-space border-space-dark" />
                        </Col>
                        <Col sm={9} className="col-10">
                            <div className="text-wrapper">
                                <p className="text-dark">Art</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={4} lg={3} className="mb-5 mt-4 mt-md-0">
                    <div className="single_image-wrapper text-center shadow rounded">
                        <img src={require(`../assets/images/categories/default/music.png`)} className="img-fluid attachment-full" alt="season-img2" />
                    </div>
                    <Row className="single_image-content">
                        <Col sm={3} className="col-2 px-0">
                            <div className="border-space border-space-dark" />
                        </Col>
                        <Col sm={9} className="col-10">
                            <div className="text-wrapper">
                                <p className="text-dark">Music</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} md={4} lg={3} className="mb-5 mt-4 mt-md-0 pt-1 pt-md-0">
                    <div className="single_image-wrapper text-center shadow rounded">
                        <img src={require(`../assets/images/categories/default/domain.png`)} className="img-fluid attachment-full" alt="season-img2" />
                    </div>
                    <Row className="single_image-content">
                        <Col sm={3} className="col-2 px-0">
                            <div className="border-space border-space-dark" />
                        </Col>
                        <Col sm={9} className="col-10">
                            <div className="text-wrapper">
                                <p className="text-dark">Domain Names</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

        )
    }
}

export default TrendingCollection;
