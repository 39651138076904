import * as React from "react";
import { Button } from "reactstrap";
import { Web3Context } from '../Web3Provider';
import Loader from "react-loader-spinner";
import {
  ETH_SEND_TRANSACTION,
  ETH_SIGN,
  PERSONAL_SIGN,
  BOX_GET_PROFILE
} from "../../../common/constants";

export default class WalletSignIn extends React.Component<any, any> {

  static contextType = Web3Context;
  context!: React.ContextType<typeof Web3Context>

  public render = () => {
    const { assets, address, isConnected, isAuthenticated, chainId, isFetching, events } = this.context;
    return (
      <>
        {isConnected /* && isAuthenticated */ ? (
          ""
          // <div>
          //   <Button color="danger" onClick={events.resetApp}>Disconnect</Button>
          //   <h3>Actions</h3>
          //   <div>
          //     <Button color="warning" onClick={events.testSendTransaction}>
          //       {ETH_SEND_TRANSACTION}
          //     </Button>
          //     <Button color="warning" onClick={events.testSignMessage}>
          //       {ETH_SIGN}
          //     </Button>
          //     <Button color="warning" onClick={() => events.testSignPersonalMessage("test")}>
          //       {PERSONAL_SIGN}
          //     </Button>
          //     <Button color="warning" onClick={events.testOpenBox}>
          //       {BOX_GET_PROFILE}
          //     </Button>
          //     <br />
          //     {/* TODO TEMP */}
          //     <Button color="primary" onClick={events.testCreateNFT}>
          //       {'Test Creating NFT'}
          //     </Button>
          //     <Button color="primary" onClick={() => events.balanceOf("85592847012508915598698695152465576977")}>
          //       {'BALANCE OF '}
          //     </Button>
          //     <Button color="primary" onClick={() => events.getRoyalty("85592847012508915598698695152465576977")}>
          //       {'Royalty Rate'}
          //     </Button>
          //   </div>
          //   {isFetching ?
          //     <Loader
          //       type="Puff"
          //       color="#04d39f"
          //       height="100"
          //       width="100"
          //     /> : !!assets && !!assets.length && (
          //       <>
          //         <h3>Address</h3>
          //         <h6>{address}</h6>
          //         <h3>Balances</h3>
          //         <div>{JSON.stringify({ chainId, assets }, null, 2)}</div>
          //       </>
          //     )}
          // </div>
        )
          : <div>
            <Button className="btn btn-primary" onClick={events.onConnect}>CONNECT WALLET</Button>
          </div>
        }
      </>
    )
  };
}