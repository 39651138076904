
/**
 * PropertyStats Widget
 */
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class PropertyStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }


    render() {
        const { propertyKey, value1, value2 } = this.props;

        return (
            <Col md={4} style={{ float: 'left' }}>
                <div style={{
                    width: '100%',
                    height: 'auto',
                    padding: 10,
                    paddingBottom: 0,
                    margin: 10,
                    border: '1px solid rgb(89, 81, 84)',
                    backgroundColor: 'rgba(154, 149, 148, 0.1)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                }}>
                    <Row >
                        <Col md={8} style={{ float: 'left' }}>
                            <h6 style={{
                                fontSize: 16,
                                textAlign: 'left',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>{propertyKey}</h6>
                        </Col>
                        <Col md={4} style={{ float: 'right' }}>
                            <p style={{ fontSize: 15, textAlign: 'right' }}><strong>{value1}</strong> of <strong>{value2}</strong> </p>
                        </Col>
                    </Row>
                </div>
            </Col>
        )
    }
}

export default PropertyStats;
