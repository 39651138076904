
/**
 * PropertyLevels Widget
 */
import React, { Component } from 'react';
import { Row, Col, Progress } from 'reactstrap';

class PropertyLevels extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }


    render() {
        const { propertyKey, min, max, value } = this.props;

        return (
            <Col md={4} style={{ float: 'left' }}>
                <div style={{
                    width: '100%',
                    height: 'auto',
                    padding: 10,
                    margin: 10,
                    border: '1px solid rgb(89, 81, 84)',
                    backgroundColor: 'rgba(154, 149, 148, 0.1)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                }}>
                    <Row style={{ margin: 2 }}>
                        <Col md={8} style={{ float: 'left' }}>
                            <h6 style={{
                                fontSize: 16,
                                textAlign: 'left',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>{propertyKey}</h6>
                        </Col>
                        <Col md={4} style={{ float: 'right', paddingRight: 0 }}>
                            <p style={{ fontSize: 15, textAlign: 'center' }}><strong>{value}</strong> of <strong>{min}/{max}</strong> </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Progress  value={value} min={min} max={max} striped={false} animated={true} barStyle={{ backgroundColor: '#fe327e' }} >
                            </Progress>
                        </Col>

                    </Row>
                </div>
            </Col>
        )
    }
}

export default PropertyLevels;
