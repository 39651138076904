import _ from 'lodash';
import { getChainData } from './helpers/utilities';

export const IdentityURL = process.env.REACT_APP_IDENTITY_URL;
export const AdminURL = process.env.REACT_APP_ADMIN_URL;
export const ApiURL = process.env.REACT_APP_API_URL;
export const ApiURL_2 = process.env.REACT_APP_API2_URL;
export const ApiURL_3 = process.env.REACT_APP_API3_URL;
export const KibanaURL = process.env.REACT_APP_KIBANA_URL;
export const SchedulerURL = process.env.REACT_APP_SCHEDULER_URL;
export const IPFSProviderURL = process.env.REACT_APP_IPFS_PROVIDER_URL;

export const DEFAULT_CACHE_EXPIRATION_MIN = 5;
export const DEFAULT_CACHE_LOCK_TIMEOUT_MS = 8000;
export const DEFAULT_CACHE_STORAGE = "local";

export const DEFAULT_GRID_BLOCK_SIZE = 100;

export const ERROR_MESSAGE_COLUMN_NAME = "errorMessage";

export const CHANGE_PASSWORD_URL = '/changePassword';

export const DEFAULT_LANGUAGE_CODE = "en";

//#region Enums

export enum UserStatus {
  DELETED = -1,
  WAITING_FOR_APPROVAL,
  STILL_WORKS,
  QUITTED,
  WAITING_FOR_VERIFICATION
}

export enum ThemeColors {
  SUCCESS = "#4DBD74",
  WARNING = "#F9B115",
  DANGER = "#E6471E",
}

export enum TraitTypes {
  Property = "1",
  Stats = "2",
  Levels = "3",
}

export enum TokenProtocol {
  ERC721 = "ERC721",
  ERC1155 = "ERC1155"
};

export enum ContractPresets {
  Auction,
  ERC721,
  ERC1155,
  Sell
};

export enum SaleStatus {
  Success = 1,
  Error = 2,
  Complete = 3,
}

export const SOCIAL_INFOS = [
  {
    "title": "linkedin",
    "link": "https://www.linkedin.com/company/hebysio",
    "icon": "fa fa-linkedin"
  },
  {
    "title": "twitter",
    "link": "https://twitter.com/HebysIo",
    "icon": "fa fa-twitter"
  },
  {
    "title": "telegram",
    "link": "https://t.me/hebysio",
    "icon": "fa fa-telegram"
  },
  {
    "title": "instagram",
    "link": "https://www.instagram.com/hebysio",
    "icon": "fa fa-instagram"
  }
  /*{
    "title": "google",
    "link": "https://google.com/",
    "icon": "fa fa-google"
  },
  {
    "title": "facebook",
    "link": "https://www.facebook.com",
    "icon": "fa fa-facebook"
  }, */
  // {
  //   "title": "vimeo",
  //   "link": "https://vimeo.com/",
  //   "icon": "fa fa-vimeo"
  // },
  // {
  //   "title": "pinterest",
  //   "link": "https://in.pinterest.com/",
  //   "icon": "fa fa-pinterest"
  // },
];

//#endregion Enums

export const ERC721_CONTRACT_ADDRESS = process.env.REACT_APP_ERC721_CONTRACT_ADDRESS;
export const ERC1155_CONTRACT_ADDRESS = process.env.REACT_APP_ERC1155_CONTRACT_ADDRESS;
export const SELL_CONTRACT_ADDRESS = process.env.REACT_APP_DIRECT_SELL_CONTRACT_ADDRESS;
export const UI_NFT_DETAIL_URL = process.env.REACT_APP_UI_URL;
export const ETH_SEND_TRANSACTION = 'eth.sendTransaction()'
export const ETH_SIGN = 'eth.sign()'
export const PERSONAL_SIGN = 'personal.sign()'
export const BOX_GET_PROFILE = 'box.getProfile()'

export const FUNCTION_BALANCE_OF = "balanceOf";
export const FUNCTION_TRANSFER = "transfer";

export const SUPPORTED_CHAIN_IDS = [3];
export const SUPPORTED_NETWORKS = SUPPORTED_CHAIN_IDS.map(chainId => getChainData(chainId));
export const SUPPORTED_NETWORKS_IDS = SUPPORTED_NETWORKS.map(chainData => chainData.network_id);

export enum ErrorCode {
  CONNECTED_ACCOUNT_NOT_EXISTS = "CONNECTED_ACCOUNT_NOT_EXISTS",
  UNSUPPORTED_CHAIN = "UNSUPPORTED_CHAIN",
  UNSUPPORTED_NETWORK = "UNSUPPORTED_NETWORK",
}


export default {
  AdminURL, IdentityURL, ApiURL, ApiURL_2, ApiURL_3, KibanaURL, SchedulerURL,
  DEFAULT_CACHE_EXPIRATION_MIN, DEFAULT_CACHE_LOCK_TIMEOUT_MS, DEFAULT_CACHE_STORAGE,
  DEFAULT_GRID_BLOCK_SIZE, CHANGE_PASSWORD_URL, UserStatus, TraitTypes, ERROR_MESSAGE_COLUMN_NAME, UI_NFT_DETAIL_URL,
  SOCIAL_INFOS, SELL_CONTRACT_ADDRESS, SaleStatus
}