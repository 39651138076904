/**
 *  Admin Site Product Listing Page
 */
import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import productdata from '../../api/product';
import { apiGetMyNftList } from '../../common/helpers/api';
import Pagination from '../../services/Pagination';
import AdminproductList from '../../widgets/AdminProduct';
import { Web3Context } from '../Wallet/Web3Provider';
import WalletConnectLink from '../Wallet/widgets/WalletConnectLink';
import Loader from 'react-loader-spinner';

class AdminProduct extends Component {
    static contextType = Web3Context;

    constructor(props) {
        super(props);
        this.state = {
            productsearch: '',
            nftList: [],
            currentNftList: [],
            currentPage: null,
            totalPages: null,
            cp_NftList: [],
            IsDeleteProcess: false,
            isLoading: false,
        }

        this.context = React.createContext(Web3Context);
    }
    componentDidMount() {
        window.scrollTo(0, 0);

        this.getNftList()
    }

    getNftList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria: {
                      /* status: 1 */
                },
            };

            var nftListResponse = (await apiGetMyNftList(
                request
            )).data;

            var nftList = nftListResponse.data;
            var cp_NftList = nftListResponse.data;
            var currentNftList = nftListResponse.data;
            console.log("🚀 ~ file: AdminProductList.js ~ line 45 ~ AdminProduct ~ getNftList= ~ nftList", nftList)
            this.setState({ nftList, cp_NftList, currentNftList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
    };

    onProductSearch(searchproduct) {
        if (searchproduct === '') {
            var curr_nfts = this.state.cp_NftList.slice((this.state.currentPage - 1) * 12, ((this.state.currentPage - 1) * 12) + 12);
            this.setState({
                ...this.state,
                productsearch: searchproduct,
                nftList: this.state.cp_NftList,
                currentNftList: curr_nfts,
                totalRecords: this.state.cp_NftList.length,
                IsDeleteProcess: true,
            })
            this.state.currentNftList = curr_products;
            this.forceUpdate();
            this.render();

        }
        else {
            let serachdata = this.state.cp_NftList.filter((productname) => {
                if (searchproduct === searchproduct.toLowerCase()) {
                    let nft = productname.name.toLowerCase().indexOf(searchproduct.toLowerCase()) > -1
                    return (
                        nft
                    )
                }
                else {
                    let nft = productname.name.toUpperCase().indexOf(searchproduct.toUpperCase()) > -1
                    return (
                        nft
                    )
                }

            })
            var curr_products = null;
            if (serachdata.length > 12) {
                curr_products = serachdata.slice(0, 12);
            }
            else {
                curr_products = serachdata;
            }

            this.setState({
                ...this.state,
                productsearch: searchproduct,
                nftList: serachdata,
                currentNftList: curr_products,
                totalRecords: searchproduct.length,
                IsDeleteProcess: false
            })
            this.state.currentNftList = curr_products;
            this.forceUpdate();
            this.render();
        }
    }

    onPageChanged = data => {
        const { nftList } = this.state;
        const { currentPage, totalPages, pageLimit } = data;

        const offset = (currentPage - 1) * pageLimit;
        const currentNftList = nftList.slice(offset, offset + pageLimit);
        this.setState({
            ...this.state,
            currentPage: currentPage,
            currentNftList: currentNftList,
            totalPages: totalPages
        });
    };

    onDeleteProduct(productdata) {
        if (productdata) {
            let deletedproduct = productdata;
            let newproduct = this.state.nftList.filter((currentNftList) => currentNftList.id !== deletedproduct.id);
            let curr_nfts = newproduct.filter((currentNftList) => currentNftList.id !== deletedproduct.id);
            curr_nfts = curr_nfts.slice((this.state.currentPage - 1) * 12, ((this.state.currentPage - 1) * 12) + 12);
            let tpg = newproduct.length / 12;

            this.setState({
                ...this.state,
                nftList: newproduct,
                currentNftList: curr_nfts,
                totalRecords: newproduct.length,
                totalPages: tpg,
                IsDeleteProcess: true
            })
        }
    }
    
    render() {
        const { provider, assets, address, isConnected, isAuthenticated, chainId, isFetching, events } = this.context;
        //TODO:video and audio thubnail, description
        const { isLoading } = this.state;
        return (
            <>
                {
                    isConnected && isAuthenticated
                        ? <>
                            {isLoading ? <Loader
                                type="Puff"
                                color="#04d39f"
                                height="100"
                                width="100"
                            /> : null}
                            <div>
                                <div className="section-ptb">
                                    <Container>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="mb-0">
                                                    <h4>NFT List</h4>
                                                </div>
                                                <div className="mb-4">
                                                    <form>
                                                        <div class="form-group mb-0">
                                                            <input type="text" class="form-control" placeholder="Search product" value={this.state.productsearch} onChange={(e) => { this.onProductSearch(e.target.value) }}></input>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div className="mb-0 mb-md-4">
                                                    {this.state.currentNftList.length > 0 ?
                                                        <Row className="products products-loop grid ciyashop-products-shortcode pgs-product-list">
                                                            {this.state.currentNftList.map((nft, index) =>
                                                                <AdminproductList nft={nft} key={index} deleteproduct={() => this.onDeleteProduct(nft)} mintNFT={() => events.mintNFT(nft.id)} />
                                                            )
                                                            }
                                                        </Row>
                                                        :
                                                        <Row className="products products-loop grid ciyashop-products-shortcode">
                                                            <div className="col-sm-12 text-center  mt-4 mt-md-5" >
                                                                <img src={require(`../../assets/images/empty-search.jpg`)} className="img-fluid mb-4" />
                                                                <h3>Sorry! No NFT were found matching your selection!    </h3>
                                                                <p>Please try different criterias to search</p>
                                                            </div>
                                                        </Row>
                                                    }
                                                </div>
                                                <div className="row mt-md-3">
                                                    <div className="col-12">
                                                        {this.state.nftList.length > 12 ?
                                                            <div>
                                                                <Pagination
                                                                    totalRecords={this.state.nftList.length}
                                                                    pageLimit={12}
                                                                    onPageChanged={this.onPageChanged}
                                                                    IsDeleteProcess={this.state.IsDeleteProcess}
                                                                />
                                                            </div>
                                                            :
                                                            <div style={{ display: 'none' }}>
                                                                <Pagination
                                                                    totalRecords={this.state.nftList.length}
                                                                    pageLimit={12}
                                                                    onPageChanged={this.onPageChanged}
                                                                    IsDeleteProcess={this.state.IsDeleteProcess}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Container>
                                </div>
                            </div>
                        </>
                        : (
                            <WalletConnectLink></WalletConnectLink>
                        )
                }
            </>
        )
    }
}
export default AdminProduct;
