/**
 *  Shop Cart Detail Page
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup";
import {
    Col, Container, Row, Table, Input, Modal, ModalBody
} from 'reactstrap';
import Select from 'react-select';
import NumericInput from 'react-number-format';
import { getLocalizedText } from '../../common/helpers/localizationManager';
import GroupItem from '../../widgets/GroupItem';
import { apiGetCoin, apiPostSetSale, apiPostUpdaateSetSale, } from "../../common/helpers/api";
import { Web3Context } from '../Wallet/Web3Provider';
import _ from 'lodash';
import constants from '../../common/constants';

const salesTypeListStyle = {
    padding: 10,
    margin: 10,
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: 5,
    color: '#000000'
};

const numericInputProps = {
    decimalScale: 2,
    decimalSeparator: ',',
    defaultThousandSeparator: ',',

};

class Sell extends Component {
    static contextType = Web3Context;
    constructor(props) {
        super(props);
        this.state = {
            saleItem: {
                bundleItems: [],
                salesType: "1",
                coinId: 0,
                isPrivate: false,
            },
            errors: {},
            selectedCoinOption: null,
            isBundleSell: false,
            coinList: [],
            coinOptionList: [],
            actionList: [],
            hasPendingRequest: false,
            showModal: false,
            errorMessage: "",
        };

        this.context = React.createContext(Web3Context);
        this.ReadSellItems = this.ReadSellItems.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        var evt = document.createEvent('Event');
        evt.initEvent('load', false, false);
        window.dispatchEvent(evt);
        window.scrollTo(0, 0);

        this.getCoinList();
        var SellItems = this.ReadSellItems();
        var saleItem = this.state.saleItem;
        var isBundleSell = this.state.isBundleSell;

        SellItems.forEach(item => {
            saleItem.bundleItems.push(item);
        });

        if (SellItems.length > 0)
            isBundleSell = true;

        this.setState({ saleItem, isBundleSell });
    }

    getCoinList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria: {
                    status: 1
                },
            };

            var coinResponse = (await apiGetCoin(
                request
            )).data;

            var coinList = coinResponse.data.list;
            console.log("🚀 ~ file: Sell.js ~ line 101 ~ Sell ~ getCoinList= ~ coinList", coinList)
            var coinOptionList = [];

            coinList.forEach(element => {
                coinOptionList.push({ value: element.id, label: `${element.symbol}` })
            });

            this.setState({ coinList, coinOptionList, isloading: false })
        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
    };

    setSalesType = (salesType) => {
        const item = this.state.salesType;
        item.salesType = salesType;

        this.setState({ item });
    };

    handleInputChange(event, field, type) {
        const saleItem = { ...this.state.saleItem };
        var newValue;

        if (type == 'checkbox')
            newValue = event.target.checked;
        else
            newValue = event.target.value;

        saleItem[field] = newValue;

        this.setState({ saleItem });
    }

    handleCoinhange = (selectedOption) => {
        var value = selectedOption.value;
        var saleItem = this.state.saleItem;
        saleItem.coinId = value;

        this.setState({ selectedCoinOption: selectedOption, saleItem });
    };

    handleBundleItemInputChange(event, field, index) {
        const saleItem = { ...this.state.saleItem };
        var newValue = event.target.value;

        saleItem.bundleItems[index][field] = newValue;

        localStorage.removeItem("LocalSellItems");
        localStorage.setItem("LocalSellItems", JSON.stringify(saleItem.bundleItems));
        this.setState({ saleItem });
    }

    ReadSellItems() {
        return JSON.parse(localStorage.getItem("LocalSellItems"));
    }

    removeFromSellList = (index, ItemId) => {
        var UpdatedSell = JSON.parse(localStorage.getItem("LocalSellItems"));
        UpdatedSell = UpdatedSell.slice(0, index).concat(UpdatedSell.slice(index + 1, UpdatedSell.length));
        localStorage.removeItem("LocalSellItems");
        localStorage.setItem("LocalSellItems", JSON.stringify(UpdatedSell));

        var saleItem = this.state.saleItem;
        saleItem.bundleItems = saleItem.bundleItems.filter(function (value, filterIndex, arr) {
            console.log("🚀 ~ file: Sell.js ~ line 141 ~ Sell ~ value", value)
            return value.NFTId != ItemId;
        });

        this.setState({ saleItem });
    }

    calculateTotalPrice = () => {
        var saleItem = this.state.saleItem;
        var totalPrice = 0;
        saleItem.bundleItems.forEach(element => {
            totalPrice = +(totalPrice + (element.unitPrice * element.quantity))
        });
        return totalPrice;
    }

    getAction = () => {
        var actionList = this.state.actionList;
        let descActionList = _(actionList).orderBy(undefined, ['asc']).value();
        let firstHistoryItem = _.first(descActionList);
        return firstHistoryItem;
    }

    setAction = (action) => {
        var actionList = this.state.actionList;
        actionList.push(action);
        this.setState({ actionList, hasPendingRequest: true, showModal: true });
    }

    onSell = async () => {
        try {
            this.setState({ isloading: true, actionList: [] });
            let { saleItem } = this.state;
            let { contractHandler, address, web3 } = this.context

            var sellContract = await contractHandler.getSellContract();
            var creatorNftContract = await contractHandler.getERC1155Contract();

            var sellContractAddress = constants.SELL_CONTRACT_ADDRESS;

            this.setAction("Your authorization is checked from the NFT produced so that you can put it on sale.")
            var isOwnerApproved = await creatorNftContract.callIsApprovedForAllAsync(address, sellContractAddress);

            if (!isOwnerApproved) {
                this.setAction("Authorization is required for the NFT produced in order to put it up for sale.")

                await creatorNftContract.sendSetApprovalForAllAsync(sellContractAddress, true);

                this.setState({ hasPendingRequest: false });
            }

            var sellRequest = { sellItem: saleItem.bundleItems, ...saleItem }

            this.setAction("Sales Information is added to the system as Off-Chain");
            var apiPostSellResponse = await (await apiPostSetSale(sellRequest)).data;
            var _sellId;
            if (apiPostSellResponse.success) {
                this.setAction("Proccess Success");
                var saleAndAuction = apiPostSellResponse.data;
                _sellId = saleAndAuction.id;
                var _saleItem = saleItem.bundleItems[0];
                this.setAction("Sales information is added to on-Chain via Smart Contract");
                //TODO: bundle Sale
                try {
                    var gweiUnitPrice = web3.utils.toWei(saleItem.bundleItems[0].unitPrice, "ether");

                    var setSellTransaction = await sellContract.setForSaleAsync(_sellId,
                        address,
                        _saleItem.tokenId,
                        gweiUnitPrice,
                        _saleItem.quantity,
                        _saleItem.creatorNftContract,
                        saleItem.isPrivate,
                        saleItem.isPrivate ? saleItem.privateBuyerAccountAddress : "0x0000000000000000000000000000000000000000");
                    var txId = await setSellTransaction.getTxId();
                    this.setAction(`TxId of the transmitted transaction: ${txId}`);
                    localStorage.removeItem("LocalSellItems");

                    var UpdateSellRequest = {
                        Id: _sellId,
                        txId: txId,
                        status: constants.SaleStatus.Success
                    };
                    await apiPostUpdaateSetSale(UpdateSellRequest);
                }
                catch (error) {
                    UpdateSellRequest.status = constants.SaleStatus.Error;
                    await apiPostUpdaateSetSale(UpdateSellRequest);
                }
            }
            else {
                this.setAction("An error occurred while added Sales Information to the system as Off-Chain.");
            }

        } catch (error) {
            console.log("🚀 ~ file: Sell.js ~ line 249 ~ Sell ~ onSell= ~ setSellTransaction", error)
            this.setState({ hasPendingRequest: false });
        }

        this.setState({ isloading: false, showModal: false });
    };

    toggleModal = () => this.setState({ showModal: !this.state.showModal });

    render() {
        let { errorMessage, showModal, hasPendingRequest, isConnecting } = this.state;
        return (
            <>
                <Modal className="modal-delete modal-lg modal-dialog-centered"
                    isOpen={showModal} toggle={this.toggleModal} backdrop="static">
                    <ModalBody>
                        {!!errorMessage
                            ? (
                                <>
                                    <small>{errorMessage}</small>
                                </>
                            )
                            : hasPendingRequest
                                ? (
                                    <>
                                        <div>{"Pending Call Request"}</div>
                                        <div>
                                            <div>
                                                {this.getAction()}
                                            </div>
                                        </div>
                                    </>
                                )
                                : isConnecting
                                    ? (
                                        <div>{"Connecting wallet."}</div>
                                    )
                                    :
                                    (
                                        <div>{"Call Request Rejected"}</div>
                                    )}
                    </ModalBody>
                </Modal>
                <div className="site-content">
                    <div className="content-wrapper ">{/* section-ptb */}
                        <Container>
                            <Row className="align-items-left justify-content-left" style={{ marginTop: 50 }}>
                                <Col md={11} lg={7} className="text-left">
                                    <Link to="/admin-panel/Product" className="btn-white btn btn-primary" style={{ marginBottom: 20 }}>
                                        <i className="fa  fa-arrow-left" style={{ marginRight: 4 }} />
                                            Go Back
                                         </Link>
                                </Col>
                            </Row>
                            {(this.ReadSellItems() != null && this.ReadSellItems().length > 0) ?
                                <Row>
                                    <Col xl={8}>
                                        <GroupItem titleStyle={{ color: '#000000', fontWeight: 'bold' }} title={getLocalizedText("Items")}>
                                            <div className="table-responsive">
                                                <Table className="cart-table" style={{ border: 'none' }}>
                                                    <thead>
                                                        <tr>
                                                            <th clas="product-remove"></th>
                                                            <th className="product-thumbnail"></th>
                                                            <th className="product-name">
                                                                <span className="nobr">Item</span>
                                                            </th>
                                                            <th className="product-stock-status">
                                                                Quantity
                                                        </th>
                                                            <th className="product-stock-status">
                                                                Unit Price
                                                        </th>
                                                        </tr>

                                                        {this.ReadSellItems().map((item, index) => (
                                                            <tr>
                                                                <td className="product-remove">
                                                                    <Link onClick={() => this.removeFromSellList(index, item.NFTId)} className="remove"></Link>
                                                                </td>
                                                                <td className="product-thumbnail">
                                                                    <Link to="#">
                                                                        {/*  require(`../../assets/images/${item.previewImageSrc}`) */}
                                                                        <img src={item.previewImageSrc} alt="Item" />
                                                                    </Link>
                                                                </td>
                                                                <td className="product-name">
                                                                    {item.name}
                                                                </td>
                                                                <td className="product-quantity">
                                                                    <div className="form-group">
                                                                        <NumericInput placeholder=" " className='form-control'
                                                                            value={item.quantity}
                                                                            decimalScale={numericInputProps.decimalScale}
                                                                            thousandSeparator={numericInputProps.defaultThousandSeparator}
                                                                            decimalSeparator={numericInputProps.defaultDecimalSeparator}
                                                                            onChange={(e) => this.handleBundleItemInputChange(e, "quantity", index)} />
                                                                        {/*  <span className="error">{this.state.errors["quantity"]}</span> */}
                                                                    </div>
                                                                </td>
                                                                <td className="product-quantity">
                                                                    <div className="form-group">
                                                                        <NumericInput placeholder=" " className='form-control'
                                                                            value={item.unitPrice}
                                                                            decimalScale={numericInputProps.decimalScale}
                                                                            thousandSeparator={numericInputProps.defaultThousandSeparator}
                                                                            decimalSeparator={numericInputProps.defaultDecimalSeparator}
                                                                            onChange={(e) => this.handleBundleItemInputChange(e, "unitPrice", index)} />
                                                                        {/*  <span className="error">{this.state.errors["quantity"]}</span> */}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </thead>
                                                </Table>
                                            </div>
                                        </GroupItem>

                                        <div className="billing-fields mt-5">
                                            <h3>Sell Details</h3>
                                            <div className="billing-fields__field-wrapper">
                                                <div className="form-group">
                                                    <ul id="shipping_method" className="shipping-methods" style={{ padding: 0, listStyle: 'none', display: 'flex' }}>
                                                        <a onClick={() => this.setSalesType("1")}>
                                                            <div style={{
                                                                ...salesTypeListStyle,
                                                                border: `1px solid ${this.state.saleItem.salesType == "1" ? "#04d39f" : '#000000'}`,
                                                                backgroundColor: `${this.state.saleItem.salesType == "1" ? 'rgba(4, 211, 159,0.1)' : 'transparent'}`
                                                            }}>
                                                                <li>
                                                                    <b><label style={{ float: 'center' }}>Set Price</label></b>
                                                                    <label style={{ cursor: 'pointer' }}>Set All fixed declaning Price</label>
                                                                </li>
                                                            </div>
                                                        </a>
                                                        <a onClick={() => this.setSalesType("2")}>
                                                            <div style={{
                                                                ...salesTypeListStyle,
                                                                border: `1px solid ${this.state.saleItem.salesType == "2" ? "#04d39f" : '#000000'}`,
                                                                backgroundColor: `${this.state.saleItem.salesType == "2" ? 'rgba(4, 211, 159,0.1)' : 'transparent'}`
                                                            }}>
                                                                <li>
                                                                    <b><label style={{ float: 'center' }}>Highest Bid</label></b>
                                                                    <label style={{ cursor: 'pointer' }}>Autction Highest Bidder</label>
                                                                </li>
                                                            </div>
                                                        </a>
                                                    </ul>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="billing_first_name" className="">Coin&nbsp;<abbr className="required" title="required">*</abbr>
                                                    </label>
                                                    <Select
                                                        value={this.state.selectedCoinOption}
                                                        onChange={this.handleCoinhange}
                                                        options={this.state.coinOptionList} />
                                                    <span className="error">{this.state.errors["coin"]}</span>
                                                </div>
                                                {

                                                    this.state.saleItem.salesType == "1" ?
                                                        !this.state.isBundleSell ?
                                                            <div className="form-group">
                                                                <label htmlFor="billing_first_name" className="">Price&nbsp;<abbr className="required" title="required">*</abbr>
                                                                </label>
                                                                <NumericInput placeholder=" " className='form-control'
                                                                    value={this.state.saleItem.price}
                                                                    decimalScale={numericInputProps.decimalScale}
                                                                    thousandSeparator={numericInputProps.defaultThousandSeparator}
                                                                    decimalSeparator={numericInputProps.defaultDecimalSeparator}
                                                                    onChange={(e) => this.handleInputChange(e, "price")} />
                                                                <span className="error">{this.state.errors["price"]}</span>
                                                            </div>
                                                            : null
                                                        :
                                                        this.state.saleItem.salesType == "2" ?
                                                            <>
                                                                <div className="form-group">
                                                                    <label htmlFor="billing_first_name" className="">auctionBegDate&nbsp;<abbr className="required" title="required">*</abbr>
                                                                    </label>
                                                                    <Input type="date" name="auctionBegDate" className='form-control'
                                                                        value={this.state.saleItem.auctionBegDate} onChange={(e) => this.handleInputChange(e, "auctionBegDate")} />
                                                                    <span className="error">{this.state.errors["auctionBegDate"]}</span>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="billing_first_name" className="">auctionBegTime&nbsp;<abbr className="required" title="required">*</abbr>
                                                                    </label>
                                                                    <Input type="time" name="auctionBegTime" className='form-control'
                                                                        value={this.state.saleItem.auctionBegTime} onChange={(e) => this.handleInputChange(e, "auctionBegTime")} />
                                                                    <span className="error">{this.state.errors["auctionBegTime"]}</span>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="billing_first_name" className="">auctionEndDate&nbsp;<abbr className="required" title="required">*</abbr>
                                                                    </label>
                                                                    <Input type="date" name="auctionEndDate" className='form-control'
                                                                        value={this.state.saleItem.auctionEndDate} onChange={(e) => this.handleInputChange(e, "auctionEndDate")} />
                                                                    <span className="error">{this.state.errors["auctionEndDate"]}</span>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="billing_first_name" className="">auctionEndTime&nbsp;<abbr className="required" title="required">*</abbr>
                                                                    </label>
                                                                    <Input type="time" name="auctionEndTime" className='form-control'
                                                                        value={this.state.saleItem.auctionEndTime} onChange={(e) => this.handleInputChange(e, "auctionEndTime")} />
                                                                    <span className="error">{this.state.errors["auctionEndTime"]}</span>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="billing_first_name" className="">biddingIncrement&nbsp;<abbr className="required" title="required">*</abbr>
                                                                    </label>
                                                                    <Input type="number" name="auctionEndDate" className='form-control'
                                                                        value={this.state.saleItem.biddingIncrement} onChange={(e) => this.handleInputChange(e, "biddingIncrement")} />
                                                                    <span className="error">{this.state.errors["biddingIncrement"]}</span>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="billing_first_name" className="">auctionClosingPrice&nbsp;<abbr className="required" title="required">*</abbr>
                                                                    </label>
                                                                    <NumericInput placeholder=" " className='form-control'
                                                                        value={this.state.saleItem.auctionClosingPrice}
                                                                        decimalScale={numericInputProps.decimalScale}
                                                                        thousandSeparator={numericInputProps.defaultThousandSeparator}
                                                                        decimalSeparator={numericInputProps.defaultDecimalSeparator}
                                                                        onChange={(e) => this.handleInputChange(e, "auctionClosingPrice")} />
                                                                    <span className="error">{this.state.errors["auctionClosingPrice"]}</span>
                                                                </div>
                                                            </>
                                                            : null}
                                                {
                                                    !this.state.isBundleSell ?
                                                        <div className="form-group">
                                                            <label className="">Quantity&nbsp;<abbr className="required" title="required">*</abbr>
                                                            </label>
                                                            <NumericInput placeholder=" " className='form-control'
                                                                decimalScale={5}
                                                                thousandSeparator={numericInputProps.defaultThousandSeparator}
                                                                decimalSeparator={numericInputProps.defaultDecimalSeparator}
                                                                onChange={(e) => this.handleInputChange(e, "quantity")}
                                                            />
                                                            <span className="error">{this.state.errors["quantity"]}</span>
                                                        </div>
                                                        : null
                                                }
                                                <div className="form-group" style={{ paddingLeft: 20 }}>
                                                    <Input type="checkbox" name="isPrivate" style={{ paddingLeft: 0 }}
                                                        value={this.state.saleItem.isPrivate} onChange={(e) => this.handleInputChange(e, "isPrivate", "checkbox")} />{' '}
                                                    {getLocalizedText("isPrivate")}
                                                    <span className="error">{this.state.errors["Price"]}</span>
                                                </div>
                                                {
                                                    this.state.saleItem.isPrivate == true ?
                                                        <div className="form-group">
                                                            <label className="">{getLocalizedText("BuyerAddress")}&nbsp;<abbr className="required" title="required">*</abbr>
                                                            </label>
                                                            <Input type="text" name="privateBuyerAccountAddress" className='form-control'
                                                                value={this.state.saleItem.privateBuyerAccountAddress} onChange={(e) => this.handleInputChange(e, "privateBuyerAccountAddress")} />{' '}
                                                            <span className="error">{this.state.errors["privateBuyerAccountAddress"]}</span>
                                                        </div>
                                                        : null
                                                }
                                                <div className="form-group">
                                                    <label htmlFor="billing_last_name" className="">Description&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                    <Input type="textarea" className="form-control" rows="3" placeholder="Description" onChange={(e) => this.handleInputChange(e, "description")} />
                                                    <span className="error">{this.state.errors["description"]}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <div className="col-xl-4" >
                                        <div id="order_review" className="checkout-review-order" style={{ position: 'sticky', top: 100 }}>
                                            <h2>Summary</h2>
                                            <div className="table-responsive">
                                                <table className="shop_table checkout-review-order-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="product-name">Item</th>
                                                            <th className="product-total">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.ReadSellItems().map((item, index) => (
                                                            <tr className="cart_item">
                                                                <td >
                                                                    {item.name}&nbsp; <strong className="product-quantity">× {item.quantity}</strong> </td>
                                                                <td className="product-total">
                                                                    <span className="woocs_special_price_code"><span className="Price-amount amount">
                                                                        <span className="Price-currencySymbol">{this.state.selectedCoinOption != null ? this.state.selectedCoinOption.label : null} </span>
                                                                        {item.unitPrice} </span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                    <tfoot>
                                                        <tr className="cart-subtotal">
                                                            <th>Total</th>
                                                            <td><span className="woocs_special_price_code">
                                                                <span className="Price-amount amount">
                                                                    <span className="Price-currencySymbol">
                                                                    </span>
                                                                    {this.calculateTotalPrice()}{" "}
                                                                    {this.state.selectedCoinOption != null ? this.state.selectedCoinOption.label : null}
                                                                </span>
                                                            </span>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="form-row place-order">
                                                <button type="submit" className="button alt" name="checkout_place_order" id="place_order" value="Place order" onClick={() => this.onSell()} data-value="Place order">
                                                    Post Your Sell
                                        </button>
                                            </div>
                                            {/* <Link onClick={() => this.onSell()} className="checkout-button button " >
                                                Post Your Sell</Link> */}
                                        </div>
                                    </div>
                                </Row>
                                :
                                <div className="wishlist-not-found">
                                    <img src={require(`../../assets/images/empty-search.jpg`)} className="img-fluid mb-4" />
                                    <h4 className="d-block">Your Sell is currently empty.</h4>
                                    <a className="btn btn-primary" href="/admin-panel/Product">Return to Item List</a>
                                </div>
                            }
                        </Container>
                    </div>
                </div >
            </>
        )
    }
}
export default Sell;
