
/**
 * Admin List Product Display
 **/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import { Web3Provider } from '../component/Wallet/Web3Provider';


class AdminproductList extends Component {
    constructor(props) {
        super(props);
        this.toggle1 = this.toggle1.bind(this);
        this.AddToSell = this.AddToSell.bind(this);
        this.AddToWishList = this.AddToWishList.bind(this);


        this.state = {
            open: false,
            modal1: false,
            transferModal: false,
            stock: 'InStock',
            transferAddress: "",
            selectedTransferNFTId: 0,
            quantity: 1,
            image: ''
        }
    }

    AddToSell(NFTId, name, tokenId, creatorNftContract, previewImageSrc, quantity, unitPrice, stockStatus) {
        var SellList = JSON.parse(localStorage.getItem("LocalSellItems"));
        if (SellList == null)
            SellList = [];
        let selectedItem = SellList.find(item => item.NFTId === NFTId);
        if (selectedItem == null) {
            SellList.push(
                {
                    NFTId: NFTId,
                    name: name,
                    tokenId: tokenId,
                    previewImageSrc: previewImageSrc,
                    creatorNftContract: creatorNftContract,
                    quantity: quantity,
                    unitPrice: unitPrice,
                    stockStatus: stockStatus
                });
            localStorage.removeItem("LocalSellItems");
            localStorage.setItem("LocalSellItems", JSON.stringify(SellList));
            var flag = 0;
            if (flag == 0) {
                toast.success("Item Added to Sell ");
                flag = 1;
            }
        }
        else {
            toast.warning("Item is already in Sell");
        }
    }

    AddToWishList(ProductID, ProductName, ProductImage, Qty, Rate, StockStatus) {
        var Cart = JSON.parse(localStorage.getItem("LocalWishListItems"));
        if (Cart == null)
            Cart = [];

        let selectedProduct = Cart.find(product => product.ProductID === ProductID);
        if (selectedProduct == null) {

            Cart.push({ ProductID: ProductID, ProductName: ProductName, ProductImage: ProductImage, Qty: Qty, Rate: Rate, StockStatus: StockStatus });
            localStorage.removeItem("LocalWishListItems");
            localStorage.setItem("LocalWishListItems", JSON.stringify(Cart));

            toast.success("Item Added to WishList");
        }
        else {
            toast.warning("Item is already in WishList");
        }


    }

    CheckSellItem(ID) {
        let checkSell = false;
        var Sell = JSON.parse(localStorage.getItem("LocalSellItems"));
        if (Sell && Sell.length > 0) {
            for (const sellItem of Sell) {
                if (sellItem.NFTId === ID) {
                    checkSell = true
                }
            }
        }
        return checkSell;
    }


    toggle1() {
        this.setState(prevState => ({
            modal1: !prevState.modal1
        }));
    }

    onDeleteInvoicePopup() {
        this.toggle1();
    }

    transferModalOpenOrClose() {
        var prevStatetransferModal = this.state.transferModal;
        var selectedTransferNFTId = this.state.selectedTransferNFTId;
        if (prevStatetransferModal == true)
            selectedTransferNFTId = 0

        this.setState(prevState => ({
            transferModal: !prevState.transferModal,
            selectedTransferNFTId
        }));
    }

    handleInputChange(event, field, type) {
        var transferAddress = this.state.transferAddress;
        var newValue;
        newValue = event.target.value;
        transferAddress = newValue;

        this.setState({ transferAddress });
    }

    onTransfer(selectedTransferNFTId) {
        var transferAddress = this.state.transferAddress
        var transferRequest = { transferAddress, selectedTransferNFTId };
        console.log("transferRequest ==> " + JSON.stringify(transferRequest));
    }

    render() {
        const { nft, deleteproduct, mintNFT } = this.props;
        return (
            //TODO:video and audio thubnail, description
            <>
                <Web3Provider></Web3Provider>
                <Col key={1} sm={6} lg={3}>
                    <ToastContainer autoClose={1000} />
                    <div className="product product_tag-black product-hover-style-default product-hover-button-style-light product_title_type-single_line product_icon_type-line-icon">
                        <div className="product-inner element-hovered">
                            <div className="product-thumbnail">
                                <div className="product-thumbnail-inner">
                                    <Link to={`/token/${nft.creatorContractAddress}/${nft.tokenId}`}>
                                        {nft.defaultPreviewUrl != null ?
                                            <div className="product-thumbnail-main">
                                                <img src={`${nft.defaultPreviewUrl}`} className="img-fluid" />
                                            </div>
                                            :
                                            null
                                        }
                                    </Link>
                                </div>
                                <div className="product-actions">
                                    <div className="product-actions-inner">
                                        <div className="product-action product-action-add-to-cart">
                                            {
                                                nft.status == 2 ?

                                                    !this.CheckSellItem(nft.id) ?
                                                        <Link onClick={() => this.AddToSell(nft.id, nft.name, nft.tokenId, nft.creatorContractAddress, nft.defaultPreviewUrl, nft.quantity, nft.quantity, "In Stock")}
                                                            className="button add_to_cart_button" rel="nofollow">SELL</Link>
                                                        :
                                                        <Link to="/Sell" className="button add_to_cart_button" rel="nofollow">Go To Sell Page</Link>
                                                    :
                                                    <Link className="button add_to_cart_button" rel="nofollow">Mint NFT for Sell</Link>
                                            }
                                        </div>
                                        {/*  <div className="product-action product-action-wishlist">
                                                                {!this.CheckWishList(product.id) ?
                                            <Link onClick={() => this.AddToWishList(product.id, product.name, product.pictures[0], 1, product.salePrice, "In Stock")} className="add_to_wishlist" data-toggle="tooltip" data-original-title="Wishlist" data-placement="top"> Add to
                                    Wishlist</Link>
                                            :
                                            <Link to="/wishlist" className="add_to_wishlist_fill" data-toggle="tooltip" data-original-title="Wishlist" data-placement="top">View Wishlist</Link>
                                        } 
                                    </div>*/}
                                    </div>
                                </div>
                                <div className="product-action product-action-quick-view">
                                    <Link to={`/admin-panel/Edit/${nft.id}`} className="open-edit-view"><i class="fa fa-pencil-square-o"></i></Link>
                                    <Link to="#" className="product-delete" onClick={() => this.onDeleteInvoicePopup()} ><i class="fa fa-trash-o"></i></Link>
                                    <Link onClick={() => this.transferModalOpenOrClose()} className="open-edit-view" style={{ right: 100 }}><i class="fa fa-share-square-o"></i></Link>
                                    {nft.status == 1 ? //TODO: 1 = lazyMint add Constants
                                        <Link onClick={(res) => mintNFT(nft.id)} className="open-edit-view" style={{ right: 137 }}><i class="fa fa-paperclip"></i></Link>
                                        : null
                                    }
                                </div>
                                <div className="product-actions">
                                </div>
                            </div>
                            <div className="product-info">
                                {/*     {product.tags ?
                                <span className="ciyashop-product-category">
                                    {product.tags.map((tag, index) =>
                                        <span>{tag}{index === product.tags.length - 1 ? '' : ','}
                                        </span>

                                    )}
                                </span>
                                : null} */}
                                {nft.name ?
                                    <h3 className="product-name">
                                        <Link to="#">{nft.name}</Link>
                                    </h3>
                                    : null}
                                {/*   {nft.salePrice ?
                                <span className="price">
                                    <ins>
                                        <span className="price-amount amount">
                                            <span className="currency-symbol">$</span>{product.salePrice.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                                        </span>
                                    </ins>
                                </span>
                                : null} */}
                                {nft.description != null ?
                                    <div className="product-details__short-description" style={{ display: 'contents' }}>
                                        <p>{nft.description}
                                        </p>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>


                    {/* modal-delete */}
                    <Modal isOpen={this.state.modal1} toggle={this.toggle1} className="modal-delete modal-lg modal-dialog-centered">
                        <ModalHeader toggle={this.toggle1}  ></ModalHeader>
                        <ModalBody>
                            Are You Sure You Want To Delete This Product ?
                         </ModalBody>
                        <ModalFooter className="justify-content-center pt-4" >
                            <Link className="action-button" onClick={(res) => deleteproduct(this.toggle1())}>Yes</Link>
                            <Link className="action-button no" onClick={this.toggle1}>No</Link>
                        </ModalFooter>
                    </Modal>

                    {/* modal-transfer */}
                    <Modal isOpen={this.state.transferModal} toggle={() => this.transferModalOpenOrClose()} className="modal-delete modal-lg modal-dialog-centered">
                        <ModalHeader toggle={() => this.transferModalOpenOrClose()}  ></ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label htmlFor="billing_last_name" className="">Transfer Address&nbsp;<abbr className="required" title="required">*</abbr></label>
                                <Input type="text" className="form-control" rows="3" placeholder="Transfer Address" onChange={(e) => this.handleInputChange(e, "transferAddress")} />
                                {/*  <span className="error">{this.state.errors["transferAddress"]}</span> */}
                            </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-center pt-4" >
                            <Link className="action-button" onClick={() => this.onTransfer(nft.id)}>Transfer</Link>
                            <Link className="action-button no" onClick={() => this.transferModalOpenOrClose()}>Close</Link>
                        </ModalFooter>
                    </Modal>
                </Col>
            </>
        )
    }

}

export default AdminproductList;
