import axios, { AxiosInstance } from 'axios'
import { BasicWalletInfo, IAssetData, ICollection, ICollectionCreateDTO, IGasPrices, INft, INftLazyMintDTO, INftMintDTO, IParsedTx, MessageSignRequest, ResponseWrapper, SessionContext, RequestWrapper } from './types'
import { AdminURL, ApiURL } from '../constants'
import { cacheGetAccessToken, cacheGetDefaultAccessToken } from './cacheManager';

const ethereumAPI: AxiosInstance = axios.create({
  baseURL: 'https://ethereum-api.xyz',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Accept': 'application/json',
  },
  timeout: 30000,
});

const adminAPI = axios.create({
  baseURL: AdminURL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Accept': 'application/json',
  },
  timeout: 40000,
  withCredentials: true
});

const marketplaceAPI = axios.create({
  baseURL: ApiURL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Accept': 'application/json',
  },
  timeout: 40000,
  withCredentials: true
});

export async function apiGetNonce(request: BasicWalletInfo) {
  return marketplaceAPI.post<ResponseWrapper<string>>(
    `/Auth/GetNonce`,
    request
  );
}

export async function apiSignInViaNonce(request: MessageSignRequest) {
  return marketplaceAPI.post<ResponseWrapper<SessionContext>>(
    "/Auth/SignInViaNonce",
    request
  );
}

export async function apiCreateCollection(request: ICollectionCreateDTO) {
  return marketplaceAPI.post<ResponseWrapper<ICollection>>(
    "/Collection/Create",
    request
  );
}

export async function apiGetCollection(request: RequestWrapper) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/Collection/List",
    request
  );
}

export async function apiGetCategory(request: RequestWrapper) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/Category/List",
    request
  );
}

export async function apiGetCoin(request: RequestWrapper) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/Coin/List",
    request
  );
}


export async function apiGetTradingHistoryFilter(request: RequestWrapper) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/TradingHistory/ActivityFilter",
    request
  );
}
export async function apiGetTradingHistory(request: RequestWrapper) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/TradingHistory/List",
    request
  );
}

export async function apiGetEventType(request: RequestWrapper) {
  return adminAPI.post<ResponseWrapper<any>>(
    "/Parameter/List",
    request
  );
}

export async function apiGetChain(request: RequestWrapper) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/Chain/List",
    request
  );
}

export async function apiGetAuctionBidList(request: RequestWrapper) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/AuctionBid/List",
    request
  );
}

export async function  apiGetAuctionBidFilter(request: RequestWrapper) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/AuctionBid/RankingsFilter",
    request
  );
}

export async function apiGetMyNftList(request: RequestWrapper) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/Nft/MyNftList",
    request
  );
}

export async function apiGetNftDetail(request: any) {
  return marketplaceAPI.post<any>(
    "/Nft/GetNFTDetail",
    request
  );
}

export async function apiGetNft(request: any) {
  return marketplaceAPI.post<any>(
    "/Nft/GetNFT",
    request
  );
}

export async function apiGetNftList(request: any) {
  return marketplaceAPI.post<any>(
    "/Nft/List",
    request
  );
}

export async function apiGetSaleNFTs(request: any) {
  return marketplaceAPI.post<any>(
    "/Nft/GetSaleNFTs",
    request
  );
}

export async function apiPostFile(request: any) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/Document/Insert",
    request
  );
}

export async function apiPostTraits(request: any) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/Trait/InsertList",
    request
  );
}

export async function apiPostSetSale(request: any) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/SaleAndAuction/SetSale",
    request
  );
}

export async function apiPostUpdaateSetSale(request: any) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/SaleAndAuction/UpdateSetSale",
    request
  );
}

export async function apiPostPurchaseNFT(request: any) {
  return marketplaceAPI.post<ResponseWrapper<any>>(
    "/SaleAndAuction/PurchaseNFT",
    request
  );
}

export async function apiGetTokenId() {
  return marketplaceAPI.post<string>(
    "/Nft/GetTokenId",
  );
}

export async function apiNftLazyMint(request: INftLazyMintDTO) {
  return marketplaceAPI.post<ResponseWrapper<INft>>(
    "/Nft/LazyMint",
    request
  );
}

export async function apiNftMint(request: INftMintDTO) {
  return marketplaceAPI.post<ResponseWrapper<INft>>(
    "/Nft/Mint",
    request
  );
}

export async function apiGetAccountAssets(
  address: string,
  chainId: number
): Promise<IAssetData[]> {
  const response = await ethereumAPI.get(
    `/account-assets?address=${address}&chainId=${chainId}`
  )
  const { result } = response.data
  return result
}

export async function apiGetAccountTransactions(
  address: string,
  chainId: number
): Promise<IParsedTx[]> {
  const response = await ethereumAPI.get(
    `/account-transactions?address=${address}&chainId=${chainId}`
  )
  const { result } = response.data
  return result
}

export const apiGetAccountNonce = async (
  address: string,
  chainId: number
): Promise<string> => {
  const response = await ethereumAPI.get(
    `/account-nonce?address=${address}&chainId=${chainId}`
  )
  const { result } = response.data
  return result
}

export const apiGetGasPrices = async (): Promise<IGasPrices> => {
  const response = await ethereumAPI.get(`/gas-prices`)
  const { result } = response.data
  return result
}

marketplaceAPI.interceptors.request.use((config) => {
  var tokenStr = cacheGetDefaultAccessToken();
  if (tokenStr != null)
    config.headers['Authorization'] = 'Bearer ' + tokenStr;

  return config;
}, function (error) {
  console.error(error)
  return Promise.reject(error);
});