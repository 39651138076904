import * as React from "react";
import { Button } from "reactstrap";
import { Web3Context } from '../Web3Provider';
import { Link } from 'react-router-dom';
import LoadingPlaceholder from "../../loadingPlaceholder";
import WalletAddress from "../WalletAddress";
import { getProviderInfo } from "web3modal";
import WalletSignIn from "../widgets/WalletSignIn";

export default class WalletNavBarItem extends React.Component<any, any> {

  static contextType = Web3Context;
  context!: React.ContextType<typeof Web3Context>

  public render = () => {
    const { provider, assets, address, isConnected, isAuthenticated, chainId, isFetching, events } = this.context;

    const providerInfo = getProviderInfo(provider);
    return (
      <div>
        {isConnected && isAuthenticated
          ? <>
            {/* <Button onClick={events.resetApp} className="bg-white border px-2 py-1 text-dark"> */}
            <Button className="bg-white border px-2 py-1 text-dark">
              <img height="25" src={providerInfo.logo} alt={providerInfo.name} className="mt-2 d-inline-flex"/>
              <WalletAddress address={address} isOwner={true} />
            </Button>
          </>
          : 
          <WalletSignIn />
          // ( 
          //   <>
          //   <Link to="/WalletConnect" className="btn-white btn btn-primary">
          //       <i className="fa fa-wallet" style={{ marginRight: 4 }} />
          //       {'Connect Wallet'}
          //     </Link>
          //     </>
          // )
        }
      </div>
    );
  };
}