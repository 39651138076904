/* Order  Json */
export default [
    {
        "id": 1,
        "orderid": "#6215",
        "productname": "La-ta-da Boho Chic Head Wrap",
        "qty": "1",
        "date": "Oct 09, 2019",
        "status": "Sent",
        "payment": "HSBC Bank",
        "price": 5235,
        "debit": "$2,235.00",
        "tax": 72,
        "action": "remove_red_eye"
    },
    {
        "id": 2,
        "orderid": "#9215",
        "productname": "Women’s Cozy Leisure Top",
        "qty": "2",
        "date": "Jan 24, 2019",
        "status": "Sent",
        "payment": "HSBC Bank",
        "price": 10235,
        "debit": "$5,235.00",
        "tax": 52,
        "action": "remove_red_eye"
    },
    {
        "id": 3,
        "orderid": "#7848",
        "productname": "WD·NY Black – Men’s Pinstripe Seer Sucker Vest",
        "qty": "2",
        "date": "Dec 2, 2019",
        "status": "Sent",
        "payment": "HSBC Bank",
        "price": 15235,
        "debit": "$4,985.00",
        "tax": 40.00,
        "action": "remove_red_eye"
    },
    {
        "id": 4,
        "orderid": "#2515",
        "productname": "Women’s Accessories Vegan Leather Mini Backpack",
        "qty": "1",
        "date": "Oct 10, 2019",
        "status": "Sent",
        "payment": "HSBC Bank",
        "price": 25235,
        "debit": "$5,878.00",
        "tax": 60,
        "action": "remove_red_eye"
    },
    {
        "id": 5,
        "orderid": "#9154",
        "productname": "Girls’ Shine Stripe Long Sleeve Ruffle Top",
        "qty": "1",
        "date": "Feb 24, 2019",
        "status": "Sent",
        "payment": "HSBC Bank",
        "price": 35235,
        "debit": "$2,225.00",
        "tax": 72,
        "action": "remove_red_eye"
    },
    {
        "id": 6,
        "orderid": "#4214",
        "productname": "Men’s Standard Fit Short Sleeve Crew T-Shirt",
        "qty": "2",
        "date": "Jan 14, 2019",
        "status": "Sent",
        "payment": "HSBC Bank",
        "price": 15235,
        "debit": "$6,205.00",
        "tax": 80,
        "action": "remove_red_eye"
    }
]
