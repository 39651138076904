
/**
 * ProductDetailOwner Widget
 */
import React, { Component } from 'react';
import adminprofile from '../assets/images/testimonials/img-02.jpg';
import ProfileImage from '../widgets/ProfileImage';

class ProductDetailOwners extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const { owners } = this.props;
        return (
            <>
                {owners != null ?
                    owners.map((owner) => {
                        return (
                            <div className="pb-4">

                                <ProfileImage src={adminprofile} width={45} height={45} /* isChecked={Productedit.owner.isChecked == 'true'} */>
                                    <div className="admin-profile-info font-weight-bold float-left ml-2 mt-2 pt-1">
                                        {owner.account != null ? owner.account.user.nickname != null ? owner.account.user.nickname : "" : ""} <span className="hebys1">||</span><i className="hebys1 fas fa-toolbox"></i> Quantity: {owner.quantity}
                                    </div>
                                </ProfileImage>
                            </div>

                        )
                    })
                    : null}
            </>
        )
    }
}

export default ProductDetailOwners;
