import * as React from "react";
import _ from "lodash";
import { Modal, ModalBody } from "reactstrap";

import Web3 from "web3";
import { Contract, ContractOptions } from "web3-eth-contract";
import Web3Modal, { getProviderInfo } from "web3modal";
// @ts-ignore
import * as ethUtil from "ethereumjs-util";

//#region Connectors
import WalletConnectProvider from "@walletconnect/web3-provider";
import { convertUtf8ToHex } from "@walletconnect/utils";
/* import Fortmatic from "fortmatic";
import Torus from "@toruslabs/torus-embed";
import Authereum from "authereum";
import { Bitski } from "bitski"; */
//#endregion

import { apiCreateCollection, apiGetAccountAssets, apiGetNonce, apiNftLazyMint, apiNftMint, apiSignInViaNonce, apiGetNft } from "../../common/helpers/api";
import { pinJSON } from "../../common/helpers/ipfsProvider";
import { BasicWalletInfo, IAssetData, IBoxProfile, INft, INftLazyMintDTO, IPinNFT, IPinTraits, MessageSignRequest } from "../../common/helpers/types";
import { openBox, getProfile } from "../../common/helpers/box";

import {
  hashPersonalMessage,
  formatTestTransaction,
  getChainData
} from "../../common/helpers/utilities";

import constants, {
  ETH_SEND_TRANSACTION,
  BOX_GET_PROFILE,
  SUPPORTED_CHAIN_IDS,
  SUPPORTED_NETWORKS_IDS,
  ErrorCode,
  ERC1155_CONTRACT_ADDRESS,
  ERC721_CONTRACT_ADDRESS,
  TokenProtocol,
  ContractPresets,
  SELL_CONTRACT_ADDRESS
} from "../../common/constants";
import ModalPresets from "../../common/modalPresets";
import GenericModal from "../GenericModal";
import { cacheSetAccountData, isAccountAuthenticated, cacheClearAccountData, cacheSetSelectedAddress } from "../../common/helpers/cacheManager";
import ERC1155Contract from "../../contracts/services/ERC1155Contract";
import AuctionContract from "../../contracts/services/AuctionContract";
import ERC721Contract from "../../contracts/services/ERC721Contract";
import SellContract from "../../contracts/services/SellContract";
import { getLocalizedText } from "../../common/helpers/localizationManager";

interface IContractHandler {
  getAuctionContract: () => Promise<AuctionContract>;
  getERC721Contract: () => Promise<ERC721Contract>;
  getERC1155Contract: () => Promise<ERC1155Contract>;
  getSellContract: () => Promise<SellContract>;
}

interface IWeb3State {
  isFetching: boolean;
  address: string;
  web3: Web3;
  provider: any;
  contractHandler: IContractHandler;
  isConnecting: boolean;
  isConnected: boolean;
  isAuthenticated: boolean;
  chainId: number;
  networkId: number;
  assets: IAssetData[];
  hasPendingRequest: boolean;
  showModal: boolean;
  showGenericModal: boolean;
}

interface IState extends IWeb3State {
  modalContent: any;
  // result: any;
  errorMessage: any;
}

interface IWeb3Context extends IWeb3State {
  events: {
    onConnect: () => void;
    toggleModal: () => void;
    resetApp: () => void;
    sendTransaction: (tx: any) => Promise<string>;
    testOpenBox: () => void;
    testSignMessage: () => void;
    testSendTransaction: () => void;
    testSignPersonalMessage: (message: string) => void;
    // TODO Temp
    testCreateNFT: () => void;
    mintNFT: (nftId: number) => void;
    balanceOf: (tokenId: string) => void;
    getRoyalty: (tokenId: string) => void;
  }
}

const INITIAL_STATE: IState = {
  isFetching: false,
  address: "",
  // @ts-ignore
  web3: null,
  provider: null,
  // @ts-ignore
  contractHandler: null,
  isConnecting: false,
  isConnected: false,
  isAuthenticated: false,
  chainId: 1,
  networkId: 1,
  assets: [],
  showModal: false,
  showGenericModal: false,
  hasPendingRequest: false,
  // result: null,
  modalContent: null
};

function initializeWeb3(provider: any) {
  return new Web3(provider);
}

const Web3Context = React.createContext<IWeb3Context>(INITIAL_STATE as any);

class Web3Provider extends React.Component<any, IState> {
  public web3Modal: Web3Modal;

  constructor(props: any) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };

    this.web3Modal = new Web3Modal({
      network: this.getNetwork(),
      cacheProvider: true,
      providerOptions: this.getProviderOptions()
    });
  }

  public componentDidMount() {
    if (this.web3Modal.cachedProvider)
      this.onConnect();
  }

  public componentWillUnmount() {
    this.unsubscribeProviderEvents();
  }

  public onConnect = async () => {
    let web3, provider;
    try {
      provider = await this.web3Modal.connect();

      this.setState({ isConnecting: true, showModal: true });

      web3 = initializeWeb3(provider);

      await this.subscribeProviderEvents(provider);

      this.setState({ web3, provider }, this.updateWalletData);
    } catch (error) {
      this.handleWeb3Exception(error, web3, provider);
    }
  };

  public balanceOf = async (tokenId: string) => {
    try {

      const { address, contractHandler } = this.state;

      // Creates ERC1155 contract instance
      var contract = await contractHandler.getERC1155Contract();
      // Force user to approve our address before setting an nft on sale.
      // Retrieve current balance(quantity) of user for specified tokenId

      /* var signedTokenId = await this.signPersonalMessage("85592847012508915598698695152465576977"); */
      // Gets elliptic curve algorithm parameters of signature to use on mint function on contract for authorization purposes.
      /*  var rsv = ethUtil.fromRpcSig(signedTokenId);
       console.log("🚀 ~ file: Web3Provider.tsx ~ line 178 ~ Web3Provider ~ balanceOf= ~ rsv", rsv) */

      var sellContractAdress = "0x1f1d83FBB896EDE8569E9461D00055D0be434003"
      var isOwnerApproved = await contract.callIsApprovedForAllAsync(address, sellContractAdress);
      console.log("🚀 ~ Web3Provider ~ testCreateNFT= ~ isOwnerApproved", isOwnerApproved);
      /*   var test = await contract.safeTransferFrom(address, "0xdBcF0D412F72427164E48CB5a140Cb2d691CB40e", tokenId, 1);
        console.log("🚀 ~ file: Web3Provider.tsx ~ line 176 ~ Web3Provider ~ balanceOf= ~ test", test)
         *///var txId = await test.getTxId();

      if (!isOwnerApproved) {
        this.setState({ hasPendingRequest: true });

        await contract.sendSetApprovalForAllAsync(sellContractAdress, true);

        this.setState({ hasPendingRequest: false });
      }
      var contractOwnerAddress = "0x9717A356e8E993e2A696966cDac66B6875E9F446";

      /*  var test = await contract.safeTransferFrom(address, "0xdBcF0D412F72427164E48CB5a140Cb2d691CB40e", tokenId, 1);
       console.log("🚀 ~ file: Web3Provider.tsx ~ line 176 ~ Web3Provider ~ balanceOf= ~ test", test)
        */
      var balanceOf = await contract.callBalanceOfAsync(address, tokenId);

      console.log("🚀 ~ file: Web3Provider.tsx ~ line 174 ~ Web3Provider ~ balanceOf= ~ balanceOf", balanceOf)

    } catch (error) {
      console.error(error);
    }
    finally {
      this.setState({ hasPendingRequest: false });
    }
  }

  public getRoyalty = async (tokenId: string) => {
    try {

      const { address, contractHandler } = this.state;
      this.setState({ hasPendingRequest: true });
      // Creates ERC1155 contract instance
      var contract = await contractHandler.getERC1155Contract();

      var royaltyRate = await contract.callGetRoyaltyRateAsync(tokenId);
      console.log("🚀 ~ file: Web3Provider.tsx ~ line 220 ~ Web3Provider ~ getRoyalty= ~ royaltyRate", royaltyRate)

      var royaltyAddress = await contract.callGetRoyaltyAddressAsync(tokenId);
      console.log("🚀 ~ file: Web3Provider.tsx ~ line 223 ~ Web3Provider ~ getRoyalty= ~ royaltyAddress", royaltyAddress)

    } catch (error) {
      console.error(error);
    }
    finally {
      this.setState({ hasPendingRequest: false });
    }
  }

  public testCreateNFT = async () => {
    try {

      const { address, contractHandler } = this.state;

      // Creates ERC1155 contract instance
      var contract = await contractHandler.getERC1155Contract();

      // Contract owner address required to give access of tokens created for transfer/sell rights.
      // TODO Retrieve address from contract.
      var contractOwnerAddress = "0x2174b449c61F1Aa9d1Ac2CB6Dcd64a493e1F3f9C";

      // Does user approved our address to make change of their tokens.
      var isOwnerApproved = await contract.callIsApprovedForAllAsync(address, contractOwnerAddress);
      console.log("🚀 ~ Web3Provider ~ testCreateNFT= ~ isOwnerApproved", isOwnerApproved);

      // Force user to approve our address before setting an nft on sale.
      if (!isOwnerApproved) {
        this.setState({ hasPendingRequest: true });

        await contract.sendSetApprovalForAllAsync(contractOwnerAddress, true);

        this.setState({ hasPendingRequest: false });
      }

      // Create a random collection record.
      var collection = (await apiCreateCollection({
        name: "Collection_" + Math.random() * 1000,
        description: "My Collection"
      })).data.data;

      // Create a random nft related to collection above.
      var nftLazyMint: INftLazyMintDTO = {
        collectionId: collection.id,
        name: "NFT_" + Math.random() * 1000,
        description: "description",
        quantity: Math.round(Math.random() * 5),
        isPhysical: false,
        royaltyRate: Math.round(Math.random() * 5),
        isPublic: true,
        tokenProtocol: TokenProtocol.ERC1155
      };

      // Insert a random NFT (off-chain).
      var nft = (await apiNftLazyMint(nftLazyMint)).data.data;

      // Sign returned tokenId.
      var signedTokenId = await this.signPersonalMessage(nft.tokenId);
      // Gets elliptic curve algorithm parameters of signature to use on mint function on contract for authorization purposes.
      var rsv = ethUtil.fromRpcSig(signedTokenId);

      this.setState({ hasPendingRequest: true });

      // Send transaction for mint function
      var mintTransaction = contract.sendMintAsync(address, nft.tokenId, nft.quantity, nft.royaltyRate, rsv.r, rsv.s, rsv.v);
      // Awaits only txId to return not transaction receipt.
      // To get full receipt send request should be awaited only not with getTxId.
      var txId = await mintTransaction.getTxId();

      // Send signed tokenId and txId to update current record.
      nft = (await apiNftMint({
        ...nftLazyMint,
        id: nft.id,
        signedTokenId,
        txId,
        onSale: false
      })).data.data;

      console.log("🚀 ~ Web3Provider ~ testCreateNFT= ~ nft", nft);

      // Make sure mint transaction is awaited before checking for balance.
      await mintTransaction;

      // Retrieve current balance(quantity) of user for specified tokenId
      var balanceOf = await contract.callBalanceOfAsync(address, nft.tokenId);

      console.log("🚀 ~ Web3Provider ~ testCreateNFT= ~ balanceOf", balanceOf);

    } catch (error) {
      console.error(error);
    }
    finally {
      this.setState({ hasPendingRequest: false });
    }
  }

  public mintNFT = async (nftId: number) => {
    try {
      console.log("🚀 ~ file: Web3Provider.tsx ~ line 248 ~ Web3Provider ~ mintNFT= ~ nftId", nftId)

      const { address, contractHandler } = this.state;

      // Creates ERC1155 contract instance
      var contract = await contractHandler.getERC1155Contract();

      // Contract owner address required to give access of tokens created for transfer/sell rights.
      // TODO Retrieve address from contract.
      var contractOwnerAddress = "0x2174b449c61F1Aa9d1Ac2CB6Dcd64a493e1F3f9C";

      // Does user approved our address to make change of their tokens.
      var isOwnerApproved = await contract.callIsApprovedForAllAsync(address, contractOwnerAddress);
      console.log("🚀 ~ Web3Provider ~ testCreateNFT= ~ isOwnerApproved", isOwnerApproved);

      // Force user to approve our address before setting an nft on sale.
      if (!isOwnerApproved) {
        this.setState({ hasPendingRequest: true });

        await contract.sendSetApprovalForAllAsync(contractOwnerAddress, true);

        this.setState({ hasPendingRequest: false });
      }

      var nftRequest = {
        id: nftId
      };

      var nft = (await apiGetNft(nftRequest)).data;
      // Sign returned tokenId.
      var signedTokenId = await this.signPersonalMessage(nft.tokenId);
      // Gets elliptic curve algorithm parameters of signature to use on mint function on contract for authorization purposes.
      var rsv = ethUtil.fromRpcSig(signedTokenId);

      this.setState({ hasPendingRequest: true });

      // Send transaction for mint function
      var mintTransaction = contract.sendMintAsync(address, nft.tokenId, nft.quantity, nft.royaltyRate, rsv.r, rsv.s, rsv.v);
      // Awaits only txId to return not transaction receipt.
      // To get full receipt send request should be awaited only not with getTxId.
      var txId = await mintTransaction.getTxId();
      // To get full receipt send request should be awaited only not with getTxId.
      console.log("🚀 ~ file: Web3Provider.tsx ~ line 366 ~ Web3Provider ~ mintNFT= ~ txId", txId)

      // Send signed tokenId and txId to update current record.
      nft = (await apiNftMint({
        ...nft,
        id: nft.id,
        signedTokenId,
        txId,
        onSale: false,
      })).data.data;

      console.log("🚀 ~ Web3Provider ~ testCreateNFT= ~ nft", nft);

      // Make sure mint transaction is awaited before checking for balance.
      // await mintTransaction;
      this.setState({ hasPendingRequest: false });
      // Retrieve current balance(quantity) of user for specified tokenId
      var balanceOf = await contract.callBalanceOfAsync(address, nft.tokenId);

      console.log("🚀 ~ Web3Provider ~ testCreateNFT= ~ balanceOf", balanceOf);

    } catch (error) {
      console.error(error);
    }
    finally {
      this.setState({ hasPendingRequest: false });
    }
  }

  initializeContracts = async () => {
    var contractHandler = {
      getAuctionContract: async () => {
        return new AuctionContract(await this.initializeContract(true, ContractPresets.Auction));
      },
      getERC721Contract: async () => {
        return new ERC721Contract(await this.initializeContract(true, ContractPresets.ERC721));
      },
      getERC1155Contract: async () => {
        return new ERC1155Contract(await this.initializeContract(true, ContractPresets.ERC1155));
      },
      getSellContract: async () => {
        return new SellContract(await this.initializeContract(true, ContractPresets.Sell));
      }
    };

    this.setState({ contractHandler });
  }

  initializeContract = async (existingContract: boolean, contractPresets: ContractPresets) => {

    // Import generated abi and bin data from json to be used as contract interface and validator.
    var contractSource;
    switch (contractPresets) {
      case ContractPresets.ERC721:
        contractSource = await import('../../contracts/bin/ERC721Contract.json')
        break;
      case ContractPresets.ERC1155:
        contractSource = await import('../../contracts/bin/ERC1155Contract.json')
        break;
      case ContractPresets.Auction:
        contractSource = await import('../../contracts/bin/AuctionContract.json')
        break;
      case ContractPresets.Sell:
        contractSource = await import('../../contracts/bin/SellContract.json')
        break;
      default:
        throw new Error("Not supported contract preset !");
    }

    const { web3, address } = this.state;

    var abiJson: any = contractSource.abi;
    var contractOptions: ContractOptions = { from: address };
    var contract: Contract;

    if (existingContract)
      contract = new web3.eth.Contract(
        abiJson
        , contractPresets == ContractPresets.ERC721
          ? ERC721_CONTRACT_ADDRESS
          : contractPresets == ContractPresets.ERC1155 ? ERC1155_CONTRACT_ADDRESS
            : SELL_CONTRACT_ADDRESS
        , contractOptions);
    else
      throw new Error("Not supported yet !");
    // contract = await new web3.eth.Contract(
    //   abiJson
    //   , undefined
    //   , contractOptions
    // )
    //   .deploy({
    //     data: contractSource.bytecode,
    //     arguments: ["test_name", "test_symbol", "http://localhost:8000/tokens/"]
    //   })
    //   .send({ from: address/* , gas: 8000000 */ });

    return contract;
  }

  onConnected = async () => {
    const { address } = this.state;

    if (isAccountAuthenticated(address)) {
      console.log("Account is already authenticated.");
      cacheSetSelectedAddress(address);
      this.setState({ showModal: false, isAuthenticated: true });
      return;
    }

    const { chainId } = this.state;

    var nonceRequest: BasicWalletInfo = {
      address,
      chainId
    };

    apiGetNonce(nonceRequest)
      .then(async response => {
        var hexMessage = `${getLocalizedText("WALLET_SIGN_LOGIN_MESSAGE")} nonce:${response.data.data}`;
        var signature = await this.signPersonalMessage(hexMessage);

        if (!signature)
          throw new Error("Signing message rejected by user !");

        const { provider } = this.state;

        console.log("🚀 ~ file: Web3Provider.tsx ~ line 173 ~ Web3Provider ~ onConnected= ~ provider", provider)

        var providerInfo = getProviderInfo(provider);
        var walletProviderId = providerInfo.name;

        var signinRequest: MessageSignRequest = {
          ...nonceRequest,
          signature,
          walletProviderId,
          hexMessage
        }

        apiSignInViaNonce(signinRequest)
          .then(response => {
            var session = response.data.data;

            cacheSetAccountData(session, nonceRequest);

            this.setState({ showModal: false, isAuthenticated: true });
          })
          .catch(this.handleWeb3Exception);
      })
      .catch(this.handleWeb3Exception);
  }

  handleWeb3Exception = async (error: Error | string, web3?: Web3, provider?: any) => {
    this.showErrorPopup(error);
    await this.onDisconnect(web3, provider);
  };

  showErrorPopup = (error: Error | string, defaultMessage?: string) => {
    var errorCode = _.get(error, 'message', defaultMessage);
    var modalContent;

    switch (errorCode) {
      case undefined:
        return;
      case ErrorCode.UNSUPPORTED_CHAIN:
      case ErrorCode.UNSUPPORTED_NETWORK:
        modalContent = { ...ModalPresets.NetworkError }
        break;
      default:
        modalContent = { ...ModalPresets.EmptyError };
        modalContent.body = errorCode;
        break;
    }

    this.setState({ modalContent, showGenericModal: true });
  }

  public subscribeProviderEvents = async (provider: any) => {
    if (!provider || !provider.on)
      return;

    // provider.on("close", this.onDisconnect);
    provider.on("accountsChanged", this.onAccountChanged);
    provider.on("chainChanged", this.onChainChanged);
    provider.on("disconnect", this.onDisconnect);
    // TODO Deprecated
    // provider.on("networkChanged", this.onNetworkChanged);
  };

  public unsubscribeProviderEvents = async (provider?: any) => {
    provider = provider || this.state.provider;

    if (!provider || !provider.removeAllListeners)
      return;

    provider.removeAllListeners();
  };

  /* onNetworkChanged = async (networkId: number) => {
    await this.updateWalletData(networkId);
  } */

  onChainChanged = async (chainId: string | number) => {
    await this.updateWalletData(null, chainId);
  }

  onAccountChanged = async (accounts: string[]) => {
    await this.updateWalletData(null, null, accounts);
  }

  updateWalletData = async (networkId?: number | null, chainId?: string | number | null, accounts?: string[] | null) => {
    try {
      const { web3, provider } = this.state;

      if (!web3 || !provider || !provider.isConnected())
        return;

      accounts = accounts || await web3.eth.getAccounts();
      let address = accounts[0];

      if (_.isEmpty(address))
        throw new Error(ErrorCode.CONNECTED_ACCOUNT_NOT_EXISTS)

      address = web3.utils.toChecksumAddress(address);

      networkId = networkId || await web3.eth.net.getId();

      if (!SUPPORTED_NETWORKS_IDS.includes(networkId))
        throw new Error(ErrorCode.UNSUPPORTED_NETWORK);

      chainId = chainId || await web3.eth.getChainId();
      chainId = web3.utils.hexToNumber(chainId);

      if (!SUPPORTED_CHAIN_IDS.includes(chainId))
        throw new Error(ErrorCode.UNSUPPORTED_CHAIN);

      const isChanged = this.state.chainId != chainId
        || this.state.networkId != networkId
        || this.state.address != address
        || !this.state.isConnected;

      this.setState({ chainId, networkId, address, isConnected: true, isConnecting: false },
        isChanged ?
          () => {
            this.getAccountAssets();
            this.initializeContracts();
            this.onConnected();
          } : undefined);
    } catch (error) {
      this.handleWeb3Exception(error);
    }
  }

  public getNetwork = () => getChainData(this.state.chainId).network;

  public getProviderOptions = () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: process.env.REACT_APP_INFURA_ID
        }
      },
      /* torus: {
        package: Torus
      },
      fortmatic: {
        package: Fortmatic,
        options: {
          key: process.env.REACT_APP_FORTMATIC_KEY
        }
      },
      authereum: {
        package: Authereum
      },
      bitski: {
        package: Bitski,
        options: {
          clientId: process.env.REACT_APP_BITSKI_CLIENT_ID,
          callbackUrl: window.location.href + "bitski-callback.html"
        }
      } */
    };
    return providerOptions;
  };

  public getAccountAssets = async () => {
    const { web3, address, chainId } = this.state;
    this.setState({ isFetching: true });
    try {
      // get account balances
      const assets = await apiGetAccountAssets(address, chainId);

      this.setState({ assets, isFetching: false });
    } catch (error) {
      console.error(error);
      this.setState({ isFetching: false });
    }
  };

  public sendTransaction = async (tx: any) => {
    const { web3 } = this.state;

    return new Promise<string>((resolve, reject) => {
      web3.eth
        .sendTransaction(tx)
        .once("transactionHash", (txHash: string) => resolve(txHash))
        .catch((err: any) => reject(err));
    });
  }

  public toggleModal = () => this.setState({ showModal: !this.state.showModal });
  public toggleGenericModal = () => this.setState({ showGenericModal: !this.state.showGenericModal });

  public testSendTransaction = async () => {
    const { web3, address, chainId } = this.state;

    if (!web3) {
      return;
    }

    const tx = await formatTestTransaction(address, chainId);

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ hasPendingRequest: true });

      // send transaction
      const result = await this.sendTransaction(tx);

      // format displayed result
      const formattedResult = {
        action: ETH_SEND_TRANSACTION,
        txHash: result,
        from: address,
        to: address,
        value: "0 ETH"
      };

      console.log(formattedResult);

      // display result

      this.setState({
        web3,
        hasPendingRequest: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({ web3, hasPendingRequest: false });
    }
  };

  public testSignMessage = async () => {
    const { web3, address, showModal } = this.state;

    if (!web3) {
      return;
    }

    // test message
    const message = "My email is john@doe.com - 1537836206101";

    // hash message
    const hash = hashPersonalMessage(message);

    try {
      // open modal & toggle pending request indicator
      this.setState({ hasPendingRequest: true, showModal: true });

      // send message
      const result = await web3.eth.sign(hash, address);

      // // verify signature
      // const signer = recoverPublicKey(result, hash);
      // const verified = signer.toLowerCase() === address.toLowerCase();

      // // format displayed result
      // const formattedResult = {
      //   action: ETH_SIGN,
      //   address,
      //   signer,
      //   verified,
      //   result
      // };

      // console.log(formattedResult);

      // display result
      this.setState({
        web3,
        showModal,
        hasPendingRequest: false,
      });
    } catch (error) {
      this.setState({ showModal, hasPendingRequest: false }, () => this.showErrorPopup(error));
    }
  };

  public signPersonalMessage = async (message: string) => {
    const { web3, address, showModal } = this.state;

    try {
      if (!web3)
        throw new Error("Web3 is not instantiated !");

      // encode message (hex)
      const hexMsg = convertUtf8ToHex(message);
      // open modal & toggle pending request indicator
      this.setState({ hasPendingRequest: true, showModal: true });

      // send message
      const result = await web3.eth.personal.sign(hexMsg, address, ""); // TODO password

      // // verify signature
      // const signer = recoverPersonalSignature(result, message);
      // const verified = signer.toLowerCase() === address.toLowerCase();

      // // format displayed result
      // const formattedResult = {
      //   action: PERSONAL_SIGN,
      //   address,
      //   signer,
      //   verified,
      //   result
      // };

      // console.log(formattedResult);

      // display result
      this.setState({
        web3,
        showModal,
        hasPendingRequest: false,
      });

      return result;
    } catch (error) {
      this.setState({ showModal, hasPendingRequest: false }, () => this.showErrorPopup(error));
      throw error;
    }
  };

  public testOpenBox = async () => {
    function getBoxProfile(
      address: string,
      provider: any
    ): Promise<IBoxProfile> {
      return new Promise(async (resolve, reject) => {
        try {
          await openBox(address, provider, async () => {
            const profile = await getProfile(address);
            resolve(profile);
          });
        } catch (error) {
          reject(error);
        }
      });
    }

    const { address, provider, showModal } = this.state;

    try {
      // open modal & toggle pending request indicator
      this.setState({ hasPendingRequest: true, showModal: true });

      // send transaction
      const profile = await getBoxProfile(address, provider);

      let result;
      if (profile) {
        result = {
          name: profile.name,
          description: profile.description,
          job: profile.job,
          employer: profile.employer,
          location: profile.location,
          website: profile.website,
          github: profile.github
        };
      }

      // format displayed result
      const formattedResult = {
        action: BOX_GET_PROFILE,
        result
      };

      console.log(formattedResult);

      // display result
      this.setState({
        showModal,
        hasPendingRequest: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({ showModal, hasPendingRequest: false }, () => this.showErrorPopup(error));
    }
  };

  // TODO You are accessing the MetaMask window.web3.currentProvider shim. This property is deprecated; use window.ethereum instead. For details, see: https://docs.metamask.io/guide/provider-migration.html#replacing-window-web3
  public onDisconnect = async (web3?: Web3, provider?: any) => {
    web3 = web3 || this.state.web3;
    provider = provider || this.state.provider || web3?.currentProvider;

    try {
      if (provider && provider.close)
        await provider.close();
    } catch (error) {
      console.error(error);
    }

    this.web3Modal.clearCachedProvider();

    await this.unsubscribeProviderEvents(provider);

    this.setState({
      address: "",
      // @ts-ignore
      web3: null,
      provider: null,
      chainId: 1,
      networkId: 1,
      assets: [],
      showModal: false,
      isFetching: false,
      isConnecting: false,
      isConnected: false,
      isAuthenticated: false,
      hasPendingRequest: false,
    }, this.onDisconnected);
  };

  public onDisconnected = async () => {
    console.log("onDisconnected");
    cacheClearAccountData();
  }

  public render = () => {
    const {
      isConnecting,
      isConnected,
      isAuthenticated,
      showModal: showModalOrg,
      showGenericModal,
      modalContent,
      hasPendingRequest,
      errorMessage
    } = this.state;

    const showModal = showModalOrg || hasPendingRequest || isConnecting || (isConnected && !isAuthenticated);

    return (
      <Web3Context.Provider value={{
        ...this.state,
        events: {
          onConnect: this.onConnect,
          toggleModal: this.toggleModal,
          resetApp: this.onDisconnect,
          sendTransaction: this.sendTransaction,
          testOpenBox: this.testOpenBox,
          testSignMessage: this.testSignMessage,
          testSendTransaction: this.testSendTransaction,
          testSignPersonalMessage: this.signPersonalMessage,
          testCreateNFT: this.testCreateNFT,
          mintNFT: this.mintNFT,
          balanceOf: this.balanceOf,
          getRoyalty: this.getRoyalty
        }
      }}>
        <Modal className="modal-delete modal-lg modal-dialog-centered"
          isOpen={showModal} toggle={this.toggleModal} backdrop="static">
          <ModalBody>
            {!!errorMessage
              ? (
                <>
                  <small>{errorMessage}</small>
                </>
              )
              : hasPendingRequest
                ? (
                  <>
                    <div>{"Pending Call Request"}</div>
                    <div>
                      <div>
                        {"Approve or reject request using your wallet"}
                      </div>
                    </div>
                  </>
                )
                : isConnecting
                  ? (
                    <div>{"Connecting wallet."}</div>
                  )
                  : isConnected && !isAuthenticated
                    ? (
                      <div>{"Please wait until we authenticate you."}</div>
                    )
                    : (
                      <div>{"Call Request Rejected"}</div>
                    )}
          </ModalBody>
        </Modal>
        <GenericModal
          isOpen={showGenericModal}
          toggle={this.toggleGenericModal}
          modalContent={modalContent}
        />
        {this.props.children}
      </Web3Context.Provider>
    );
  };
}

export { Web3Provider, Web3Context }