/**
 * About Banner
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

class AboutBanner extends Component {

  constructor() {
    super();
    this.state = { showText: false };
  }

  getMoreTextDiv() {
    if (this.state.showText) {
      return <p>Hebys will unify the assets acting as a hub and make it simple for average user by utilizing it's multi-chain structure powered by Ethereum, Binance Smart Chain and Avalanche blockchain.</p>;
    } else {
      return null;
    }
  }

  render() {
    var expandedDiv = this.getMoreTextDiv();
    return (
      <Row className="section-ptb align-items-center">
        <Col md={6} className="order-md-1 mb-4 mb-md-0">
          <div className="single_image-wrapper">
            <img src={require(`../assets/images/logo_background.png`)} className="attachment-full img-fluid" alt="" />
          </div>
        </Col>
        <Col md={6}>
          <div className="text-wrapper">
            <span>Know more</span>
          </div>
          <div className="section-title mb-4">
            <h2 className="title text-left"> About Hebys</h2>
            <div className="text-wrapper">
              <p>Hebys is a true value NFT Marketplace HUB allowing users to create, trade and discover digital assets. Primary objective of the Marketplace is to remove confusion and complexity in terms of UX, user and payment flows.</p>
              {expandedDiv}
            </div>
          </div>
          <div className="ciyashop_button_wrapper ciyashop_button_width_default">
            <div className="inline_hover ciyashop_button_link ciyashop_button_size_medium button-underline">
              <Link className="inline_hover"
                onClick={() => this.setState({ showText: !this.state.showText })}
                style={{
                  cursor: 'pointer'
                }}> {(this.state.showText) ? "Read Less.." : "Read More.."} </Link>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default AboutBanner;
