/**
 * Home Slider Demo 2
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1
};

class HomSlider2 extends Component {

  render() {
    return (
      <Slider className="slider-02 slider-simple-arrow" {...settings}>
        <div key={1} className="slide-02-item">
          <div className="slide-inner">
            <div className="slide-image">
              <img src={require(`../assets/images/home-02-slider/img-01.jpg`)} alt="slide-1" />
            </div>
            <div className="slide-content text-left">
              <div className="container">
                <div className="row justify-content-end">
                  <div className="col-lg-6 col-md-8 col-8">
                    <div className="slide-right" >
                      <div className="slide-subtitle">Only 24 Hours</div>
                      <div className="slide-title">
                        <h1>#Spring Final Sale</h1>
                      </div>
                      <Link className="slide-button-flat" >See full detail</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div key={2} className="slide-02-item">
          <div className="slide-inner">
            <div className="slide-image">
              <img src={require(`../assets/images/home-02-slider/img-02.jpg`)} alt="slide-1" />
            </div>
            <div className="slide-content text-left">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-8 col-8">
                    <div className="slide-left">
                      <div className="slide-subtitle">Up to 80% Discount</div>
                      <div className="slide-title">
                        <h1>#End Of Season Sale</h1>
                      </div>
                      <Link className="slide-button-flat" >See full detail</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    )
  }
}

export default HomSlider2;
