
/**
 *  Shop Main Page
 */
import { Pagination } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from "lodash.debounce";
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { getFilterProductsdata } from '../../services';
import AllProduct from '../../api/product';
import ProductList from '../../widgets/ProductList';
import ShopBanner from '../../widgets/shopfilter/ShopBanner';
import SideFilter from '../../widgets/shopfilter/SideFilter';
import SocialFilter from '../../widgets/shopfilter/SocialInfo';
import TopFilter from '../../widgets/shopfilter/TopFilter';
import { SOCIAL_INFOS } from '../../common/constants';
import { apiGetSaleNFTs } from '../../common/helpers/api';


const numEachPage = 12;
class Market extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            minValue: 0,
            maxValue: 12,
            limit: 8,
            hasMoreProduct: true,
            getproduct: AllProduct,
            lastScrollTop: 0,
            isloading: false,
            getSaleNFts: []
        }

        window.onscroll = debounce(() => {

            var st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > this.state.lastScrollTop) {
                this.onLoadMore();
            }
            this.setState({
                lastScrollTop: st <= 0 ? 0 : st
            })
        }, 100);
    }

    componentDidMount() {
        window.scrollTo(0, 0)  
        this.getSaleNFts()
    }
  
    getSaleNFts = async () => {
     try {
         this.setState({ isloading: true });
         var request = {
             Criteria: {
             },
         };
  
         var getSaleNFtsResponse = (await apiGetSaleNFTs(
             request
         )).data;
  
         var getSaleNFts = getSaleNFtsResponse.list;
         console.log("🚀 ~ file: TopSellingProduct.js ~ line 147 ~ TopSellingProduct ~ getSaleNFtsResponse= ~ getSaleNFts", getSaleNFts)
         
         this.setState({ getSaleNFts, isloading: false })
  
        } catch (error) {
           console.error(error);
           this.setState({ isloading: false });
        }
     };

    handleChange = value => {

        this.setState({
            minValue: (value - 1) * numEachPage,
            maxValue: value * numEachPage
        });
    };

    refreshPage = () => {
        window.location.reload(false);
    };

    onLoadMore = () => {
        this.setState({ isloading: true });
        setTimeout(() => {
            this.setState({
                limit: this.state.limit + 8,
                isloading: false
            });
        }, 2500);
    };

    render() {
        let { products } = this.props;
        let layoutstyle = localStorage.getItem('setLayoutStyle');

        if (layoutstyle == null) {
            layoutstyle = localStorage.setItem('setLayoutStyle', 'col-sm-6 col-md-4')
        }

        return (
            <div className="site-content">
                <div className="inner-intro">
                    <Container>
                        <Row className="intro-title align-items-center">
                            <Col md={6} className="text-left">
                                <div className="intro-title-inner">
                                    <h1>Market</h1>
                                </div>
                            </Col>
                            <Col md={6} className="text-right">
                                <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                                    <li className="home">
                                        <span>
                                            <Link className="bread-link bread-home" to="/">Home</Link>
                                        </span>
                                    </li>
                                    <li><span>Market</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="content-wrapper pt-5 mb-3 mb-md-5">
                    <Container>
                        <Row>
                            <div className="sidebar col-xl-3 col-lg-4 desktop">
                                <div className="shop-sidebar-widgets pt-3 sticky-top">
                                    <SideFilter />
                                </div>
                                {/* <div>
                                    <h4 className="widget-title">Social Info</h4>
                                    {<SocialFilter socialInfos={SOCIAL_INFOS} />}
                                    <ShopBanner />
                                </div> */}
                            </div>
                            <div className="content col-xl-9 col-lg-8">
                                <div className="products-header">
                                    <div className="loop-header">
                                        <div className="loop-header-tools">
                                            <div className="loop-header-tools-wrapper">
                                                <TopFilter productlength={this.state.getSaleNFts.length} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {products.length > 0 ? */}
                                    <div className="content col-xl-12 col-lg-12">
                                        <Row className="products products-loop grid ciyashop-products-shortcode pgs-product-list">
                                            {/* {products.slice(0, this.state.getSaleNFts.limit)
                                                .map((product, index) => */}
                                                {this.state.getSaleNFts.map((product, index) => 
                                                    // <ProductList product={product} key={index} layoutstyle={layoutstyle} />
                                                    <ProductList product={product} key={index}/>
                                                )
                                            }
                                        </Row>
                                        {this.state.isloading &&
                                            <div className="lazyload-img"></div>
                                        }
                                    </div>
                                    {/* :
                                    <Row className="products products-loop grid ciyashop-products-shortcode">
                                        <div className="col-sm-12 text-center  mt-5" >
                                            <img src={require(`../../assets/images/empty-search.jpg`)} className="img-fluid mb-4" />
                                            <h3>Sorry! No products were found matching your selection!</h3>
                                            <p>Please try different criterias to search</p>
                                            <Link to="#" className="btn btn-solid">Continue Looking for</Link>
                                        </div>
                                    </Row>
                                } */}
                            </div>

                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (state) => ({
    products: getFilterProductsdata(state.data, state.filters)
});

export default connect(
    mapDispatchToProps, {}
)(Market)
