/**
 * Admin Profile Detail
 */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import Common from '../api/common';
import adminprofile from '../assets/images/testimonials/img-02.jpg';
import Subscribe from '../widgets/Subscribe.js';
import Footer from '../layouts/footer/Footer';
const listStyle = {
    listStyle: 'none',
    display: 'inline-block',
    paddingLeft: 5
}
class AdminProfileDetail extends Component {
    render() {
        const Profile = Common['0']['profile'];
        const SocialMediaList = Common['0']['socialMedia'];
        return (
            <Row>
                <Col lg={12}>
                    <div className="admin-profile" style={{ backgroundColor: 'transparent' }}>
                        <div className="admin-profile-image" style={{ position: 'relative', height: 120, width: 120 }}>
                            <img className="img-fluid rounded-circle" src={adminprofile} width={120} height={120} style={{ border: '3px solid #04d39f', height: 120 }} alt="profile" />
                            <div className="d-flex product-actions-list">
                                <div className="product-action product-action-wishlist">
                                </div>
                                {
                                    Profile.isChecked == 'true'
                                        ?
                                        <div className="product-action product-action-add-to-cart">
                                            <svg width="35" height="35" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 5, right: '-6px' }}>
                                                <path d="M4.78117 0.743103C5.29164 -0.247701 6.70826 -0.257701 7.21872 0.743103C7.52645 1.33846 8.21742 1.62509 8.8553 1.42099C9.91675 1.08134 10.9186 2.08304 10.5789 3.1446C10.3748 3.78247 10.6614 4.47445 11.2568 4.78117C12.2476 5.29164 12.2476 6.70826 11.2568 7.21872C10.6614 7.52545 10.3748 8.21742 10.5789 8.8553C10.9186 9.91685 9.91685 10.9186 8.8553 10.5789C8.21742 10.3748 7.52545 10.6614 7.21872 11.2568C6.70826 12.2476 5.29164 12.2476 4.78117 11.2568C4.47445 10.6614 3.78247 10.3748 3.1446 10.5789C2.08304 10.9186 1.08134 9.91685 1.42099 8.8553C1.62509 8.21742 1.33846 7.52545 0.743103 7.21872C-0.247701 6.70826 -0.247701 5.29164 0.743103 4.78117C1.33846 4.47445 1.62509 3.78247 1.42099 3.1446C1.08134 2.08304 2.08304 1.08134 3.1446 1.42099C3.78247 1.62509 4.47445 1.33846 4.78117 0.743103Z" fill="#04d39f">
                                                </path>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.43961 4.24998C8.64623 4.43922 8.65221 4.76823 8.48297 4.97484L5.40604 8.13462L3.54703 6.20677C3.34779 6.00014 3.35377 5.67113 3.56039 5.47189C3.76701 5.27266 4.09602 5.27864 4.29526 5.48525L5.40604 6.63718L7.70475 4.25334C7.90398 4.04672 8.23299 4.04074 8.43961 4.23998Z" fill="#FFFFFF">
                                                </path>
                                            </svg>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="admin-profile-info">
                            <br/>
                            <b><h3 >{Profile.firstname + ' ' + Profile.lastname}</h3></b>
                            <span className="text-dark">{Profile.email}</span>
                            <div className="pgs-social-profiles">
                                <div className="social-profiles-wrapper">
                                    <div className="social-profiles-wrapper-inner social-profiles-default social-profiles-shape-square">
                                        <div className="social-profiles">
                                            <ul>
                                                {SocialMediaList.map(x => {
                                                    return (
                                                        x.platform == "1" ?
                                                            <li style={listStyle}><a href={x.url} title="Facebook" target="_blank"><i className="fa fa-facebook" /></a></li>
                                                            :
                                                            x.platform == "2" ?
                                                                <li style={listStyle}><a href={x.url} title="Twitter" target="_blank"><i className="fa fa-twitter" /></a></li>
                                                                :
                                                                x.platform == "3" ?
                                                                    <li style={listStyle}><a href={x.url} title="Google" target="_blank"><i className="fa fa-google" /></a></li>
                                                                    :
                                                                    x.platform == '4' ?
                                                                        <li style={listStyle}><a href={x.url} title="Vimeo" target="_blank" ><i className="fa fa-vimeo" /></a></li>
                                                                        :
                                                                        x.platform == '5' ?
                                                                            <li style={listStyle}><a href={x.url} title="Pinterest" target="_blank" ><i className="fa fa-pinterest" /></a></li>
                                                                            :
                                                                            x.platform == '6' ?
                                                                                <li style={listStyle}><a href={x.url} title="Pinterest" target="_blank" ><i className="fa fa-behance" /></a></li>
                                                                                :
                                                                                null)
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*                             <i className="fa fa-clone  pr-2" style={{ paddingLeft: 10, cursor: 'pointer' }} onClick={() => navigator.clipboard.writeText("Text", 'Copy this text to clipboard')} />
 */}                        </div>



                        {/*    {Profile.phoneno != '' ?
                            <div className="admin-profile-number ml-auto">
                                <strong className="h2"> <i className="fa fa-phone-square pr-2"></i>{Profile.phoneno}</strong>
                            </div>
                            :
                            null
                        } */}

                    </div>
                </Col>
            </Row>
        )
    }
}
export default AdminProfileDetail;
