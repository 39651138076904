
/**
 * ProductDetailTraits Widget
 */
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PropertyText from './PropertyText'
import PropertyStats from './PropertyStats'
import PropertyLevels from './PropertyLevels'
import { TraitTypes } from '../common/constants';

class ProductDetailTraits extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }
    render() {
        const { traits } = this.props;
        return (
            <>
                <Row>
                    <Col md={12} >
                        {traits != null ? traits.map((x, index) => {
                            return (
                                x.traitType == TraitTypes.Property ?
                                    <PropertyText propertyKey={x.key} value={x.textValue} />
                                    :
                                    null)
                        }) : null}
                    </Col>
                </Row>
                <h3>Stats</h3>
                <Row>
                    <Col md={12} >
                        {traits != null ? traits.map(x => {
                            return (
                                x.traitType == TraitTypes.Stats ?
                                    <PropertyStats propertyKey={x.key} value1={x.numericValue2} value2={x.numericValue3} />
                                    :
                                    null)
                        }) : null}
                    </Col>
                </Row>
                <h3>Levels</h3>
                <Row>
                    <Col md={12} >
                        {traits != null ? traits.map(x => {
                            return (
                                x.traitType == TraitTypes.Levels ?
                                    <PropertyLevels propertyKey={x.key} min={x.numericValue1} value={x.numericValue2} max={x.numericValue3} />
                                    :
                                    null)
                        }) : null}
                    </Col>
                </Row>
            </>
        )
    }
}

export default ProductDetailTraits;
