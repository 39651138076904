/**
 *  header-menu and sidebar menu data
 */
export default [
/*    {
      "menu_title": "Home",
      "type": "subMenu",
      "path": "/",
      "icon": "home",
      "child_routes": [
         {
            "path": "/",
            "menu_title": "Home Default",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/index-new-fashion",
            "menu_title": "Home New Fashion",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/index-modern",
            "menu_title": "Home Modern",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/index-home-classic",
            "menu_title": "Home Classic",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
      ]
   }, */
   {
      "menu_title": "Market",
      "path": "/market",
      "mega": true,
      "icon": "party_mode",
      //"type": "subMenu",
      /*  "child_routes": [
        {
            "path": "/sidebar-with-load-more",
            "menu_title": "Sidebar With Load More",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/topbar-with-load-more",
            "menu_title": "Topbar  With Load More",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/sidebar-without-lazyload",
            "menu_title": "Sidebar Without Lazyload",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/topbar-without-lazyload",
            "menu_title": "Topbar Without Lazyload",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/sidebar-with-lazyload",
            "menu_title": "Sidebar With Lazyload",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/shop/clothing/29",
            "menu_title": "Product Detail",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
      ],*/
   }, 
   {
      // "menu_title": "Blog",
      "menu_title": "Stats",
      "path": "#",
      "mega": true,
      "icon": "party_mode",
      "type": "subMenu",
      "child_routes": [
         {
            "path": "/Rankings",
            // "menu_title": "Blog Full Width",
            "menu_title": "Rankings",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/Activity",
            // "menu_title": "Blog Post Single",
            "menu_title": "Activity",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
      ]
   },

   {
      // "menu_title": "Pages",
      "menu_title": "Community",
      "type": "subMenu",
      "path": "#",
      "icon": "home",
      "child_routes": [
         {
            "path": "#",
            // "menu_title": "About Us",
            "menu_title": "Help Center",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "#",
            // "menu_title": "Contact Us",
            "menu_title": "Partners & Developers",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "#",
            // "menu_title": "Coming Soon",
            "menu_title": "Suggestions",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "#",
            // "menu_title": "Maintenance",
            "menu_title": "Discord Community",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "#",
            // "menu_title": "Page Not Found",
            "menu_title": "Blog",
            "icon": "arrow_right_alt",
            "child_routes": null
         }
      ]
   },
   {
      "menu_title": "Profile",
      "path": "/admin-panel/Reports",
      "icon": "perm_identity",
      "child_routes": null
   },
   {
      "menu_title": "Create",
      "path": "/create",
      "icon": "perm_identity",
      "child_routes": null
   }
]
