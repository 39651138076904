/**
 *  Wallet Connect Page
 */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
//import logo from '../../assets/images/logo-new.png';
//import Loader from 'react-loader-spinner';
import WalletSignIn from '../Wallet/widgets/WalletSignIn';

class WalletConnect extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <div className="maintenance">
                    <div className="site-content">
                        <div className="content-wrapper">
                            <Container>
                                {/* <Row className="align-items-left justify-content-center">
                                    <Col md={11} lg={7} className="text-center">
                                        <div className="mntc-cs-item mntc-cs-content text-center">
                                            <img className="img-fluid" src={logo} alt="logo" />
                                        </div>
                                    </Col>
                                </Row> */}
                                <Row className="align-items-center justify-content-center" style={{ marginTop: 50 }}>
                                    <Col md={11} lg={7} className="text-center">
                                        <div className="mntc-cs-item mntc-cs-content text-center">
                                            <h1>Connect your wallet</h1>
                                            <p>Connect with one of available wallet providers or create a new wallet.</p>
                                            <WalletSignIn />
                                        </div>

                                        <h3>or</h3>
                                        <Link to="/" className="btn-white btn" style={{ marginBottom: 20 }}>
                                            <i className="fa fa-arrow-left" style={{ marginRight: 4 }} />
                                            Go Back
                                         </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default WalletConnect;