import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { apiGetCollection, apiGetCategory, apiGetChain ,apiGetTradingHistory,apiGetEventType,apiGetTradingHistoryFilter} from "../../common/helpers/api";
import { Col, Container, Row, Button } from 'reactstrap';
import { TabPanel, Tabs } from 'react-tabs';
import { property, set } from 'lodash';
import {getLocalizedTextWithValue} from '../../common/helpers/localizationManager';
import moment from 'moment';

class Activity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isloading: false,
            request: {
                categories: [],
                collections: [],
                chains: [],
                eventTypes: [],
                search:''
            },
            activeFilter: [],
            tradingsHistoryList: [],
            eventTypeList: [],
            SearchValue: "",
            collectionList: [],
            categoryList: [],
            chainList: [],
            filteredCollection:[],

        };

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.getCollectionList()
        this.getChainList()
        this.getCategoryList()
        this.getEventTypeList()
        this.getTradingHistoryList()
        
       
        console.log("🚀 ~ file: Activity.js ~ line 126 ~ ItemCreate ~ componentDidMount ~ context",)

    }
    
    getCollectionList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria: {
                    status: 1
                },
            };
            var collectionResponse = (await apiGetCollection(
                request
            )).data;

            var collectionList = collectionResponse.data.list;
            this.setState({ collectionList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
        console.log("🚀 ~ file: Activity.js ~ line 151 ~ Activity ~ getCollectionList ~ collectionList", collectionList)
    };

    getTradingHistoryList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria:{
                    status: 1
            }
                
            };
            var tradingHistoryResponse = (await apiGetTradingHistory(
                request
            )).data;

            var tradingsHistoryList = tradingHistoryResponse.data.list;
            this.setState({ tradingsHistoryList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
        console.log("🚀 ~ file: Activity.js ~ line 103 ~ Activity ~ getTradingHistoryList ~ tradingsHistoryList", tradingsHistoryList)
    };
    
    getTradingHistoryFilterList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria:{
                CollectionList:this.state.request.collections,
                ChainList:this.state.request.chains,
                EventTypeList:this.state.request.eventTypes,
            }
            };
            var tradingHistoryFilterResponse = (await apiGetTradingHistoryFilter(
                request
            )).data;
            
            console.log(request,"---getTradingHistoryFilterList-request")

            var tradingsHistoryList = tradingHistoryFilterResponse.data.list;
            this.setState({ tradingsHistoryList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
        console.log("🚀 ~ file: Activity.js ~ line 130 ~ Activity ~ getTradingsHistoryFilterList ~ tradingsHistoryFilterList", tradingsHistoryList)
    };

    getCategoryList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria: {
                    status: 1
                },
            };
            var categoryResponse = (await apiGetCategory(
                request
            )).data;

            var categoryList = categoryResponse.data.list;
            console.log("🚀 ~ file: Activity.js ~ line 190 ~ Activity ~ getCategoryList= ~ categoryList", categoryList)
            this.setState({ categoryList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
    };

    getEventTypeList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria: {
                    keyCode:"ACTIVITY_EVENT_TYPE_OPTION"
                },
            };

            var eventTypeResponse = (await apiGetEventType(
                request
            )).data;

            var eventTypeList = eventTypeResponse.data.list;
            console.log("🚀 ~ file: Activity.js ~ line 213 ~ Activity ~ getEventTypeList= ~ eventTypeList", eventTypeList)
            this.setState({ eventTypeList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
    };
    getChainList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria: {
                    status: 1
                },
            };
            var chainResponse = (await apiGetChain(
                request
            )).data;

            var chainList = chainResponse.data.list;
            console.log(" ~ file: Activity.js ~ line 189 ~ Activity ~ getChainList= ~ chainList", chainList)
            this.setState({ chainList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
    };

     onChangeCategoryFilter(event) {
        var request = this.state.request;

        if (event.target.checked == "1") {
            if (!request.categories.includes(event.target.value))
            request.categories.push(event.target.value);
        }
        else
            if (request.categories.includes(event.target.value)) {
                request.categories = request.categories.filter(function (value, index, arr) {
                    return value != event.target.value;
                });

            }
            this.setState({request})
    }
    onChangeCollectionFilter(event) {
        var request = this.state.request;

        if (event.target.checked == "1") {
            if (!request.collections.includes(event.target.value))
            request.collections.push(event.target.value);
        }
        else
            if (request.collections.includes(event.target.value)) {
                request.collections = request.collections.filter(function (value, index, arr) {
                    return value != event.target.value;
                });

            }
            this.setState({request})
    }

  
    onChangeChainFilter(event) {
        var request = this.state.request;

        if (event.target.checked == "1") {
            if (!request.chains.includes(event.target.value))
            request.chains.push(event.target.value);
        }
        else
            if (request.chains.includes(event.target.value)) {
                request.chains = request.chains.filter(function (value, index, arr) {
                    return value != event.target.value;
                });

            }
            this.setState({request})
            
            console.log(request)
    }

    onChangeEventTypeFilter(event) {
        var request = this.state.request;

        if (event.target.checked == "1") {
            if (!request.eventTypes.includes(event.target.value))
            request.eventTypes.push(event.target.value);
        }
        else
            if (request.eventTypes.includes(event.target.value)) {
                request.eventTypes = request.eventTypes.filter(function (value, index, arr) {
                    return value != event.target.value;
                });

            }
            this.setState({request})
    }
  

    SearchCollection = (event) => {
        this.setState({ SearchValue: event.target.value })
    }

    clearcollection() {
        var collectionList = [];
        this.state.request.collections(collectionList);
    }
    clearcategory() {
        var categoryList = [];
        this.state.request.category(categoryList);
    }
    cleareventType() {
        var request=this.state.request
        var eventTypeList = [];
        request.eventTypes=eventTypeList;
        this.setState({request,})
        
        
    }

    render() {
        let { categoryList, collectionList,eventTypeList,chainList} = this.state;
        const categoryFilterValues = this.state.request.categories;
        const collectionFilterValues=this.state.request.collections;
        const chainFilterValues=this.state.request.chains;
        const eventTypeFilterValues=this.state.request.eventTypes;

        return (
            <div className="site-content">
                <div className="inner-intro">
                    <Container>
                        <Row className="intro-title align-items-center">
                            <Col md={6} className="text-left">
                                <div className="intro-title-inner">
                                    <h1>Activity</h1>
                                </div>
                            </Col>
                            <Col md={6} className="text-right">
                                <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                                    <li className="home">
                                        <span>
                                            <Link className="bread-link bread-home" to="/">Home</Link>
                                        </span>
                                    </li>
                                    <li><span>Activity</span></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="content-wrapper pt-5 mb-3 mb-md-5">
                    <Container>
                        <Row>
                            <div className="sidebar col-xl-3 col-lg-4 desktop">
                                <div className="shop-sidebar-widgets pt-3 sticky-top">
                                    <div className="widget widget_price_filter">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h4 className="widget-title"> Event Type</h4>
                                            {/* <p><a className="price-clear-filter" onClick={() => this.cleareventType()} >Clear</a></p> */}
                                        </div>
                                        <div className="pgs-widget-layered-nav-list-container has-scrollbar" style={{ height: '210px' }}>
                                            <Scrollbars>
                                            <ul className="pgs-widget-layered-nav-list" tabIndex={0} style={{ right: '-17px' }}>
                                                    {eventTypeList.map((eventType, index) => {
                                                        return (
                                                            <div className="form-check pgs-filter-checkbox" key={index}>
                                                                { <input type="checkbox" onClick={(e) => this.onChangeEventTypeFilter(e,)} value={eventType.value} defaultChecked={eventTypeFilterValues.includes(eventType.value) ? true : false} className="form-check-input" id={eventType.value} />}
                                                                <label className="form-check-label"
                                                                    htmlFor={eventType.value}>{eventType.translations.en}</label>
                                                            </div>
                                                        )
                                                    })}
                                                </ul>
                                            </Scrollbars>
                                        </div>
                                    </div>

                                    {/* <div className="widget widget_layered_nav widget-layered-nav pgs_widget-layered-nav">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h4 className="widget-title"> Categories</h4>
                                            <p><a className="price-clear-filter" onClick={() => this.clearcategory()} >Clear</a></p>
                                        </div>
                                        <div className="pgs-widget-layered-nav-list-container has-scrollbar" style={{ height: '210px' }}>
                                            <Scrollbars>
                                                <ul className="pgs-widget-layered-nav-list" tabIndex={0} style={{ right: '-17px' }}>
                                                    {categoryList.map((category, index) => {
                                                        return (
                                                            <div className="form-check pgs-filter-checkbox" key={index}>
                                                                { <input type="checkbox"  onClick={(e) => this.onChangeCategoryFilter(e, categoryFilterValues)} value={category.id} defaultChecked={categoryFilterValues.includes(category.name) ? true : false} className="form-check-input" id={category.name} />}
                                                                <label className="form-check-label"
                                                                    htmlFor={category.name}>{category.name}</label>
                                                            </div>
                                                        )
                                                    })}
                                                </ul>
                                            </Scrollbars>
                                        </div>
                                    </div> */}

                                    <div>
                                        <div className="widget widget_layered_nav widget-layered-nav pgs_widget-layered-nav">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="widget-title"> Collections</h4>
                                                {/* <p><a className="price-clear-filter" onClick={() => this.clearcollection()} >Clear</a></p> */}
                                            </div>
                                            {/* <div className="widget">
                                                <input onChange={this.SearchCollection}  type="text" id="btn-search" className="form-control" placeholder="Search a Collection" />
                                            </div> */}
                                            <div className="pgs-widget-layered-nav-list-container has-scrollbar" style={{ height: '210px' }}>
                                                <Scrollbars>
                                                    <ul className="pgs-widget-layered-nav-list" tabIndex={0} style={{ right: '-17px' }}>
                                                        {collectionList.map((collection, index) => {
                                                            return (
                                                                <div className="form-check pgs-filter-checkbox" key={index}>
                                                                    { <input type="checkbox" className="form-check-input"  onClick={(e) => this.onChangeCollectionFilter(e)} value={collection.id} defaultChecked={collectionFilterValues.includes(collection.name) ? true : false}  id={collection.name} />}
                                                                    <label className="form-check-label"
                                                                        htmlFor={collection.name}>{collection.name}</label>
                                                                        
                                                                </div>
                                                            )
                                                        })}
                                                    </ul>
                                                </Scrollbars>
                                            </div>
                                        </div>
                                        <div className="widget widget_layered_nav widget-layered-nav pgs_widget-layered-nav">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="widget-title"> Chains</h4>
                                                {/* <p><a className="price-clear-filter" onClick={() => this.clearcollection()} >Clear</a></p> */}
                                            </div>
                                            <div className="pgs-widget-layered-nav-list-container has-scrollbar" style={{ height: '210px' }}>
                                                <Scrollbars>
                                                    <ul className="pgs-widget-layered-nav-list" tabIndex={0} style={{ right: '-17px' }}>
                                                        {chainList.map((chain, index) => {
                                                            return (
                                                                <div className="form-check pgs-filter-checkbox" key={index}>
                                                                    { <input type="checkbox" className="form-check-input"  onClick={(e) => this.onChangeChainFilter(e)} value={chain.id} defaultChecked={chainFilterValues.includes(chain.networkName) ? true : false}  id={chain.networkName} />}
                                                                    <label className="form-check-label"
                                                                     htmlFor={chain.networkName} >{chain.networkName}</label>
                                                                </div>
                                                            )
                                                        })}
                                                    </ul>
                                                </Scrollbars>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Sidefilter /> */}
                                </div>
                            </div>
                            <div className="content col-xl-9 col-lg-8">
                                <div className="products-header">
                                    <div className="loop-header">
                                        <div className="loop-header-tools">
                                            <div className="loop-header-tools-wrapper">
                                                <div className="section-ptb">
                                                    <Container>
                                                        <Row>
                                                            <div className="tranding-title" >
                                                                <h3 className="mb-0"> Trading History</h3>
                                                            </div>
                                                            <Button className="trading-refresh-button btn btn-primary ml-auto" onClick={()=>this.getTradingHistoryFilterList()}><i className="fa fa-history"></i></Button>
                                                        </Row>
                                                        <Tabs>
                                                            <TabPanel>
                                                                <div className="tab-pane fade show active " id="trading-list" role="tabpanel" aria-labelledby="transaction-list-tab">
                                                                    <div className="table-responsive">

                                                                        <table className="table trading-table mb-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">Event</th>
                                                                                    <th scope="col">Item</th>
                                                                                    <th scope="col"></th>
                                                                                    <th scope="col">Unit Price</th>
                                                                                    <th scope="col">Quantity</th>
                                                                                    <th scope="col">From</th>
                                                                                    <th scope="col">To</th>
                                                                                    <th scope="col">Date</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.tradingsHistoryList.map((TradingItem, index) =>
                                                                                    <tr>
                                                                                        <td>{getLocalizedTextWithValue("ACTIVITY_EVENT_TYPE_OPTION",TradingItem.eventType)}</td>
                                                                                        <td><img className="img-fluid  user-image th-img" src={ TradingItem.nft !=null ? TradingItem.nft.defaultPreviewUrl !=null ? TradingItem.nft.defaultPreviewUrl : "" : "" }/></td>
                                                                                        <td><Link to={`/token/${TradingItem.creatorContractAddress}/${TradingItem.tokenId}`}>{TradingItem.nft != null ? TradingItem.nft.name : null}</Link></td>
                                                                                        <td>{TradingItem.bidPrice}</td>
                                                                                        <td>{TradingItem.quantity}</td>
                                                                                        <td>{TradingItem.fromAccountId}</td>
                                                                                        <td>{TradingItem.toAddress}</td>
                                                                                        <td>{moment(TradingItem.createdDate).format('YYYY-MM-DD HH:MM:SS')}</td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                        </Tabs>
                                                    </Container>
                                                </div>
                                                {/* <TradingHistoryList /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>


                    </Container>
                </div>
            </div>
        )
    }
}

export default Activity