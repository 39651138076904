import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { apiGetAuctionBidList,apiGetCategory,apiGetChain,apiGetCoin,apiGetAuctionBidFilter} from "../../common/helpers/api";
import { Col, Container, Row, Button } from 'reactstrap';
import { TabPanel, Tabs } from 'react-tabs';

class Rankings extends Component {
    constructor(props){
        super(props);
        this.state={
            request:{
                categories: [],
                chains:[],
                coins:[]
            },
            auctionBidList:[],
            categoryList:[],
            chainList:[],
            coinList:[]
        }
    }
    componentDidMount(){
        window.scrollTo(0, 0)
        this.getAuctionBidList()
        this.getCategoryList()
        this.getChainList()
        this.getCoinList()
        console.log("🚀 ~ file: Activity.js ~ line 126 ~ ItemCreate ~ componentDidMount ~ context",)

    }
    getAuctionBidList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria: {
                    status: 1
                },
            };
            var auctionBidResponse = (await apiGetAuctionBidList(
                request
            )).data;
                console.log(request)
            var auctionBidList = auctionBidResponse.data.list;
            console.log("🚀 ~ file: Activity.js ~ line 190 ~ Activity ~ getCategoryList= ~ auctionBidList", auctionBidList)
            this.setState({ auctionBidList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
    };
    getAuctionBidFilterList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria:{
                ChainList:this.state.request.chains,
                CoinList:this.state.request.coins,
            }
            };
            var auctionBidFilterResponse = (await apiGetAuctionBidFilter(
                request
            )).data;
            
            console.log(request,"---getTradingHistoryFilterList-request")

            var auctionBidList = auctionBidFilterResponse.data.list;
            this.setState({ auctionBidList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
        console.log("🚀 ~ file: Activity.js ~ line 130 ~ Activity ~ getTradingsHistoryFilterList ~ tradingsHistoryFilterList", auctionBidList)
    };

    getCategoryList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria: {
                    status: 1
                },
            };
            var categoryResponse = (await apiGetCategory(
                request
            )).data;

            var categoryList = categoryResponse.data.list;
            console.log("🚀 ~ file: Activity.js ~ line 190 ~ Activity ~ getCategoryList= ~ categoryList", categoryList)
            this.setState({ categoryList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
    };

    getChainList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria: {
                    status: 1
                },
            };
            var chainResponse = (await apiGetChain(
                request
            )).data;

            var chainList = chainResponse.data.list;
            console.log(" ~ file: Activity.js ~ line 189 ~ Activity ~ getChainList= ~ chainList", chainList)
            this.setState({ chainList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
    };

    getCoinList = async () => {
        try {
            this.setState({ isloading: true });
            var request = {
                Criteria: {
                    status: 1
                },
            };
            var coinResponse = (await apiGetCoin(
                request
            )).data;

            var coinList = coinResponse.data.list;
            console.log(" ~ file: Activity.js ~ line 189 ~ Activity ~ getChainList= ~ chainList", coinList)
            this.setState({ coinList, isloading: false })

        } catch (error) {
            console.error(error);
            this.setState({ isloading: false });
        }
    };
    onChangeCategoryFilter(event) {
        var request = this.state.request;

        if (event.target.checked == "1") {
            if (!request.categories.includes(event.target.value))
            request.categories.push(event.target.value);
        }
        else
            if (request.categories.includes(event.target.value)) {
                request.categories = request.categories.filter(function (value, index, arr) {
                    return value != event.target.value;
                });

            }
            this.setState({request})
            console.log(request)
    }

    onChangeChainFilter(event) {
        var request = this.state.request;

        if (event.target.checked == "1") {
            if (!request.chains.includes(event.target.value))
            request.chains.push(event.target.value);
        }
        else
            if (request.chains.includes(event.target.value)) {
                request.chains = request.chains.filter(function (value, index, arr) {
                    return value != event.target.value;
                });

            }
            this.setState({request})
            
            console.log(request)
    }
    onChangeCoinFilter(event) {
        var request = this.state.request;

        if (event.target.checked == "1") {
            if (!request.coins.includes(event.target.value))
            request.coins.push(event.target.value);
        }
        else
            if (request.coins.includes(event.target.value)) {
                request.coins = request.coins.filter(function (value, index, arr) {
                    return value != event.target.value;
                });

            }
            this.setState({request})
            
            console.log(request)
    }
    
    render() {
        let {categoryList,chainList,coinList}=this.state;
        const categoryFilterValues = this.state.request.categories;
        const chainFilterValues=this.state.request.chains;
        const coinFilterValues=this.state.request.coins;
        return (
            <div className="site-content">
            <div className="inner-intro">
                <Container>
                    <Row className="intro-title align-items-center">
                        <Col md={6} className="text-left">
                            <div className="intro-title-inner">
                                <h1>Rankings</h1>
                            </div>
                        </Col>
                        <Col md={6} className="text-right">
                            <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                                <li className="home">
                                    <span>
                                        <Link className="bread-link bread-home" to="/">Home</Link>
                                    </span>
                                </li>
                                <li><span>Rankings</span></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="content-wrapper pt-5 mb-3 mb-md-5">
                <Container>
                    <Row>
                        <div className="sidebar col-xl-3 col-lg-4 desktop">
                            <div className="shop-sidebar-widgets pt-3">
                                <div>
                                { /* <div className="widget widget_layered_nav widget-layered-nav pgs_widget-layered-nav">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h4 className="widget-title"> Categories</h4>
                                            {<p><a className="price-clear-filter" onClick={() => this.clearcollection()} >Clear</a></p> }
                                        </div>
                                        <div className="pgs-widget-layered-nav-list-container has-scrollbar" style={{ height: '210px' }}>
                                            <Scrollbars>
                                                <ul className="pgs-widget-layered-nav-list" tabIndex={0} style={{ right: '-17px' }}>
                                                {categoryList.map((category, index) => {
                                                        return (
                                                            <div className="form-check pgs-filter-checkbox" key={index}>
                                                                { <input type="checkbox" onClick={(e) => this.onChangeCategoryFilter(e, categoryFilterValues)} value={category.id} defaultChecked={categoryFilterValues.includes(category.name) ? true : false} className="form-check-input" id={category.name} />}
                                                                <label className="form-check-label"
                                                                    htmlFor={category.name}>{category.name}</label>
                                                            </div>
                                                        )
                                                    })}
                                                </ul>
                                            </Scrollbars>
                                        </div>
                                    </div>
                                </div> */}
                                  <div className="widget widget_layered_nav widget-layered-nav pgs_widget-layered-nav">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="widget-title"> Coins</h4>
                                                {/* <p><a className="price-clear-filter" onClick={() => this.clearcollection()} >Clear</a></p> */}
                                            </div>
                                            <div className="pgs-widget-layered-nav-list-container has-scrollbar" style={{ height: '210px' }}>
                                                <Scrollbars>
                                                    <ul className="pgs-widget-layered-nav-list" tabIndex={0} style={{ right: '-17px' }}>
                                                        {coinList.map((coin, index) => {
                                                            return (
                                                                <div className="form-check pgs-filter-checkbox" key={index}>
                                                                    { <input type="checkbox" className="form-check-input"  onClick={(e) => this.onChangeChainFilter(e)} value={coin.id} defaultChecked={coinFilterValues.includes(coin.name) ? true : false}  id={coin.name} />}
                                                                    <label className="form-check-label"
                                                                     htmlFor={coin.name} >{coin.name}</label>
                                                                </div>
                                                            )
                                                        })}
                                                    </ul>
                                                </Scrollbars>
                                            </div>
                                        </div>
                                    </div>

                                 <div className="widget widget_layered_nav widget-layered-nav pgs_widget-layered-nav">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="widget-title"> Chains</h4>
                                                {/* <p><a className="price-clear-filter" onClick={() => this.clearcollection()} >Clear</a></p> */}
                                            </div>
                                            <div className="pgs-widget-layered-nav-list-container has-scrollbar" style={{ height: '210px' }}>
                                                <Scrollbars>
                                                    <ul className="pgs-widget-layered-nav-list" tabIndex={0} style={{ right: '-17px' }}>
                                                        {chainList.map((chain, index) => {
                                                            return (
                                                                <div className="form-check pgs-filter-checkbox" key={index}>
                                                                    { <input type="checkbox" className="form-check-input"  onClick={(e) => this.onChangeChainFilter(e)} value={chain.id} defaultChecked={chainFilterValues.includes(chain.networkName) ? true : false}  id={chain.networkName} />}
                                                                    <label className="form-check-label"
                                                                     htmlFor={chain.networkName} >{chain.networkName}</label>
                                                                </div>
                                                            )
                                                        })}
                                                    </ul>
                                                </Scrollbars>
                                            </div>
                                    </div>
                                
                                {/* <Sidefilter /> */}
                            </div>
                        </div>
                        <div className="content col-xl-9 col-lg-8">
                            <div className="products-header">
                                <div className="loop-header">
                                    <div className="loop-header-tools">
                                        <div className="loop-header-tools-wrapper">
                                            <div className="section-ptb">
                                                <Container>
                                                    <Row>
                                                        <div className="tranding-title" >
                                                            {/* <h3 className="mb-0"> Trading History</h3> */}
                                                        </div>
                                                        <Button className="trading-refresh-button btn btn-primary ml-auto" onClick={()=>this.getAuctionBidFilterList()}><i className="fa fa-history"></i></Button>
                                                    </Row>
                                                    <Tabs>
                                                        <TabPanel>
                                                            <div className="tab-pane fade show active " id="trading-list" role="tabpanel" aria-labelledby="transaction-list-tab">
                                                                <div className="table-responsive">

                                                                    <table className="table trading-table mb-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Auction</th>
                                                                                <th scope="col">7 Day Volume</th>
                                                                                <th scope="col">7 Day Change</th>
                                                                                <th scope="col">Total Volume</th>
                                                                                <th scope="col">Avg Price</th>
                                                                                <th scope="col">Owners</th>
                                                                                <th scope="col">Assets</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.auctionBidList.map((auctionBidItem, index) =>
                                                                                <tr>
                                                                                   <td>{auctionBidItem.AuctionId}</td>
                                                                                   <td>{auctionBidItem.BidPrice}</td>
                                                                                   <td>{auctionBidItem.BidPrice}</td>
                                                                                    <td>{auctionBidItem.TotalPrice}</td>
                                                                                    <td>{auctionBidItem.BidPrice}</td>
                                                                                    <td>{auctionBidItem.BidderUserId}</td>
                                                                                    <td>{auctionBidItem.CoinId}</td> 
                                                                                    
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </TabPanel>
                                                    </Tabs>
                                                </Container>
                                            </div>
                                            {/* <TradingHistoryList /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>


                </Container>
            </div>
        </div>
        )
    }
}
export default Rankings;