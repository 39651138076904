/**
 * CreateAndSellYourNfts Widget
 */
import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';

class CreateAndSellYourNfts extends Component {

    render() {
        return (
            <Container>   
                <Row className="section-ptb section-1">
                    <Col sm={12} className="col-sm-12">
                        <div className="section-title text-center">
                            <h2 className="title text-white">Create and sell your NFTs</h2>
                        </div>
                    </Col>
                    <Col sm={12} lg={3} className="text-center mb-4 mb-lg-0">
                    <img src={require(`../assets/images/icons/wallet.png`)} className="img-fluid attachment-full" alt="wallet" />
                        <div className="section-title  ">
                            <h3 className="title text-white">Set up your wallet</h3>*
                            <p>Once you’ve set up your wallet of choice, connect it to Hebys by clicking the wallet icon in the top right corner</p>
                        </div>
                    </Col>
                    <Col sm={12} lg={3} className="text-center mb-4 mb-lg-0">
                    <img src={require(`../assets/images/icons/collection.png`)} className="img-fluid attachment-full" alt="collection" />
                        <div className="section-title ">
                            <h3 className="title text-white">Create your collection</h3>*
                            <p>Click Create and set up your collection. Add social links, a description, profile and banner images, and set a secondary sales fee</p>
                        </div>
                    </Col>
                    <Col sm={12} lg={3} className="text-center mb-4 mb-lg-0">
                    <img src={require(`../assets/images/icons/image.png`)} className="img-fluid attachment-full" alt="picturee" />
                        <div className="section-title">
                            <h3 className="title text-white">Add your NFTs</h3>*
                            <p>Upload your work (image, video, audio, or 3D art), add a title and description, and customize your NFTs with properties, stats, and unlockable content</p>
                        </div>
                    </Col>

                    <Col sm={12} lg={3} className="text-center mb-4 mb-lg-0">
                    <img src={require(`../assets/images/icons/sale.png`)} className="img-fluid attachment-full" alt="sale" />
                        <div className="section-title ">
                            <h3 className="title text-white">List them for sale</h3>*
                            <p>Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your NFTs, and we help you sell them!</p>
                        </div>
                    </Col>
                </Row>
            </Container>

        )
    }
}

export default CreateAndSellYourNfts;
