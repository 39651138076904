/**
 *  Admin Dashboard Menu
 */
import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import PageNotFound from '../../component/Pages/PageNotFound';
import Footer from '../../layouts/footer/Footer';
import Headers from '../../layouts/header/Header';

// Component
import AdminHeader from './Adminheader';
import AdminProductList from './AdminProductList';
import Collaboration from './Collaboration';
import Invoices from './Invoices';
import ItemCreate from './Item/ItemCreate';
import ItemEdit from './Item/ItemEdit';
import Profile from './Profile';
import Profileedit from './Profileedit';
import Reports from './Reports';
import Settings from './Settings';


class AdminDashboard extends Component {

  IsAdminPage(pathname) {
    let pathArray = pathname.split('/');
    let productEditPage = pathArray[pathArray.length - 2];
    let pageName = pathArray[pathArray.length - 1];
    if (productEditPage == "Edit" || productEditPage == "Sell") {
      return true;
    }
    else {
      if (pageName == "Reports" || pageName == "Invoices" || pageName == "Profile" || pageName == "Product" || pageName == "Profileedit" || pageName == "Profileedit" || pageName == "Collaboration"
        || pageName == "Settings" || pageName == "product-add" || pageName == "Edit")
        return true;
      else
        return false;
    }

  }

  render() {
    const { location } = this.props;
    const { match } = this.props;
    return (
      <Fragment>
        {
          this.IsAdminPage(location.pathname) ?

            <div>
              <AdminHeader />
              <Route path={`${match.url}/Reports`} component={Reports} />
              <Route path={`${match.url}/Invoices`} component={Invoices} />
              <Route path={`${match.url}/Profile`} component={Profile} />
              <Route path={`${match.url}/Product`} component={AdminProductList} />
              <Route path={`${match.url}/Profileedit`} component={Profileedit} />
              <Route path={`${match.url}/Collaboration`} component={Collaboration} />
              <Route path={`${match.url}/Settings`} component={Settings} />
              <Route path={`${match.url}/Create`} component={ItemCreate} />
              <Route path={`${match.url}/Edit/:id`} component={ItemEdit} />
            </div>

            :

            <div>
              <Headers />
              <Route exact component={PageNotFound} />
              <Footer />
            </div>


        }
      </Fragment>
    )
  }
}
export default AdminDashboard;

