/**
 * Default Home Page
 */
import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import AboutBanner from '../../widgets/AboutBanner.js';
import CreateAndSellYourNft from '../../widgets/CreateAndSellYourNft.js';
import DealOfTheWeek from '../../widgets/DealOfTheWeek.js';
import TrendingCollection from '../../widgets/TrendingCollection.js';
import HomeBanner from '../../widgets/HomeBanner.js';
import HomeSlider from '../../widgets/HomSlider.js';
//import Instagram from '../../widgets/Instafeed.js';
import OurLatestPost from '../../widgets/OurLatestPost.js';
import Subscribe from '../../widgets/Subscribe.js';
import TopSellingProduct from '../../widgets/TopSellingProduct.js';


const latestblogslider = {
   dots: false,
   infinite: true,
   arrows: true,
   speed: 500,
   slidesToShow: 2,
   slidesToScroll: 1,
   responsive: [
      {
         breakpoint: 991,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1
         }
      },
      {
         breakpoint: 767,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1
         }
      },
      {
         breakpoint: 575,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1
         }
      }
   ]
};

class HomePage extends Component {
   componentDidMount() {
      window.scrollTo(0, 0)
   }
   render() {
      return (
         <div>
            <Container className="py-5">
               <Row>
                  <Col sm={6}>
                     <div className="p-4 font18">
                        <h1 className="hebys1 display-4 font-weight-bold">All things NFT</h1>
                        Hebys is a <strong>true value NFT Marketplace HUB</strong> allowing users to create, trade and discover digital assets.<br />
                        <Link to="/market" className="btn-white btn btn-primary mt-4 mr-4">EXPLORE</Link>
                        <Link to="/create" className="btn-white btn btn-secondary mt-4">CREATE</Link>
                     </div>
                  </Col>
                  <Col sm={6}>
                     <HomeSlider />
                  </Col>
               </Row>
            </Container>
            <div id="content" className="site-content" tabIndex={-1}>
               <div className="content-wrapper content-wrapper-vc-enabled">
                  <div id="primary" className="content-area">
                     <main id="main" className="site-main">
                        <article id="post-13069" className="post-13069 page type-page status-publish hentry">
                           <div className="entry-content">
                              <Container fluid className="bg-gray">
                                 <TrendingCollection />
                              </Container>
                              <Container>
                                 <Row className="mb-0 pb-sm-3 pb-lg-5 pt-5">
                                    <Col sm={12}>
                                       <Row mb={3} className="justify-content-center">
                                          <Col sm={10} lg={6} className="text-center">
                                             <div className="section-title">
                                                <h2 className="title"> Exclusive Hebys drops</h2>
                                                {/* <p>Forget about struggling to do everything at once: taking care of the family, Running your business etc.</p> */}
                                             </div>
                                          </Col>
                                       </Row>
                                       <TopSellingProduct />
                                    </Col>
                                 </Row>
                              </Container>
                              {/* <Container>
                                 <HomeBanner />
                              </Container> */}
                              <div className="container-fluid section-2 bg-dark-gray">
                                 {/* <DealOfTheWeek /> */}
                                 <CreateAndSellYourNft></CreateAndSellYourNft>
                              </div>
                              <div className="container section-3" id="">
                                 <AboutBanner />
                              </div>
                              <Container>
                                 {/* <Subscribe /> */}
                              </Container>
                              <Container fluid className="bg-gray">
                                 <div className="row section-ptb">
                                    <Col sm={12}>
                                       <div className="row text-center">
                                          <div className="col-sm-12 offset-lg-2 col-lg-8 offset-md-1 col-md-9">
                                             <div className="section-title">
                                                <h2 className="title"> Resources for getting started</h2>
                                                {/* <p>Read what we say on our blog. you do not have to worry about getting stuck alone trying to figure everything out.</p> */}
                                             </div>
                                          </div>
                                       </div>
                                       <Row>
                                          <OurLatestPost settings={latestblogslider} />
                                       </Row>
                                    </Col>
                                 </div>
                              </Container>
                              {/* <div className="content-wrapper overflow-hidden">
                                 <div className="container-fluid p-0">
                                    <div className="row">
                                       <div className="col-sm-12">
                                          <div className="instafeed insta-feeds">
                                             <Instagram />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div> */}
                           </div>
                        </article>
                     </main>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}

export default HomePage;
