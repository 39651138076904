/**
 *  Social Info
 */
import React, { Component } from 'react';

class SocialFilter extends Component {
    constructor(props) {
        super(props);

        this.state = { }
    }

    render() {
        const { socialInfos } = this.props;

        return (
            <div className="widget widget_pgs_social_profiles">
                <div className="social-profiles-wrapper">
                    <div className="social-profiles-wrapper-inner social-profiles-default social-profiles-shape-square">
                        <div className="social-profiles">
                            <ul>
                                {socialInfos != null ? socialInfos.map((social, index) => {
                                    return (
                                        <>
                                        <li><a href={social.link} title={social.title} target="_blank" ><i className={social.icon} /></a></li></>
                                    )
                                }) : null}
                            </ul>
                            <div className="clearfix" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SocialFilter;


