
/**
 * ProfileImage Widget
 */
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class ProfileImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }


    render() {
        const { src, isChecked, width, height } = this.props;
        var svgWidth = width / 3;
        var svgHeight = height / 3;
        return (
            <Col md={12} className="pl-0 mt-2">
                <Row >
                    <Col md={12}>
                        <div className="admin-profile-image float-left" style={{height: height, width: width}}>
                            <img className="img-fluid rounded-circle" src={src} width={width} height={height} style={{ border: '2px solid #fe327e', height: height }} alt="profile" />
                            <div className="product-actions-list">
                                {
                                    isChecked == true
                                        ?
                                        <div className="product-action product-action-add-to-cart">
                                            <svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight} `} fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, right: '-7px' }}>
                                                <path d="M4.78117 0.743103C5.29164 -0.247701 6.70826 -0.257701 7.21872 0.743103C7.52645 1.33846 8.21742 1.62509 8.8553 1.42099C9.91675 1.08134 10.9186 2.08304 10.5789 3.1446C10.3748 3.78247 10.6614 4.47445 11.2568 4.78117C12.2476 5.29164 12.2476 6.70826 11.2568 7.21872C10.6614 7.52545 10.3748 8.21742 10.5789 8.8553C10.9186 9.91685 9.91685 10.9186 8.8553 10.5789C8.21742 10.3748 7.52545 10.6614 7.21872 11.2568C6.70826 12.2476 5.29164 12.2476 4.78117 11.2568C4.47445 10.6614 3.78247 10.3748 3.1446 10.5789C2.08304 10.9186 1.08134 9.91685 1.42099 8.8553C1.62509 8.21742 1.33846 7.52545 0.743103 7.21872C-0.247701 6.70826 -0.247701 5.29164 0.743103 4.78117C1.33846 4.47445 1.62509 3.78247 1.42099 3.1446C1.08134 2.08304 2.08304 1.08134 3.1446 1.42099C3.78247 1.62509 4.47445 1.33846 4.78117 0.743103Z" fill="#04d39f">
                                                </path>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.43961 4.24998C8.64623 4.43922 8.65221 4.76823 8.48297 4.97484L5.40604 8.13462L3.54703 6.20677C3.34779 6.00014 3.35377 5.67113 3.56039 5.47189C3.76701 5.27266 4.09602 5.27864 4.29526 5.48525L5.40604 6.63718L7.70475 4.25334C7.90398 4.04672 8.23299 4.04074 8.43961 4.23998Z" fill="#FFFFFF">
                                                </path>
                                            </svg>
                                        </div>
                                        : null}
                            </div>
                        </div>
                        {this.props.children}
                    </Col>
                </Row>
            </Col>
        )
    }
}

export default ProfileImage;
