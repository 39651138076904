/* Invoice Bill Json */
export default [
    {
        "id": 1,
        "invoiceid": "#9275",
        "pictures": "shop/blazers/a.jpg",
        "productname": "La-ta-da Boho Chic Head Wrap",
        "qty": "1",
        "date": "Oct 09, 2019",
        "buyer": "Kuba Bray",
        "status": "Sent",
        "payment": "ALLAHABAD BANK",
        "price": 5235,
        "debit": "$2,235.00",
        "tax": 72,
        "action": "remove_red_eye"
    },
    {
        "id": 2,
        "invoiceid": "#9385",
        "pictures": "shop/blazers/b.jpg",
        "productname": "Women’s Cozy Leisure Top",
        "qty": "2",
        "date": "Jan 24, 2019",
        "buyer": "Louis Boyce",
        "status": "Sent",
        "payment": "ANDHRA BANK",
        "price": 10235,
        "debit": "$5,235.00",
        "tax": 52,
        "action": "remove_red_eye"
    },
    {
        "id": 3,
        "invoiceid": "#7548",
        "pictures": "shop/boys/a.jpg",
        "productname": "WD·NY Black – Men’s Pinstripe Seer Sucker Vest",
        "qty": "2",
        "date": "Dec 2, 2019",
        "buyer": "Havin Weston",
        "status": "Sent",
        "payment": "BANK OF BARODA",
        "price": 15235,
        "debit": "$4,985.00",
        "tax": 40.00,
        "action": "remove_red_eye"
    },
    {
        "id": 4,
        "invoiceid": "#8545",
        "pictures": "shop/clothing/a.jpg",
        "productname": "Women’s Accessories Vegan Leather Mini Backpack",
        "qty": "1",
        "date": "Oct 10, 2019",
        "buyer": "Tayyib Ochoa",
        "status": "Sent",
        "payment": "BANK OF INDIA",
        "price": 25235,
        "debit": "$5,878.00",
        "tax": 60,
        "action": "remove_red_eye"
    },
    {
        "id": 5,
        "invoiceid": "#1020",
        "pictures": "shop/clothing/b.jpg",
        "productname": "Girls’ Shine Stripe Long Sleeve Ruffle Top",
        "qty": "1",
        "date": "Feb 24, 2019",
        "buyer": "Malachy Vasquez",
        "status": "Sent",
        "payment": "BANK OF MAHARASHTRA",
        "price": 35235,
        "debit": "$2,225.00",
        "tax": 72,
        "action": "remove_red_eye"
    },
    {
        "id": 6,
        "invoiceid": "#8748",
        "pictures": "shop/clothing/c.jpg",
        "productname": "Men’s Standard Fit Short Sleeve Crew T-Shirt",
        "qty": "2",
        "date": "Jan 14, 2019",
        "buyer": "Abiha Shaffer",
        "status": "Sent",
        "payment": "CANARA BANK",
        "price": 20152,
        "debit": "$8,205.00",
        "tax": 50,
        "action": "remove_red_eye"
    },
    {
        "id": 7,
        "invoiceid": "#9848",
        "pictures": "shop/coats-jackets/a.jpg",
        "productname": "Women’s Cozy Leisure Top",
        "qty": "1",
        "date": "Oct 17, 2019",
        "buyer": "Jeremiah Cherry",
        "status": "Sent",
        "payment": "CENTRAL BANK OF INDIA",
        "price": 15235,
        "debit": "$6,205.00",
        "tax": 10,
        "action": "remove_red_eye"
    },
    {
        "id": 8,
        "invoiceid": "#5142",
        "pictures": "shop/coats-jackets/b.jpg",
        "productname": "Girls’ Cold Shoulder Short Sleeve T-Shirt with Tulle – Orange",
        "qty": "10",
        "date": "Feb 15, 2019",
        "buyer": "Dean Pratt",
        "status": "Sent",
        "payment": "CORPORATION BANK",
        "price": 20235,
        "debit": "$15,205.00",
        "tax": 70,
        "action": "remove_red_eye"
    },
    {
        "id": 9,
        "invoiceid": "#8545",
        "pictures": "shop/coats-jackets/c.jpg",
        "productname": "Women’s Cheers Pullover",
        "qty": "4",
        "date": "Oct 15, 2019",
        "buyer": "Xander Benson",
        "status": "Sent",
        "payment": "INDIAN OVERSEAS BANK",
        "price": 20154,
        "debit": "$14,205.00",
        "tax": 100,
        "action": "remove_red_eye"
    },
    {
        "id": 10,
        "invoiceid": "#4574",
        "pictures": "shop/dresswear/a.jpg",
        "productname": "Toddler Boys’ Jackets – Red",
        "qty": "3",
        "date": "Jun 09, 2019",
        "buyer": "Muhammad Berg",
        "status": "Sent",
        "payment": "INDIAN BANK",
        "price": 15421,
        "debit": "$10,205.00",
        "tax": 150,
        "action": "remove_red_eye"
    }
]
