
/**
 * PropertyText Widget
 */
import React, { Component } from 'react';
import { Col } from 'reactstrap';

class PropertyText extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }


    render() {
        const { propertyKey, value } = this.props;

        return (
            <Col>
                {propertyKey}:  <span className="font-weight-bold">{value}</span>
            </Col>
        )
    }
}

export default PropertyText;
