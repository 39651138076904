/* Invoice Bill Json */
export default [
    {
        "id": 1,
        "image": "img-01.jpg",
        "name": "Mellissa Doe",
        "email": "mellissadoe@gmail.com",
        "access": "Read",
        "action": "remove_red_eye"
    },
    {
        "id": 2,
        "image": "img-03.jpg",
        "name": "Carla Henderson",
        "email": "carlahenderson@gmail.com",
        "access": "Write",
        "action": "remove_red_eye"
    },
    {
        "id": 3,
        "image": "img-02.jpg",
        "name": "Sara Lisbon",
        "email": "saralisbon@gmail.com",
        "access": "Write",
        "action": "remove_red_eye"
    },
    {
        "id": 4,
        "image": "img-04.jpg",
        "name": "Anton Stephens",
        "email": "antonstephens@gmail.com",
        "access": "Admin",
        "action": "remove_red_eye"
    },
    {
        "id": 5,
        "image": "img-02.jpg",
        "name": "Joana Williams",
        "email": "joanawilliams@gmail.com",
        "access": "Read",
        "action": "remove_red_eye"
    }
]